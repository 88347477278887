import axios, { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import PermissionType from '../domain/permission';
import { GET_ALL_PERMISSIONS_URL } from '../constants/urls';
import { hasPermission } from '../helpers/PermissionHelper';
import TeamType from '../domain/team';

const usePermissions = (selectedTeam: TeamType | null) => {
  const requestConfig = {
    params: {
      // No params
    },
  } as AxiosRequestConfig;
  const { isLoading, error, data } = useQuery(['getAllPermissions', requestConfig], () => {
    return axios.get<PermissionType[]>(GET_ALL_PERMISSIONS_URL, requestConfig);
  });

  let permissionFlags;
  if (!isLoading && !error && data !== undefined) {
    let allPermissions = data.data;

    const hasSelectedTeam = !!selectedTeam;

    // TODO should I just send them from the backend as booleans?
    const canViewDashboard = hasSelectedTeam && hasPermission(allPermissions, 'view_dashboard_page');
    const canViewMemberScores = hasSelectedTeam && hasPermission(allPermissions, 'view_member_scores_page');
    const canViewTeamScores = hasSelectedTeam && hasPermission(allPermissions, 'view_team_scores_page');
    const canViewMemberAdmin = hasSelectedTeam && hasPermission(allPermissions, 'view_member_admin_page');
    const canViewGlobalCoreAssignments = hasPermission(allPermissions, 'view_global_core_assignments_page');
    const canViewTeamCoreAssignments =
      hasSelectedTeam && hasPermission(allPermissions, 'view_team_core_assignments_page');
    const canViewSmrAssignments = hasSelectedTeam && hasPermission(allPermissions, 'view_smr_assignments_page');

    const canViewRecurringTasks = hasSelectedTeam && hasPermission(allPermissions, 'view_recurringtaskschedule');
    const canViewEventTriggeredTasks = hasSelectedTeam && hasPermission(allPermissions, 'view_triggerrule');
    const canViewRecurringGoals = hasSelectedTeam && hasPermission(allPermissions, 'view_recurringgoalschedule');

    // const canAddEvent = hasSelectedTeam && hasPermission(allPermissions, 'add_event');

    const canViewAllAssignments = canViewRecurringTasks || canViewEventTriggeredTasks || canViewRecurringGoals;
    const canViewDots = canViewSmrAssignments || canViewMemberAdmin;
    const canViewScores = canViewMemberScores || canViewMemberScores;
    const canViewFcpAssignments = canViewGlobalCoreAssignments || canViewTeamCoreAssignments;
    // const canCreateSomething = canAddEvent;

    permissionFlags = {
      canViewDashboard,
      canViewMemberScores,
      canViewMemberAdmin,
      canViewGlobalCoreAssignments,
      canViewTeamCoreAssignments,
      canViewTeamScores,
      canViewSmrAssignments,

      canViewRecurringTasks,
      canViewEventTriggeredTasks,
      canViewRecurringGoals,

      canViewAllAssignments,
      canViewDots,
      canViewScores,
      canViewFcpAssignments,
    };
  } else {
    permissionFlags = null;
  }

  const permissionResponse = {
    isLoading,
    error,
    permissionFlags,
  };

  return permissionResponse;
};

export { usePermissions };
