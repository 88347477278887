import axios, { AxiosRequestConfig } from 'axios';
import React from 'react';
import TeamType from '../../../domain/team';
import { isSelectedMonthTheCurrentMonth } from '../../../helpers/AgGridDateHelpers';
import { TASKS_URL } from '../../../constants/urls';
import BaseTaskGrid from '../BaseTaskGrid/BaseTaskGrid';
import { DateTime } from 'luxon';
import TeamMemberType from '../../../domain/teamMember';
import { createFeedbackMessageKey } from '../../FeedbackMessages/FeedbackMessages';
import { GridComponentStates } from '../../../helpers/GridComponentHelpers';
import TaskType from '../../../domain/task';

type UnassignedOverdueTasksGridProps = {
  selectedTeam: TeamType;
  selectedMonth: string;
};

const UnassignedOverdueTasksGrid = (props: UnassignedOverdueTasksGridProps) => {
  if (!props.selectedMonth) {
    throw new Error('Need to pass selectedMonth prop');
  }

  let title = 'Unassigned Overdue Tasks through ' + props.selectedMonth;
  const requestConfig = {
    params: {
      team: props.selectedTeam.id,
      completed_date_time__isnull: true,
      expired_date_time__isnull: true,
      // TODO more timezone issues, can we refilter after call checking for isOverdue=true?
      due_date_time__lte: DateTime.now().toISO(),
      assigned_to_member__isnull: true,
    },
  } as AxiosRequestConfig;

  if (isSelectedMonthTheCurrentMonth(props.selectedMonth) && props?.selectedTeam === null) {
    return <div />;
  }

  const getAdditionalColDefs = (
    team: TeamType,
    gridComponentStates: GridComponentStates<TaskType>,
    refetchTasks: () => void,
  ) => {
    return [
      {
        field: 'assignedToMember',
        headerName: 'Assigned To',
        width: 150,
        cellRenderer: 'assignedToRenderer',
        cellRendererParams: {
          selectedTeam: team,
          // @ts-ignore
          onChange: async (newTeamMember: TeamMemberType | undefined, data) => {
            const idToUpdate = data.id;

            if (idToUpdate !== null) {
              const modifiedTask = {
                id: idToUpdate,
                assignedToMember: newTeamMember?.id,
              };
              // POST is for new stuff, PUT is for replacing task (must have ALL fields)
              // This uses PATCH, which loads old task and only updates fields you passed
              // https://stackoverflow.com/a/24241955/13815107
              await axios
                .patch(TASKS_URL + '/' + idToUpdate + '/', modifiedTask)
                .then(() => {
                  gridComponentStates.addFeedbackMessage({
                    key: createFeedbackMessageKey('task', 'change', idToUpdate),
                    status: 'success',
                    messageBody: <span>Task updated successfully.</span>,
                  });
                  refetchTasks();
                })
                .catch((error) => {
                  const errorMessage = 'Failed to reassign task!';
                  console.error(errorMessage, error);
                  gridComponentStates.addFeedbackMessage({
                    key: createFeedbackMessageKey('task', 'change', idToUpdate),
                    status: 'fail',
                    messageBody: <span>{errorMessage}</span>,
                  });
                });
            }
          },
        },
      },
      {
        field: 'totalPoints',
        headerName: 'Weight',
        width: 140,
      },
    ];
  };

  return (
    <BaseTaskGrid
      title={title}
      selectedTeam={props.selectedTeam}
      requestConfig={requestConfig}
      url={TASKS_URL}
      // idToFocusOn=
      hideCreateButton={true}
      height={400}
      selectedMonth={props.selectedMonth}
      getAdditionalColDefs={getAdditionalColDefs}
      showAssignedToColumn={true}
      // We need this postQueryFilter to run through the tasks again to match what the server timezone thinks isOverdue
      postQueryFilter={(tasks?: TaskType[]) => tasks?.filter((t) => t.isOverdue) || []}
    />
  );
};

export default UnassignedOverdueTasksGrid;
