const BASE_REST_URL = '/rest';

const GET_MY_TEAMS_URL = '/teams/my';
const TEAMS_URL = '/teams/';
const GET_ALL_TEAMS_URL = '/teams/all';
const TEAM_MEMBERS_URL = '/team-members';
const ACCOUNTS_URL = '/accounts';

const EVENT_TYPES_URL = '/event-types';
const EVENTS_URL = '/events';

const GET_ALL_PERMISSIONS_URL = '/permissions';
const GET_PAGE_PERMISSIONS_URL = '/permissions/page-permissions';
const EVENT_PERMISSIONS_URL = '/permissions/event';
const TASK_PERMISSIONS_URL = '/permissions/task';
const RECURRING_TASK_SCHEDULE_PERMISSIONS_URL = '/permissions/recurring-task-schedule';
const RECURRING_GOAL_SCHEDULE_PERMISSIONS_URL = '/permissions/recurring-goal-schedule';
const TRIGGER_RULE_PERMISSIONS_URL = '/permissions/trigger-rule';
const TEAM_MEMBER_PERMISSIONS_URL = '/permissions/team-membership';
const CORE_RECURRING_TASK_SCHEDULE_PERMISSIONS_URL = 'permissions/core-recurring-task-schedule';
const CORE_RECURRING_GOAL_SCHEDULE_PERMISSIONS_URL = 'permissions/core-recurring-goal-schedule';
const CORE_TRIGGER_RULE_PERMISSIONS_URL = 'permissions/core-trigger-rule';

const TASKS_URL = '/tasks';
const RECURRING_TASK_SCHEDULES_URL = '/recurring-task-schedules';
const CORE_RECURRING_TASK_SCHEDULES_URL = '/core-recurring-task-schedules';
const TRIGGER_RULE_URL = '/trigger-rules';
const CORE_TRIGGER_RULE_URL = '/core-trigger-rules';

const GET_GOALS_URL = '/goals';
const RECURRING_GOAL_SCHEDULES_URL = '/recurring-goal-schedules';
const CORE_RECURRING_GOAL_SCHEDULES_URL = '/core-recurring-goal-schedules';
const GOAL_SOURCE_FIELDS_URL = '/goal-source-fields';

const GET_TEAM_SCORE_SUMMARY_URL = '/current-team-score-summary';
const MEMBERS_SCORE_HISTORY_URL = '/members-score-history';
const GET_MEMBER_CURRENT_SCORE_SUMMARY_URL = '/current-team-membership-score-summary';
const GET_MEMBER_SCORE_SUMMARY_FOR_PERIOD_URL = '/team-membership-score-summary-for-period';

const FIELD_CORE_PROCESS_URL = '/field-core-processes';
const FIELD_CORE_PROCESS_LEADS_URL = '/field-core-process-leads';
const FIELD_CORE_PROCESS_CATEGORIES_URL = '/fcp-categories';

const FIELD_CORE_PROCESS_SUBSTEP_URL = '/fcp-substeps';
const FIELD_CORE_PROCESS_SUBSTEP_INSTALLATION_URL = 'fcp-substeps-installation/';
const FIELD_CORE_PROCESS_SUBSTEP_INSTALLATION_STATS_URL = 'fcp-substeps-installation/stats';

const GET_TAGS_URL = '/tags';

// TODO should rename backend to smr-category-responsibilities but not yet
const SMR_CATEGORY_RESPONSIBILITY_URL = '/smr-category-assignments';

const REQUEST_CHANGE_URL = '/request-change';

export {
  BASE_REST_URL,
  GET_MY_TEAMS_URL,
  TEAMS_URL,
  GET_ALL_TEAMS_URL,
  TEAM_MEMBERS_URL,
  ACCOUNTS_URL,
  EVENT_TYPES_URL,
  EVENTS_URL,
  GET_ALL_PERMISSIONS_URL,
  GET_PAGE_PERMISSIONS_URL,
  EVENT_PERMISSIONS_URL,
  TASK_PERMISSIONS_URL,
  RECURRING_TASK_SCHEDULE_PERMISSIONS_URL,
  RECURRING_GOAL_SCHEDULE_PERMISSIONS_URL,
  TRIGGER_RULE_PERMISSIONS_URL,
  TEAM_MEMBER_PERMISSIONS_URL,
  CORE_RECURRING_GOAL_SCHEDULE_PERMISSIONS_URL,
  CORE_RECURRING_TASK_SCHEDULE_PERMISSIONS_URL,
  CORE_TRIGGER_RULE_PERMISSIONS_URL,
  TASKS_URL,
  RECURRING_TASK_SCHEDULES_URL,
  CORE_RECURRING_TASK_SCHEDULES_URL,
  TRIGGER_RULE_URL,
  CORE_TRIGGER_RULE_URL,
  GET_GOALS_URL,
  RECURRING_GOAL_SCHEDULES_URL,
  CORE_RECURRING_GOAL_SCHEDULES_URL,
  GOAL_SOURCE_FIELDS_URL,
  GET_TEAM_SCORE_SUMMARY_URL,
  MEMBERS_SCORE_HISTORY_URL,
  GET_MEMBER_CURRENT_SCORE_SUMMARY_URL,
  GET_MEMBER_SCORE_SUMMARY_FOR_PERIOD_URL,
  FIELD_CORE_PROCESS_URL,
  FIELD_CORE_PROCESS_LEADS_URL,
  FIELD_CORE_PROCESS_SUBSTEP_URL,
  FIELD_CORE_PROCESS_SUBSTEP_INSTALLATION_URL,
  FIELD_CORE_PROCESS_SUBSTEP_INSTALLATION_STATS_URL,
  FIELD_CORE_PROCESS_CATEGORIES_URL,
  GET_TAGS_URL,
  SMR_CATEGORY_RESPONSIBILITY_URL,
  REQUEST_CHANGE_URL,
};
