import PermissionType from '../domain/permission';
import { ObjectWithActions } from '../domain/shared';

// Most common: 'change', 'delete'
// Others: 'reopen', 'complete'
function doActionsContain(objectWithActions: ObjectWithActions, actionName: string) {
  if (!objectWithActions || !Array.isArray(objectWithActions.actions)) {
    return false;
  }
  return objectWithActions.actions.indexOf(actionName) > -1;
}

// Looking for a new codenameToFind? Check the auth_permission DB table.
function hasPermission(usersPermissions: PermissionType[], codenameToFind: string) {
  // !!: If we find an object, return boolean true
  return !!usersPermissions.find((pagePermission) => {
    return pagePermission.codename === codenameToFind;
  });
}

export { doActionsContain, hasPermission };
