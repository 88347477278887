import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import GoalType from '../../../domain/goal';
import TeamMemberType from '../../../domain/teamMember';
import TeamType from '../../../domain/team';
import styles from './CompletedGoalGrid.module.scss';
import { GET_GOALS_URL } from '../../../constants/urls';
import GoalGridPoints, { getValueString } from '../GoalGridPoints/GoalGridPoints';
import { GridApi, ValueFormatterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { defaultOnGridReady, getDefaultColDef } from '../../../helpers/GridComponentHelpers';
import { getEndOfMonthFromString, getStartOfMonthFromString } from '../../../helpers/AgGridDateHelpers';
import { getPositionWithName } from '../../../helpers/TeamMembershipHelpers';

type CompletedGoalGridProps = {
  selectedTeam: TeamType;
  selectedTeamMember: TeamMemberType;
  selectedMonth: string;
};

const getGoalsColumns = () => {
  return [
    {
      field: 'summary',
      headerName: 'Summary',
      flex: 1,
    },
    {
      field: 'goalValue',
      headerName: 'Goal',
      valueFormatter: (params: ValueFormatterParams) => {
        return getValueString(params.data, params.value);
      },
    },
    {
      field: 'resultValue',
      headerName: 'Result',
      valueFormatter: (params: ValueFormatterParams) => {
        return getValueString(params.data, params.value);
      },
    },
    {
      field: 'pointsEarned',
      headerName: 'Pts Earned',
      cellRenderer: 'pointsRenderer',
    },
  ];
};

const CompletedGoalsGrid = (props: CompletedGoalGridProps) => {
  // Weird syntax for types https://stackoverflow.com/a/53598521/13815107
  const [gridApi, setGridApi] = React.useState<null | GridApi>(null);
  const [columnDefs] = React.useState(getGoalsColumns());

  const requestConfig = {
    params: {
      assigned_to_member: props.selectedTeamMember.id,
      start_date_time__gte: getStartOfMonthFromString(props.selectedMonth, props.selectedTeam.timezone).toISO(),
      start_date_time__lte: getEndOfMonthFromString(props.selectedMonth, props.selectedTeam.timezone).toISO(),
      // Should we do anything with end date times?
    },
  } as AxiosRequestConfig;

  const { data, error, isRefetching } = useQuery(
    ['getGoalsForTeamMemberAndMonth', requestConfig],
    () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      if (gridApi) {
        gridApi.showLoadingOverlay();
      }
      return axios.get<GoalType[]>(GET_GOALS_URL, requestConfig);
    },
    {
      onSuccess: (response: AxiosResponse<GoalType[]>) => {
        // If the grid isn't ready, this won't work,
        //  but the data will be picked up on onGridReady
        if (gridApi) {
          gridApi.setRowData(response.data);
        }
      },
    },
  );
  if (error) return <p>Error!</p>;
  const goals = data?.data;

  return (
    <Container fluid className={styles.container}>
      {/* Remove autoHeight when the parent div has implicit size */}
      <Row>
        <Col>
          <h6>
            Goals for {getPositionWithName(props.selectedTeamMember)} during {props.selectedMonth}
          </h6>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="ag-theme-balham" style={{ height: 250, width: '100%' }}>
            <AgGridReact
              onGridReady={(params) => {
                defaultOnGridReady(params, goals, isRefetching, setGridApi);
              }}
              columnDefs={columnDefs}
              // 2022-18-07 Kirk, Austin, Brad - filter is too buried, make the button always visible
              suppressMenuHide={true}
              // Pass in row data via api on react-query onSuccess instead.
              // Otherwise this comes through as undefined on user tab switching which breaks loading spinner
              // rowData={DO_NOT_USE}
              frameworkComponents={{
                pointsRenderer: GoalGridPoints,
              }}
              tooltipShowDelay={0}
              defaultColDef={getDefaultColDef()}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CompletedGoalsGrid;
