import { ICellRendererParams, ValueGetterParams } from 'ag-grid-community';
import TaskType from '../../../domain/task';
import { formatDateForAgGrid, isWeekend } from '../../../helpers/AgGridDateHelpers';
import DefaultTooltip, { getCellId } from '../../DefaultTooltip/DefaultTooltip';
import React from 'react';

type TaskGridDateAndCreationIconProps = ICellRendererParams & {
  // Populated by AG grid based on 'field':'dueDateTime' in column configuration
  value: string;
  // Populated by AG grid because this is one row of data
  data: TaskType;
};

const TaskGridDateAndCreationIcon = (props: TaskGridDateAndCreationIconProps) => {
  const row = props.data as TaskType;
  const creationIconElement = getCreationIconElement(getCellId(props), row.creationType, isSelfAssigned(row));
  const warningElement = getWarningElement(getCellId(props), props.value, row.team.timezone, row.team.daysOffWork);
  const coreAssignmentElement = getCoreAssignmentElement(getCellId(props), row.isCreatedByCore);
  return (
    <span>
      <span>{formatDateForAgGrid(props.value, row.team.timezone)}</span> {creationIconElement} {warningElement}{' '}
      {coreAssignmentElement}
    </span>
  );
};

function getWarningElement(cellId: string, date: string, timezone: string, daysOffWork: string[]): JSX.Element | null {
  let warningMessage = '';
  if (isWeekend(date, timezone, daysOffWork)) {
    warningMessage += 'Due date is on a weekend';
  }
  // space for other warnings here
  if (warningMessage) {
    return (
      <DefaultTooltip id={cellId + '-warning'} tooltipText={warningMessage}>
        <i
          style={{
            textDecoration: 'underline dotted',
            color: '#bf9300',
          }}
          className="bi bi-exclamation-triangle-fill"
        />
      </DefaultTooltip>
    );
  } else {
    return null;
  }
}

function getCreationIconElement(cellId: string, creationType: string, isSelfAssigned: boolean): JSX.Element | null {
  const iconName = getCreationIconName(creationType, isSelfAssigned);
  if (iconName) {
    const iconClasses = 'bi bi-' + iconName;
    return (
      <DefaultTooltip
        id={cellId + '-creation-icon'}
        tooltipText={getCreationIconHoverText(creationType, isSelfAssigned)}
      >
        <i
          style={{
            textDecoration: 'underline dotted',
          }}
          className={iconClasses}
        />
      </DefaultTooltip>
    );
  } else {
    return null;
  }
}

function getCreationIconHoverText(creationType: string, isSelfAssigned: boolean) {
  let hoverText = '';
  // https://icons.getbootstrap.com/
  if (creationType === 'recurring') {
    hoverText = 'Recurring scheduled task';
  } else if (creationType === 'smr') {
    hoverText = 'Generated from SMR section';
  } else if (creationType === 'hmr') {
    hoverText = 'Generated from Family Homes Monthly Report';
  } else if (creationType === 'triggeredEvent') {
    hoverText = 'An event created this task, because there was a trigger rule set up to create it.';
  } else if (isSelfAssigned) {
    hoverText = 'One-time task assigned to self';
  } else {
    // Manager assigned
    // No text
  }
  return hoverText;
}

function getCreationIconName(creationType: string, isSelfAssigned: boolean) {
  let iconName;
  // https://icons.getbootstrap.com/
  if (creationType === 'recurring') {
    iconName = 'arrow-repeat';
  } else if (creationType === 'smr' || creationType === 'hmr') {
    iconName = 'file-earmark-bar-graph';
  } else if (creationType === 'triggeredEvent') {
    iconName = 'gear';
  } else if (isSelfAssigned) {
    iconName = 'person-square';
  } else {
    // Manager assigned
    // No icon
    iconName = null;
  }
  return iconName;
}

const symbolFilterRenderer = (props: ICellRendererParams) => {
  // This is normally the default, may not need this code later
  if (!props.value) {
    return '(Blanks)';
  }
  if (props.value === '(Select All)') {
    return props.value;
  }
  const isSelfAssigned = props.value === 'manual - self-assigned';
  const iconName = getCreationIconName(props.value, isSelfAssigned);

  if (iconName) {
    return (
      <span>
        {getCreationIconElement(props.value, props.value, isSelfAssigned)} - ({props.value})
      </span>
    );
  } else {
    // Turned this off by checking getIconName() in valueGetter, it makes it more confusing
    // Code should never reach here
    return '(' + props.value + ')';
  }
};

const symbolFilterValueGetter = (params: ValueGetterParams) => {
  const task: TaskType = params.data;

  // This turns 'manual' and 'gsheets_import' (and anything else with no icon) into ''
  // This collapses everything with no icon into '(Blanks)' because that's what users expect.
  const iconName = getCreationIconName(params.data?.creationType, isSelfAssigned(task));
  if (getCreationIconName(params.data?.creationType, isSelfAssigned(task))) {
    if (iconName === 'person-square') {
      return 'manual - self-assigned';
    }
    return params.data?.creationType;
  } else {
    return '';
  }
};

function isSelfAssigned(row: TaskType) {
  if (!row) {
    return false;
  }
  return row.assignedToMember?.account?.id === row.creationUser?.id;
}

function getCoreAssignmentElement(cellId: string, isCreatedByCore: boolean) {
  if (isCreatedByCore) {
    return (
      <DefaultTooltip id={cellId + '-core-assignment'} tooltipText={'Created by core assignment'}>
        <i
          style={{
            textDecoration: 'underline dotted',
            color: 'blue',
          }}
          className="bi bi-globe2"
        />
      </DefaultTooltip>
    );
  } else {
    return null;
  }
}

export default TaskGridDateAndCreationIcon;
export { getCreationIconName, getCreationIconElement, symbolFilterRenderer, symbolFilterValueGetter };
export type { TaskGridDateAndCreationIconProps };
