import React, { useContext, useState } from 'react';
import { Alert, Button, Col, Collapse, Container, Row } from 'react-bootstrap';
import MemberTabs from '../../components/members/MemberTabs/MemberTabs';
import MemberScoreSummaryPieChart from '../../components/scores/TeamScoreHistoryGrid/MemberScoreSummaryPieChart/MemberScoreSummaryPieChart';
import CurrentTasksGrid from '../../components/tasks/CurrentTaskGrid/CurrentTaskGrid';
import LoggedInTeamMemberContext from '../../contexts/LoggedInTeamMemberContext';
import { SelectedTeamMemberContext } from '../../contexts/SelectedTeamMemberContext';
import TeamType from '../../domain/team';
import styles from './Dashboard.module.scss';
import CurrentGoalGrid from '../../components/goals/CurrentGoalGrid/CurrentGoalGrid';
import { useSearchParams } from 'react-router-dom';
import { isTeamMemberSelectedAndIsOnThisTeam } from '../../helpers/PageHelper';

type DashboardProps = {
  selectedTeam: TeamType;
};

const Dashboard = (props: DashboardProps) => {
  const selectedTeamMemberContext = useContext(SelectedTeamMemberContext);

  const [isRightSideOpen, setRightSideOpen] = useState(true);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const idStringToEditFromQueryParam = searchParams.get('edit');
  let idToFocusOn = undefined;
  if (idStringToEditFromQueryParam && parseInt(idStringToEditFromQueryParam)) {
    idToFocusOn = parseInt(idStringToEditFromQueryParam);
  }

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row>
        <Col>
          <h4 className="text-center font-weight-bold">Operations Dashboard</h4>
        </Col>
      </Row>
      <Row>
        <Col className="px-0">
          <LoggedInTeamMemberContext.Consumer>
            {(loggedInTeamMember) => (
              <MemberTabs selectedTeam={props.selectedTeam} loggedInTeamMember={loggedInTeamMember} />
            )}
          </LoggedInTeamMemberContext.Consumer>
        </Col>
      </Row>
      {/* If selectedTeamMember is not null, render this */}
      {!isTeamMemberSelectedAndIsOnThisTeam(selectedTeamMemberContext, props.selectedTeam) && (
        <Row>
          <Col>
            <Alert id="dashboardSelectTeamText" variant="light">
              Please select a team member
            </Alert>
          </Col>
        </Row>
      )}
      {/* If selectedTeamMember is not null, render the below */}
      {selectedTeamMemberContext?.selectedTeamMember !== null &&
        isTeamMemberSelectedAndIsOnThisTeam(selectedTeamMemberContext, props.selectedTeam) && (
          <Row className="flex-grow-1 pb-1">
            {/* xs={8} is NOT on this because we want it to take the full 12 when the other side collapses */}
            <Col className="shadow-sm border d-flex">
              <CurrentTasksGrid
                selectedTeamMember={selectedTeamMemberContext.selectedTeamMember}
                selectedTeam={props.selectedTeam}
                idToFocusOn={idToFocusOn}
              />
              <Button
                className={styles.collapseButton}
                variant="light"
                onClick={() => setRightSideOpen(!isRightSideOpen)}
                aria-controls="example-collapse-text"
                aria-expanded={isRightSideOpen}
              >
                <i className={isRightSideOpen ? 'bi bi-caret-right' : 'bi bi-caret-left'} />
              </Button>
            </Col>
            <Collapse
              in={isRightSideOpen}
              // This makes it collapse horizontally, but some css (% width vs hardcoded width) breaks it
              // I don't care enough to fix it right now
              // To find the problem css, find the collapse div in Chrome inspector and hit 'break on attribute change'
              //   then find when it changes to 'collapsing'
              // dimension="width"
            >
              <Col xs={4} className="shadow-sm border">
                <Row>
                  <Col>
                    <MemberScoreSummaryPieChart height={200} selectedTeam={props.selectedTeam} />
                  </Col>
                </Row>
                <hr className={styles.dividerLine} />
                <CurrentGoalGrid
                  selectedTeam={props.selectedTeam}
                  selectedTeamMember={selectedTeamMemberContext.selectedTeamMember}
                />
              </Col>
            </Collapse>
          </Row>
        )}
    </Container>
  );
};

export default Dashboard;
