import { GridApi, RowClassParams, RowDoubleClickedEvent, ValueGetterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import React, { useContext, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { TASK_PERMISSIONS_URL, TASKS_URL } from '../../../constants/urls';
import LoggedInTeamMemberContext from '../../../contexts/LoggedInTeamMemberContext';
import PermissionType from '../../../domain/permission';
import TagType from '../../../domain/tag';
import TaskType, { TaskTypeToSend } from '../../../domain/task';
import TeamType from '../../../domain/team';
import TeamMemberType from '../../../domain/teamMember';
import { compareDatesForAgGridFilter } from '../../../helpers/AgGridDateHelpers';
import { getObjectFromListById } from '../../../helpers/ObjectIdHelpers';
import { doActionsContain, hasPermission } from '../../../helpers/PermissionHelper';
import { createFeedbackMessageKey } from '../../FeedbackMessages/FeedbackMessages';
import CurrentTaskGridActions from '../CurrentTaskGridActions/CurrentTaskGridActions';
import TaskGridSummary from '../TaskGridSummary/TaskGridSummary';
import ModifyTaskDialog from '../ModifyTaskDialog/ModifyTaskDialog';
import TaskGridDateAndCreationIcon, {
  symbolFilterRenderer,
  symbolFilterValueGetter,
} from '../TaskGridDateAndCreationIcon/TaskGridDateAndCreationIcon';
import TaskGridTags from '../TaskGridTags/TaskGridTags';
import styles from './BaseTaskGrid.module.scss';
import {
  defaultGetRowId,
  defaultOnGridReady,
  getDefaultColDef,
  getDefaultStatusBar,
  GridComponentStates,
  removeRow,
  removeRowById,
  useGridComponentStates,
} from '../../../helpers/GridComponentHelpers';
import { EnterpriseColDef } from '../../../helpers/AgGridEnterpriseTypes';
import GenericRequestChangeDialog, {
  genericHandleRequestChangeOk,
} from '../../GenericRequestChangeDialog/GenericRequestChangeDialog';
import RequestedChangeToSend from '../../GenericRequestChangeDialog/requestedChange';
import GenericDeleteDialog from '../../GenericDeleteDialog/GenericDeleteDialog';
import RefetchSubjectContext from '../../../contexts/RefetchSubjectContext';
import { GET_MEMBER_SCORE_SUMMARY, GET_TEAM_SCORE_SUMMARY_FOR_TEAM } from '../../../constants/globals';
import _ from 'lodash';
import ReopenTaskDialog, { ReopenTaskDialogState } from '../ReopenTaskDialog/ReopenTaskDialog';
import { MemberSelect } from '../../members/MemberSelect/MemberSelect';

type BaseTaskGridProps = {
  title: string;
  selectedTeam: TeamType;
  requestConfig: AxiosRequestConfig;
  url?: string;
  selectedTeamMember?: TeamMemberType;
  idToFocusOn?: number;
  hideCreateButton?: boolean;
  height?: number;
  selectedMonth?: string;
  getAdditionalColDefs?: (
    team: TeamType,
    gridComponentStates: GridComponentStates<TaskType>,
    refetchTasks: () => void,
    openReopenDialog: (taskToReopen: TaskType) => void,
  ) => EnterpriseColDef[];
  showAssignedToColumn?: boolean;
  postQueryFilter?: (tasks?: TaskType[]) => TaskType[];
};

const getColDefs = (
  team: TeamType,
  gridComponentStates: GridComponentStates<TaskType>,
  refetchTasks: () => void,
  openReopenDialog: (taskToReopen: TaskType) => void,
  getAdditionalColDefs?: (
    team: TeamType,
    gridComponentStates: GridComponentStates<TaskType>,
    refetchTasks: () => void,
    openReopenDialog: (taskToReopen: TaskType) => void,
  ) => EnterpriseColDef[],
  showAssignedToColumn?: boolean,
  selectedTeamMember?: TeamMemberType,
): EnterpriseColDef[] => {
  let additionalColDefs: EnterpriseColDef[] = [];
  if (getAdditionalColDefs) {
    additionalColDefs = getAdditionalColDefs(team, gridComponentStates, refetchTasks, openReopenDialog);
  }

  const handleCompleteUndo = (taskToBeReopened: TaskType) => {
    // We don't really need to pass the object here, but I think it is more standardized
    // We could also wrap this in react-query's useMutation, but that seems overkill
    const response = axios.post(TASKS_URL + '/' + taskToBeReopened.id + '/reopen/', taskToBeReopened).then(() => {
      // TODO handle errors
      gridComponentStates.addFeedbackMessage({
        key: createFeedbackMessageKey('task', 'reopen', taskToBeReopened.id),
        status: 'success',
        messageBody: <span>Task reopened successfully.</span>,
      });
      refetchTasks();
    });
    return response;
  };

  let allColumns: EnterpriseColDef[] = [
    {
      field: 'summary',
      headerName: 'Summary',
      // Use flex instead of width to fill up the rest of the space
      // width: null,
      flex: 1,
      cellRenderer: 'summaryRenderer',
    },
    {
      field: 'tags',
      headerName: 'Tags',
      cellRenderer: 'tagsRenderer',
      width: 120,
      sortable: false,
      filterParams: {
        valueGetter: (params: ValueGetterParams) => {
          return params.data.tags
            .map((tag: TagType) => {
              return tag.name;
            })
            .join(', ');
        },
      },
    },
    {
      field: 'dueDateTime',
      headerName: 'Due Date',
      cellRenderer: 'dateAndCreationIconRenderer',
      width: 100,
      // filter: 'agDateColumnFilter',
      // filterParams: {
      //   comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
      //     return compareDatesForAgGridFilter(filterLocalDateAtMidnight, cellValue, team.timezone);
      //   },
      // },
      // AG Grid enterprise paid feature!
      filter: 'agMultiColumnFilter',
      filterParams: {
        filters: [
          {
            filter: 'agDateColumnFilter',
            filterParams: {
              comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
                return compareDatesForAgGridFilter(filterLocalDateAtMidnight, cellValue, team.timezone);
              },
            },
          },
          {
            filter: 'agSetColumnFilter',
            filterParams: {
              valueGetter: symbolFilterValueGetter,
              cellRenderer: symbolFilterRenderer,
            },
          },
        ],
      },
    },
  ];

  allColumns = allColumns.concat(additionalColDefs);
  allColumns.push({
    headerName: 'Actions',
    width: 100,
    sortable: false,
    filter: false,
    cellRenderer: 'actionsRenderer',
    cellRendererParams: {
      onEditClick: (taskToBeEdited: TaskType) => {
        gridComponentStates.openModifyDialog(taskToBeEdited);
      },
      onCompleteClick: (taskToBeCompleted: TaskType, gridApi: GridApi) => {
        // Remove from this grid even before the backend refreshes the rows
        removeRow(gridApi, taskToBeCompleted);
        // We don't really need to pass the object here, but I think it is more standardized
        // We could also wrap this in react-query's useMutation, but that seems overkill
        const response = axios
          .post(TASKS_URL + '/' + taskToBeCompleted.id + '/complete/', taskToBeCompleted)
          .then(() => {
            // TODO handle errors
            gridComponentStates.addFeedbackMessage({
              key: createFeedbackMessageKey('task', 'complete', taskToBeCompleted.id),
              status: 'success',
              messageBody: <span>Task completed successfully.</span>,
              onUndo: () => handleCompleteUndo(taskToBeCompleted),
            });
            refetchTasks();
          });
        return response;
      },
      onRequestChangeClick: (taskToRequestChange: TaskType) => {
        gridComponentStates.openRequestChangeDialog(taskToRequestChange);
      },
      onReopenClick: (taskToBeReopened: TaskType) => {
        openReopenDialog(taskToBeReopened);
      },
    },
  });
  return allColumns;
};

const BaseTaskGrid = (props: BaseTaskGridProps) => {
  const refetchSubjectContext = useContext(RefetchSubjectContext);

  // TODO use this everywhere and delete the old states
  let gridComponentStates = useGridComponentStates<TaskType>();

  const [reopenTaskDialogState, setReopenTaskDialogState] = React.useState({
    isSubmitting: false,
    isOpen: false,
    taskToReopen: null,
  } as ReopenTaskDialogState);

  const openReopenDialog = (taskToReopen: TaskType) => {
    setReopenTaskDialogState({
      isSubmitting: false,
      isOpen: true,
      taskToReopen: taskToReopen,
    });
  };

  const closeReopenDialog = () => {
    setReopenTaskDialogState({
      isSubmitting: false,
      isOpen: false,
      taskToReopen: null,
    });
  };

  const disableReopenDialogDoubleSubmit = () => {
    setReopenTaskDialogState({
      isSubmitting: true,
      isOpen: true,
      taskToReopen: null,
    });
  };

  const { idToFocusOn, hideCreateButton, height } = props;
  // const tasksRequestConfig = getTaskRequestConfig(props);

  let url = TASKS_URL;
  if (props.url) {
    url = props.url;
  }

  const {
    data: tasksData,
    error: tasksError,
    refetch,
    isRefetching: isTasksRefetching,
  } = useQuery(
    ['getTasksForTeamMember', props.requestConfig, url, props.selectedTeamMember],
    () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      if (gridComponentStates.gridApi) {
        gridComponentStates.gridApi.showLoadingOverlay();
      }
      return axios.get<TaskType[]>(url, props.requestConfig);
    },
    {
      onSuccess: (response: AxiosResponse<TaskType[]>) => {
        // If the grid isn't ready, this won't work,
        //  but the data will be picked up on onGridReady
        if (gridComponentStates.gridApi) {
          if (props.postQueryFilter) {
            gridComponentStates.gridApi.setRowData(props.postQueryFilter(response.data));
          } else {
            gridComponentStates.gridApi.setRowData(response.data);
          }
        }
        if (idToFocusOn) {
          const found = getObjectFromListById(response.data, idToFocusOn);
          if (found && doActionsContain(found, 'change')) {
            gridComponentStates.openModifyDialog(found);
          } else {
            console.error('Not allowed or could not find task to edit with id: ' + idToFocusOn);
          }
        }
      },
    },
  );

  // React.useRef - Stop react from creating a new debouce function every time this is called
  //  it breaks the debounce
  const refetchTasks = React.useRef(
    _.debounce(() => {
      refetch();
      refetchSubjectContext.next(GET_MEMBER_SCORE_SUMMARY);
      refetchSubjectContext.next(GET_TEAM_SCORE_SUMMARY_FOR_TEAM);
    }, 500),
  ).current;

  const taskPermissionsRequestConfig = {
    params: {
      // No parameters
    },
  } as AxiosRequestConfig;
  const { error: taskPermissionsError, data: taskPermissionsData } = useQuery(
    ['getTaskPermissions', taskPermissionsRequestConfig],
    () => {
      return axios.get<PermissionType[]>(TASK_PERMISSIONS_URL, taskPermissionsRequestConfig);
    },
  );

  // Fixes grid's loading overlay from not displaying on a data re-fetch
  // Without useState, column definitions refresh on every component re-render
  // Ag-grid thinks these are new column defs, and triggers some internals to handle it
  // for some reason one internal thing it does is close all overlays (immediately after it opened)
  const [columnDefs] = useState(
    getColDefs(
      props.selectedTeam,
      gridComponentStates,
      refetchTasks,
      openReopenDialog,
      props.getAdditionalColDefs,
      props.showAssignedToColumn,
      props.selectedTeamMember,
    ),
  );

  if (tasksError || taskPermissionsError) return <p>Error!</p>;

  const taskPermissions = taskPermissionsData?.data;
  const tasks = props.postQueryFilter ? props.postQueryFilter(tasksData?.data) : tasksData?.data;

  const handleDeleteTaskDialogCancel = () => {
    gridComponentStates.closeDeleteDialog();
  };
  const handleDeleteTaskDialogOk = (idToDelete: number) => {
    if (gridComponentStates.gridApi) {
      // Remove from this grid even before the backend refreshes the rows
      removeRowById(gridComponentStates.gridApi, idToDelete);
    }
    // TODO should I do this the react-query way?
    // onSuccess: () => {
    //  cache.invalidateQueries(ServerStateKeysEnum.Items);
    //}
    gridComponentStates.disableSubmissionDeleteDialog();
    return axios.delete(TASKS_URL + '/' + idToDelete + '/').then((response) => {
      gridComponentStates.closeDeleteDialog();
      gridComponentStates.addFeedbackMessage({
        key: createFeedbackMessageKey('task', 'delete', idToDelete),
        status: 'success',
        messageBody: <span>Task deleted successfully.</span>,
      });
      refetchTasks();
    });
  };

  const handleModifyTaskDialogCancel = () => {
    gridComponentStates.closeModifyDialog();
  };
  const handleModifyTaskDialogOk = (modifiedTask: TaskTypeToSend, idToUpdate: number | null) => {
    let promise;
    if (idToUpdate !== null) {
      console.log('Updating task ' + idToUpdate, modifiedTask);
      // POST is for new stuff, PUT is for replacing task (must have ALL fields)
      // This uses PATCH, which loads old task and only updates fields you passed
      // https://stackoverflow.com/a/24241955/13815107
      promise = axios.patch(TASKS_URL + '/' + idToUpdate + '/', modifiedTask).then((response) => {
        // TODO make some green success box or something...
        gridComponentStates.closeModifyDialog();
        gridComponentStates.addFeedbackMessage({
          key: createFeedbackMessageKey('task', 'change', idToUpdate),
          status: 'success',
          messageBody: <span>Task updated successfully.</span>,
        });
        refetchTasks();
      });
    } else {
      console.log('Creating task', modifiedTask);
      if (modifiedTask.id) {
        console.error('Creating a task but sending an id. Was this meant to be a modify?');
        promise = Promise.reject('Creating a task but sending an id. Was this meant to be a modify?');
      } else {
        promise = axios.post(TASKS_URL + '/', modifiedTask).then((response) => {
          gridComponentStates.closeModifyDialog();
          gridComponentStates.addFeedbackMessage({
            key: createFeedbackMessageKey('task', 'create'),
            status: 'success',
            messageBody: <span>Task created successfully.</span>,
          });
          refetchTasks();
        });
      }
    }
    return promise;
  };

  const handleModifyTaskDialogDeleteIcon = (updatedTask: TaskType) => {
    gridComponentStates.closeModifyDialog();
    gridComponentStates.openDeleteDialog(updatedTask);
  };

  const handleRowDoubleClick = (event: RowDoubleClickedEvent) => {
    const task = event.data as TaskType;
    if (doActionsContain(task, 'change')) {
      gridComponentStates.openModifyDialog(task);
    }
  };

  const onCreateClick = () => {
    gridComponentStates.openModifyDialog(null);
  };

  const handleReopenUndo = (taskToBeCompleted: TaskType) => {
    // We don't really need to pass the object here, but I think it is more standardized
    // We could also wrap this in react-query's useMutation, but that seems overkill
    const response = axios.post(TASKS_URL + '/' + taskToBeCompleted.id + '/complete/', taskToBeCompleted).then(() => {
      // TODO handle errors
      gridComponentStates.addFeedbackMessage({
        key: createFeedbackMessageKey('task', 'complete', taskToBeCompleted.id),
        status: 'success',
        messageBody: <span>Task completed successfully.</span>,
      });
      refetchTasks();
    });
    return response;
  };

  // const handleReopenTaskDialogOk = (taskToReopen: TaskType) => {
  //   // We don't really need to pass the object here, but I think it is more standardized
  //   // We could also wrap this in react-query's useMutation, but that seems overkill
  //   const response = axios.post(TASKS_URL + '/' + taskToReopen.id + '/reopen/', taskToReopen).then(() => {
  //     closeReopenDialog();
  //     gridComponentStates.addFeedbackMessage({
  //       key: createFeedbackMessageKey('task', 'reopen', taskToReopen.id),
  //       status: 'success',
  //       messageBody: <span>Task reopened successfully.</span>,
  //       onUndo: () => handleReopenUndo(taskToReopen),
  //     });
  //     refetchTasks();
  //   });
  //   return response;
  // };
  //
  // const handleReopenTaskDialogCancel = () => {
  //   closeReopenDialog();
  // };

  const handleReopenTaskDialogOk = (taskToReopen: TaskType) => {
    disableReopenDialogDoubleSubmit();
    // We don't really need to pass the object here, but I think it is more standardized
    // We could also wrap this in react-query's useMutation, but that seems overkill
    const promise = axios.post(TASKS_URL + '/' + taskToReopen.id + '/reopen/', taskToReopen).then(() => {
      closeReopenDialog();
      gridComponentStates.addFeedbackMessage({
        key: createFeedbackMessageKey('task', 'reopen', taskToReopen.id),
        status: 'success',
        messageBody: <span>Task reopened successfully.</span>,
        onUndo: () => handleReopenUndo(taskToReopen),
      });
      refetchTasks();
    });
    return promise;
  };

  const handleReopenTaskDialogCancel = () => {
    closeReopenDialog();
  };

  const completedOverdueTaskCount = tasks?.filter((t: TaskType) => t.isComplete && t.isOverdue).length;

  return (
    <Container fluid className={styles.container + ' d-flex flex-column flex-grow-1'}>
      <Row>
        <Col>
          <h6>
            {props.title} (Total: {tasks?.length}
            {completedOverdueTaskCount ? `, Completed Overdue: ${completedOverdueTaskCount}` : ''})
          </h6>
        </Col>
      </Row>
      <Row className="d-flex flex-column flex-grow-1">
        <Col>
          <div
            className="ag-theme-balham"
            style={{
              height: height || '100%',
              width: '100%',
            }}
          >
            <AgGridReact
              getRowId={defaultGetRowId}
              onGridReady={(params) => {
                defaultOnGridReady(params, tasks, isTasksRefetching, gridComponentStates.setGridApi);
              }}
              onRowDoubleClicked={handleRowDoubleClick}
              frameworkComponents={{
                actionsRenderer: CurrentTaskGridActions,
                tagsRenderer: TaskGridTags,
                dateAndCreationIconRenderer: TaskGridDateAndCreationIcon,
                summaryRenderer: TaskGridSummary,
                assignedToRenderer: MemberSelect,
              }}
              columnDefs={columnDefs}
              // 2022-18-07 Kirk, Austin, Brad - filter is too buried, make the button always visible
              suppressMenuHide={true}
              // Pass in row data via api on react-query onSuccess instead.
              // Otherwise this comes through as undefined on user tab switching which breaks loading spinner
              // rowData={DO_NOT_USE}
              tooltipShowDelay={100}
              defaultColDef={getDefaultColDef()}
              getRowClass={(params: RowClassParams) => {
                if (params.data.isOverdue || params.data.isExpired) {
                  return styles.tasksGridWarning;
                } else {
                  // No classes
                  return;
                }
              }}
              statusBar={getDefaultStatusBar()}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {taskPermissions &&
            !hideCreateButton &&
            (hasPermission(taskPermissions, 'add_task') || hasPermission(taskPermissions, 'add_my_task')) && (
              <Button id="createOneTimeTask" variant="primary" onClick={onCreateClick}>
                Create a one-time task <i className="bi bi-plus" />
              </Button>
            )}
        </Col>
      </Row>
      <GenericDeleteDialog
        state={gridComponentStates.deleteDialogState}
        onCancel={handleDeleteTaskDialogCancel}
        onOk={handleDeleteTaskDialogOk}
        objectTypeString={'task'}
      />
      <GenericRequestChangeDialog
        state={gridComponentStates.requestChangeDialogState}
        onOk={(requestedChange: RequestedChangeToSend) =>
          genericHandleRequestChangeOk(
            requestedChange,
            gridComponentStates.closeRequestChangeDialog,
            gridComponentStates.addFeedbackMessage,
          )
        }
        onCancel={gridComponentStates.closeRequestChangeDialog}
        objectTypeString={'task'}
      />
      <LoggedInTeamMemberContext.Consumer>
        {(loggedInTeamMember) =>
          taskPermissions && (
            <ModifyTaskDialog
              selectedTeam={props.selectedTeam}
              selectedTeamMember={props.selectedTeamMember}
              // Should I change how I pass this state somehow?
              state={gridComponentStates.modifyDialogState}
              onCancel={handleModifyTaskDialogCancel}
              onOk={handleModifyTaskDialogOk}
              onDeleteIcon={handleModifyTaskDialogDeleteIcon}
              taskPermissions={taskPermissions}
              loggedInTeamMember={loggedInTeamMember}
            />
          )
        }
      </LoggedInTeamMemberContext.Consumer>
      <ReopenTaskDialog
        // Should I change how I pass this state somehow?
        state={reopenTaskDialogState}
        onOk={handleReopenTaskDialogOk}
        onCancel={handleReopenTaskDialogCancel}
      />
    </Container>
  );
};

// function getTaskFromParams(params: GridRenderCellParams|GridValueGetterParams|GridRowParams): TaskType{
//   const task = params.row as TaskType;
//   return task;
// }

export default BaseTaskGrid;
