import axios, { AxiosResponse } from 'axios';
import { Col, Container, Row } from 'react-bootstrap';
import { DateTime } from 'luxon';
import { ChangeEvent, useMemo, useState } from 'react';
import {
  AssigneeWithColor,
  getAssigneeBackgroundColor,
  getAssigneeDropdownJsx,
  getAssigneeTextColor,
  reformatDate,
} from '../../../../../helpers/FieldCoreProcessHelpers';

import '../../../../../styles.css';
import { ICellRendererParams } from 'ag-grid-community';
import FcpSubstepType from '../../../../../domain/fcpSubstep';
import TeamType from '../../../../../domain/team';
import FcpSubstepInstallationType from '../../../../../domain/fcpSubstepInstallation';
import { FIELD_CORE_PROCESS_SUBSTEP_INSTALLATION_URL } from '../../../../../constants/urls';
import ReactDatePicker from 'react-datepicker';
import './installationRenderer.datepicker.scss';
import {
  formatDateDayBeforeMonthAgGrid,
  formatDateForAgGridOnlyMonthYear,
  isoToJsDate,
  jsToIsoDate,
} from '../../../../../helpers/AgGridDateHelpers';
import { HQ_TIMEZONE } from '../../../../../constants/globals';

// substepId: substep?.id??-1,
// substepInstId: 0,
// installationDate: '',
// assigneeId: -1,
// assigneeJsx: assigneeJsx,
// assignees: assignees,
// assigneeBackgroundColor: '',
// assigneeTextColor: '',
// team: team,
// paused: team.isPaused
type InstallationRendererProps = ICellRendererParams & {
  canEditSubs: boolean;
  team: TeamType;
  assignees: AssigneeWithColor[];
};

const getInstallationInfo = (substep: FcpSubstepType, team: TeamType, assignees: AssigneeWithColor[]) => {
  let installationInfo = {
    substepId: substep?.id ?? -1,
    substepInstId: 0,
    installationDate: '',
    assigneeId: -1,
    assignees: assignees,
    assigneeBackgroundColor: '',
    assigneeTextColor: '',
    team: team,
    paused: team.isPaused,
  };
  if (substep) {
    const foundInstallation = substep.subInsts.find((installation: FcpSubstepInstallationType) => {
      return installation.team === team.id;
    });

    if (foundInstallation) {
      installationInfo.substepInstId = foundInstallation.id;

      if (foundInstallation.completionDateTime)
        installationInfo.installationDate = reformatDate(
          DateTime.fromISO(foundInstallation.completionDateTime).toLocaleString(),
        );

      if (foundInstallation.assigneeName) {
        installationInfo.assigneeId = foundInstallation.assignee ?? -1;
      }

      installationInfo.assigneeBackgroundColor = getAssigneeBackgroundColor(
        installationInfo.assigneeId,
        installationInfo.installationDate,
        assignees,
      );

      installationInfo.assigneeTextColor = getAssigneeTextColor(
        installationInfo.assigneeId,
        installationInfo.installationDate,
      );
    }
  }

  return installationInfo;
};

type AssigneeChangeType = { assignee: number };
type CompletionChangeType = { completionDateTime: string | null };

function InstallationRenderer(props: InstallationRendererProps) {
  const installationInfo = useMemo(
    () => getInstallationInfo(props.data, props.team, props.assignees),
    [props.data, props.team, props.assignees],
  );
  const substepInstId = installationInfo.substepInstId;
  // The assignee state contains the initals of the assignee, not the assignee's full name.
  const assigneeId = installationInfo.assigneeId;
  const assigneeBackgroundColor = installationInfo.assigneeBackgroundColor;
  const assigneeTextColor = installationInfo.assigneeTextColor;
  const installationDate = installationInfo.installationDate;
  const [dateStringBeingEdited, setDateStringBeingEdited] = useState<string | undefined>(installationDate);
  // const [showDateField, setShowDateField] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isUnsaved, setIsUnsaved] = useState(false);

  const assigneeJsx = getAssigneeDropdownJsx(props.assignees);

  let fcpPause = false;
  const rowNode = props.node;
  if (rowNode?.data) {
    fcpPause = rowNode.data.fcpIsPaused;
  }

  const isPaused = fcpPause || installationInfo.paused;

  /**
   * When assignee is selected in dropdown, update assignee id in the database.
   * Also, change background of assignee dropdown to the assignee's unique color
   * in the assignees map and change the text color to white so that the assignee's
   * initials are more readable. This function also makes sure to reset the background
   * color to white and the text color to black for the assignee dropdown when an
   * assignee is deselected or not selected yet (value is "-1").
   */
  const onAssigneeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    if (!props.column) {
      throw Error('No column definition, but got change?');
    }
    const newAssigneeId = parseInt(event.target.value);

    // setCanEdit = false;
    axios
      .put<AssigneeChangeType, AxiosResponse<FcpSubstepType>>(
        `${FIELD_CORE_PROCESS_SUBSTEP_INSTALLATION_URL + substepInstId + '/assignee'}`,
        //Set ID to the assignee id unless choosing the blank option
        { assignee: newAssigneeId },
      )
      .then((response) => {
        const updatedCellData = response.data;
        // setCanEdit = true;
        console.log('setting data', updatedCellData);
        props.node.setData(updatedCellData);
      });
  };

  /**
   * This function handles the date input for an installation. It receives
   * the date from the input tag and sends it to the database.
   */
  const onCompletionDateSelect = (isoDateString: string | undefined) => {
    setConfirmDelete(false);
    if (isoDateString) {
      return axios
        .put<CompletionChangeType, AxiosResponse<FcpSubstepType>>(
          `${FIELD_CORE_PROCESS_SUBSTEP_INSTALLATION_URL + substepInstId + '/completion'}`,
          {
            completionDateTime: isoDateString,
          },
        )
        .then((response) => {
          const updatedCellData = response.data;
          // setCanEdit = true;
          console.log('setting data', updatedCellData);
          props.node.setData(updatedCellData);
        });
    } else {
      // Remove completion from installation
      return axios
        .put<CompletionChangeType, AxiosResponse<FcpSubstepType>>(
          `${FIELD_CORE_PROCESS_SUBSTEP_INSTALLATION_URL + substepInstId + '/completion'}`,
          { completionDateTime: null },
        )
        .then((response) => {
          const updatedCellData = response.data;
          // setCanEdit = true;
          console.log('setting data', updatedCellData);
          props.node.setData(updatedCellData);
        });
    }
  };

  console.log(dateStringBeingEdited, props.team.name, props.team.id);
  let displayDate = '';
  if (dateStringBeingEdited) {
    const installationDate = DateTime.fromISO(dateStringBeingEdited, { zone: HQ_TIMEZONE });
    if (installationDate.year === DateTime.now().year) {
      displayDate = formatDateDayBeforeMonthAgGrid(dateStringBeingEdited, HQ_TIMEZONE);
    } else {
      displayDate = formatDateForAgGridOnlyMonthYear(dateStringBeingEdited, HQ_TIMEZONE);
    }
  }
  const unsavedClass = isUnsaved ? 'date-picker-unsaved' : '';

  return (
    <Container fluid className={' d-flex flex-column p-0 flex-grow-1 align-items-between justify-content-center'}>
      {substepInstId !== 0 && (
        <Row className="d-flex p-0 align-items-center justify-content-between">
          <Col sm={5} className="px-1">
            <ReactDatePicker
              wrapperClassName={'date-picker ' + unsavedClass}
              portalId="root"
              disabled={!props.canEditSubs}
              className={'date-style-common' + (isPaused ? ' paused-substep ' : '')}
              selected={isoToJsDate(dateStringBeingEdited, HQ_TIMEZONE)}
              value={displayDate}
              onFocus={() => {
                // setShowDateField(true);
              }}
              onBlur={(e) => {
                if (isUnsaved) {
                  onCompletionDateSelect(dateStringBeingEdited).then(() => {
                    setIsUnsaved(false);
                  });
                }
              }}
              onChange={(date) => {
                setDateStringBeingEdited(jsToIsoDate(date));
                setIsUnsaved(true);
              }}
            />
            {displayDate && !confirmDelete && (
              // disabled={!canEditSubs} TODO why was this here? Doesn't do anything
              <span className="align-items-start" onClick={() => setConfirmDelete(true)}>
                <button
                  className="clear-installation-date-button"
                  disabled={!props.canEditSubs}
                  onClick={() => setConfirmDelete(true)}
                  onMouseLeave={() => setConfirmDelete(false)}
                >
                  X
                </button>
              </span>
            )}
            {confirmDelete && (
              // disabled={!canEditSubs} TODO why was this here? Doesn't do anything
              <span className="align-items-start" onBlur={() => setConfirmDelete(false)}>
                <button
                  className="clear-installation-date-confirm-button"
                  disabled={!props.canEditSubs}
                  onClick={() => {
                    setDateStringBeingEdited(undefined);
                    setIsUnsaved(true);
                    onCompletionDateSelect(undefined).then(() => {
                      setIsUnsaved(false);
                    });
                  }}
                  onMouseLeave={() => setConfirmDelete(false)}
                >
                  X
                </button>
              </span>
            )}
          </Col>
          {installationInfo.substepId && (
            <Col sm={7}>
              <select
                className="initials-dropdown"
                value={assigneeId}
                disabled={!props.canEditSubs}
                onChange={onAssigneeChange}
                style={{ backgroundColor: assigneeBackgroundColor, color: assigneeTextColor }}
              >
                {assigneeJsx}
              </select>
            </Col>
          )}
        </Row>
      )}
    </Container>
  );
}

export default InstallationRenderer;
