import { Button, Modal } from 'react-bootstrap';
import TaskType from '../../../domain/task';

type ReopenTaskDialogState = {
  isSubmitting: boolean;
  isOpen: boolean;
  taskToReopen: TaskType | null;
};

export interface ReopenTaskDialogProps {
  state: ReopenTaskDialogState;
  onOk: (updatedTask: TaskType) => void;
  onCancel: () => void;
}

const ReopenTaskDialog = (props: ReopenTaskDialogProps) => {
  const { state, onOk, onCancel } = props;
  const task = state.taskToReopen;

  const handleOk = () => {
    if (task !== null) {
      onOk(task);
    }
  };

  return (
    // This component causes 'findDOMNode is deprecated in StrictMode' warning
    // Unfortunately, this workaround on SO didn't work https://stackoverflow.com/a/64325602
    // The best way to fix this is to upgrade to react-bootstrap 2.x/bootstrap 5, but that takes some work
    // TODO fix this warning
    <Modal show={state.isOpen} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Reopen this task?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Do you want to reopen this task? If the due date has already passed, it will be considered late.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleOk} disabled={state.isSubmitting}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReopenTaskDialog;
export type { ReopenTaskDialogState };
