import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosRequestConfig } from 'axios';
import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { useQuery } from 'react-query';
import FeedbackMessagesContext from '../../contexts/FeedbackMessagesContext';
import LoggedInTeamMemberContext from '../../contexts/LoggedInTeamMemberContext';
import SelectedTeamContext from '../../contexts/SelectedTeamContext';
import { SelectedTeamMemberContext } from '../../contexts/SelectedTeamMemberContext';
import TeamMemberType from '../../domain/teamMember';
import AllRoutes from '../AllRoutes/AllRoutes';
import FeedbackMessages, { FeedbackMessage } from '../FeedbackMessages/FeedbackMessages';
import Header from '../Header/Header';
import styles from './App.module.scss';
// https://www.ag-grid.com/react-data-grid/licensing/
import { LicenseManager } from 'ag-grid-enterprise';
import { Subject } from 'rxjs';
import RefetchSubjectContext from '../../contexts/RefetchSubjectContext';
import { isLocalhost, isStaging } from '../../helpers/EnvironmentHelpers';

// TODO move these into .env?
const AUTH0_CLIENT_ID = 'ywRsRfTehR5bx1D4KD94u8jwi6ejB4nA';
const AUTH0_DOMAIN = 'dev-oiz87mbf.us.auth0.com';
const AUTH0_AUDIENCE_ID = 'https://operations.lovejustice.ngo/rest';

// Not working? Try importing this manager another way
// https://www.ag-grid.com/react-data-grid/licensing/
// process.env is replaced at build time by react based on .env
// NOTE: Someone might be able to see this in the running app, but this keeps it somewhat secret
LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY!);

const GET_LOGGED_IN_TEAM_MEMBERSHIP = 'team-members/get-logged-in-team-membership/';

function App() {
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const [feedbackMessages, setFeedbackMessages] = useState<FeedbackMessage[]>([]);

  const { isAuthenticated } = useAuth0();

  const loggedInTeamMembershipRequest = {
    params: {
      // No params
    },
  } as AxiosRequestConfig;
  const { isLoading, error, data } = useQuery(
    ['getTeamMembershipForLoggedInUser', loggedInTeamMembershipRequest],
    () => {
      return axios.get<TeamMemberType>(GET_LOGGED_IN_TEAM_MEMBERSHIP, loggedInTeamMembershipRequest);
    },
  );
  if (isLoading) return <p>Loading...</p>;

  let loggedInTeamMember;
  if (error || data === undefined) {
    loggedInTeamMember = null;
  } else {
    loggedInTeamMember = data.data;
  }

  const addFeedbackMessage = (feedbackMessage: FeedbackMessage) => {
    const newMessages = feedbackMessages.concat(feedbackMessage);
    setFeedbackMessages(newMessages);
  };

  let backgroundColor = undefined;
  if (isStaging()) {
    backgroundColor = '#ffe6c7';
  } else if (isLocalhost()) {
    backgroundColor = '#c7f8ff';
  }

  const subject = new Subject<string>();

  return (
    // TODO console says something about strict mode. Not fixing it yet, but will try this later:
    // https://stackoverflow.com/a/65918908
    // vh-100 is to grow the height to equal the viewport
    <Container className={styles.App + ' vh-100'} style={{ backgroundColor: backgroundColor }} fluid>
      <FeedbackMessagesContext.Provider value={{ feedbackMessages, addFeedbackMessage }}>
        <LoggedInTeamMemberContext.Provider value={loggedInTeamMember}>
          <SelectedTeamContext.Provider value={{ selectedTeam, setSelectedTeam }}>
            <SelectedTeamMemberContext.Provider value={{ selectedTeamMember, setSelectedTeamMember }}>
              <RefetchSubjectContext.Provider value={subject}>
                <Header />
                {isAuthenticated && <AllRoutes selectedTeam={selectedTeam} />}
                <FeedbackMessages
                  messages={feedbackMessages}
                  onFeedbackMessageClose={(messageToRemove: FeedbackMessage) => {
                    // Is there a better way to remove it then 'not removing' all the others?
                    const remainingMessages = feedbackMessages.filter((existingMessage) => {
                      return existingMessage.key !== messageToRemove.key;
                    });
                    setFeedbackMessages(remainingMessages);
                  }}
                />
              </RefetchSubjectContext.Provider>
            </SelectedTeamMemberContext.Provider>
          </SelectedTeamContext.Provider>
        </LoggedInTeamMemberContext.Provider>
      </FeedbackMessagesContext.Provider>
    </Container>
  );
}

export { AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_AUDIENCE_ID };
export default App;
