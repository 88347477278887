import { useLocation } from 'react-router-dom';

import FCPRecordPage from './subpages/FCPRecordPage';
import SubstepLookupPage from './subpages/SubstepLookupPage';
import SubstepRecordPage from './subpages/SubstepRecordPage';
import React from 'react';

import '../../styles.css';
import SubstepInstallationStatsPage from './subpages/SubstepInstallationStatsPage';

type FieldCoreProcessesProps = {};

export const FieldCoreProcesses = (props: FieldCoreProcessesProps) => {
  const location = useLocation();

  return (
    <div className="d-flex flex-column fcp-main-page-container">
      {location.pathname.includes('record/at') && <FCPRecordPage type="AT" />}
      {location.pathname.includes('record/fh') && <FCPRecordPage type="FH" />}
      {location.pathname.includes('substeps/at') && <SubstepRecordPage type="AT" />}
      {location.pathname.includes('substeps/fh') && <SubstepRecordPage type="FH" />}
      {location.pathname.includes('substep-stats') && <SubstepInstallationStatsPage />}
      {location.pathname.includes('lookup') && <SubstepLookupPage />}
    </div>
  );
};
