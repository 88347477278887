import { createContext } from 'react';

type SelectedMonthContextType = {
  selectedMonth: string | null;
  setSelectedMonth: Function;
};

const dummyMonthContext = {
  selectedMonth: null,
  setSelectedMonth: (job: any) => {},
} as SelectedMonthContextType;

const SelectedMonthContext = createContext(dummyMonthContext);

export default SelectedMonthContext;
