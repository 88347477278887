// TODO make a real typescript object for this
import AccountType from '../domain/account';

function getNameOfCreationUser(auditableDomainObject: any) {
  const account: AccountType = auditableDomainObject.creationUser;
  let createdByName: string;
  if (account.firstName) {
    createdByName = account.firstName + ' ' + account.lastName + ' (' + account.email + ')';
  } else {
    createdByName = account.email;
  }
  return createdByName;
}

export { getNameOfCreationUser };
