import { RowClassParams, RowDoubleClickedEvent, ValueGetterParams } from 'ag-grid-community';
import { AgGridColumnProps } from 'ag-grid-react';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { TEAM_MEMBERS_URL, TEAM_MEMBER_PERMISSIONS_URL } from '../../../constants/urls';
import PermissionType from '../../../domain/permission';
import TeamMemberType, { TeamMemberTypeToSend } from '../../../domain/teamMember';
import TeamType from '../../../domain/team';
import { getObjectFromListById } from '../../../helpers/ObjectIdHelpers';
import { doActionsContain, hasPermission } from '../../../helpers/PermissionHelper';
import { createFeedbackMessageKey } from '../../FeedbackMessages/FeedbackMessages';
import ModifyMemberDialog, { ModifyMemberDialogState } from '../ModifyMemberDialog/ModifyMemberDialog';
import styles from './MemberGrid.module.scss';
import DeleteMemberDialog, { DeleteMemberDialogState } from '../DeleteMemberDialog/DeleteMemberDialog';
import MemberGridActions from '../MemberGridActions/MemberGridActions';
import AccountType from '../../../domain/account';
import { getAccountString } from '../../../helpers/AccountHelpers';
import { defaultOnGridReady, getDefaultColDef, useGridComponentStates } from '../../../helpers/GridComponentHelpers';
import { useNavigate } from 'react-router-dom';

type MemberGridProps = {
  selectedTeam: TeamType;
  idToFocusOn?: number;
};

const getCurrentMemberColumns = (
  setModifyMemberDialogState: (value: React.SetStateAction<ModifyMemberDialogState>) => void,
): AgGridColumnProps[] => {
  return [
    {
      field: 'callSign',
      headerName: 'Call Sign',
      width: 100,
    },
    {
      field: 'position',
      headerName: 'Position',
      width: 200,
    },
    {
      field: 'account',
      headerName: 'Account',
      flex: 1,
      valueGetter: (params: ValueGetterParams) => {
        const account = params.data.account as AccountType;
        if (!account) {
          return 'Unassigned';
        }
        return getAccountString(account);
      },
    },
    {
      field: 'manager',
      headerName: 'Manager',
      flex: 1,
      valueGetter: (params: ValueGetterParams) => {
        const account = params.data.manager as AccountType;
        if (!account) {
          return 'Unassigned';
        }
        return getAccountString(account);
      },
    },
    {
      headerName: 'Actions',
      width: 100,
      sortable: false,
      filter: false,
      cellRenderer: 'actionsRenderer',
      cellRendererParams: {
        onEditClick: (memberToBeEdited: TeamMemberType) => {
          setModifyMemberDialogState({
            isOpen: true,
            memberToEdit: memberToBeEdited,
          });
        },
      },
    },
  ];
};

const MemberGrid = (props: MemberGridProps) => {
  let gridComponentStates = useGridComponentStates<TeamMemberType>();

  const idToFocusOn = props.idToFocusOn;

  const reactRouterNavigate = useNavigate();

  const [editMemberDialogState, setModifyMemberDialogState] = React.useState({
    isOpen: false,
    memberToEdit: null,
  } as ModifyMemberDialogState);

  const [deleteMemberDialogState, setDeleteMemberDialogState] = React.useState({
    isOpen: false,
    memberToDelete: null,
    selectedTeam: null,
  } as DeleteMemberDialogState);

  const membersRequestConfig = {
    params: {
      team: props.selectedTeam.id,
    },
  } as AxiosRequestConfig;
  const {
    data: membersData,
    error: membersError,
    refetch: refetchMembers,
    isRefetching: isMembersRefetching,
  } = useQuery(
    ['getTeamMembersForTeam', membersRequestConfig],
    () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      if (gridComponentStates.gridApi) {
        gridComponentStates.gridApi.showLoadingOverlay();
      }
      return axios.get<TeamMemberType[]>(TEAM_MEMBERS_URL, membersRequestConfig);
    },
    {
      onSuccess: (response: AxiosResponse<TeamMemberType[]>) => {
        // If the grid isn't ready, this won't work,
        //  but the data will be picked up on onGridReady
        if (gridComponentStates.gridApi) {
          gridComponentStates.gridApi.setRowData(response.data);
        }
        if (idToFocusOn) {
          const found = getObjectFromListById(response.data, idToFocusOn);
          if (found && doActionsContain(found, 'change')) {
            setModifyMemberDialogState({
              isOpen: true,
              memberToEdit: found,
            });
          } else {
            console.error('Not allowed or could not find member to edit with id: ' + props.idToFocusOn);
          }
        }
      },
    },
  );

  const memberPermissionsRequestConfig = {
    params: {
      // No parameters
    },
  } as AxiosRequestConfig;
  const { error: memberPermissionsError, data: memberPermissionsData } = useQuery(
    ['getMemberPermissions', memberPermissionsRequestConfig],
    () => {
      return axios.get<PermissionType[]>(TEAM_MEMBER_PERMISSIONS_URL, memberPermissionsRequestConfig);
    },
  );

  // Fixes grid's loading overlay from not displaying on a data re-fetch
  // Without useState, column definitions refresh on every component re-render
  // Ag-grid thinks these are new column defs, and triggers some internals to handle it
  // for some reason one internal thing it does is close all overlays (immediately after it opened)
  const [columnDefs] = useState(getCurrentMemberColumns(setModifyMemberDialogState));
  if (membersError || memberPermissionsError) return <p>Error!</p>;

  const memberPermissions = memberPermissionsData?.data;
  const members = membersData?.data;

  const handleDeleteMemberDialogCancel = () => {
    setDeleteMemberDialogState({
      isOpen: false,
      memberToDelete: null,
      selectedTeam: null,
    });
  };
  const handleDeleteMemberDialogOk = (idToDelete: number) => {
    // TODO should I do this the react-query way?
    // onSuccess: () => {
    //  cache.invalidateQueries(ServerStateKeysEnum.Items);
    //}
    gridComponentStates.disableSubmissionDeleteDialog();
    return axios.delete(TEAM_MEMBERS_URL + '/' + idToDelete + '/').then((response) => {
      setDeleteMemberDialogState({
        isOpen: false,
        memberToDelete: null,
        selectedTeam: null,
      });
      gridComponentStates.addFeedbackMessage({
        key: createFeedbackMessageKey('member', 'delete', idToDelete),
        status: 'success',
        messageBody: <span>Member deleted successfully.</span>,
      });
      refetchMembers();
    });
  };

  const handleModifyMemberDialogCancel = () => {
    setModifyMemberDialogState({
      isOpen: false,
      memberToEdit: null,
    });
  };
  const handleModifyMemberDialogOk = (modifiedMember: TeamMemberTypeToSend, idToUpdate: number | null) => {
    let promise;
    if (idToUpdate !== null) {
      console.log('Updating member ' + idToUpdate, modifiedMember);
      // POST is for new stuff, PUT is for replacing member (must have ALL fields)
      // This uses PATCH, which loads old member and only updates fields you passed
      // https://stackoverflow.com/a/24241955/13815107
      promise = axios.patch(TEAM_MEMBERS_URL + '/' + idToUpdate + '/', modifiedMember).then((response) => {
        // TODO make some green success box or something...
        setModifyMemberDialogState({
          isOpen: false,
          memberToEdit: null,
        });
        gridComponentStates.addFeedbackMessage({
          key: createFeedbackMessageKey('member', 'change', idToUpdate),
          status: 'success',
          messageBody: <span>Member updated successfully.</span>,
        });
        refetchMembers();
      });
    } else {
      console.log('Creating member', modifiedMember);
      if (modifiedMember.id) {
        console.error('Creating a member but sending an id. Was this meant to be a modify?');
        promise = Promise.reject('Creating a member but sending an id. Was this meant to be a modify?');
      } else {
        promise = axios.post(TEAM_MEMBERS_URL + '/', modifiedMember).then((response) => {
          const updatedMember = response.data;
          setModifyMemberDialogState({
            isOpen: false,
            memberToEdit: null,
          });
          gridComponentStates.addFeedbackMessage({
            key: createFeedbackMessageKey('member', 'create'),
            status: 'success',
            messageBody: <span>Member created successfully.</span>,
          });
          refetchMembers();
          reactRouterNavigate('/smr-responsibilities/?newTeamMember=' + updatedMember.id, { replace: true });
        });
      }
    }
    return promise;
  };

  const handleModifyMemberDialogDeleteIcon = (updatedMember: TeamMemberType, selectedTeam: TeamType) => {
    setModifyMemberDialogState({
      isOpen: false,
      memberToEdit: null,
    });
    setDeleteMemberDialogState({
      isOpen: true,
      memberToDelete: updatedMember,
      selectedTeam,
    });
  };

  const handleRowDoubleClick = (event: RowDoubleClickedEvent) => {
    const member = event.data as TeamMemberType;
    if (doActionsContain(member, 'change')) {
      setModifyMemberDialogState({
        isOpen: true,
        memberToEdit: member,
      });
    }
  };

  const onCreateClick = () => {
    setModifyMemberDialogState({
      isOpen: true,
      memberToEdit: null, // New member
    });
  };

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row>
        <Col>
          <h6>Current members</h6>
        </Col>
      </Row>
      <Row className="d-flex flex-column flex-grow-1">
        <Col>
          <div
            className="ag-theme-balham"
            style={{
              height: '100%',
              width: '100%',
            }}
          >
            <AgGridReact
              onGridReady={(params) => {
                defaultOnGridReady(params, members, isMembersRefetching, gridComponentStates.setGridApi);
              }}
              onRowDoubleClicked={handleRowDoubleClick}
              frameworkComponents={{
                actionsRenderer: MemberGridActions,
              }}
              columnDefs={columnDefs}
              // 2022-18-07 Kirk, Austin, Brad - filter is too buried, make the button always visible
              suppressMenuHide={true}
              // Pass in row data via api on react-query onSuccess instead.
              // Otherwise this comes through as undefined on user tab switching which breaks loading spinner
              // rowData={DO_NOT_USE}
              tooltipShowDelay={100}
              defaultColDef={getDefaultColDef()}
              getRowClass={(params: RowClassParams) => {
                const member = params.data as TeamMemberType;
                if (!member.account) {
                  return styles.memberGridWarning;
                } else {
                  // No classes
                  return;
                }
              }}
            ></AgGridReact>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {memberPermissions && hasPermission(memberPermissions, 'add_teammembership') && (
            <Button variant="primary" onClick={onCreateClick}>
              Create a member <i className="bi bi-plus"></i>
            </Button>
          )}
        </Col>
      </Row>
      <DeleteMemberDialog
        state={deleteMemberDialogState}
        onCancel={handleDeleteMemberDialogCancel}
        onOk={handleDeleteMemberDialogOk}
      />
      {memberPermissions && (
        <ModifyMemberDialog
          selectedTeam={props.selectedTeam}
          // Should I change how I pass this state somehow?
          state={editMemberDialogState}
          onCancel={handleModifyMemberDialogCancel}
          onOk={handleModifyMemberDialogOk}
          onDeleteIcon={handleModifyMemberDialogDeleteIcon}
          memberPermissions={memberPermissions}
        />
      )}
    </Container>
  );
};

// function getMemberFromParams(params: GridRenderCellParams|GridValueGetterParams|GridRowParams): MemberType{
//   const member = params.row as MemberType;
//   return member;
// }

export default MemberGrid;
