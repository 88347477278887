import axios, { AxiosResponse } from 'axios';
import { useState } from 'react';
import { CATEGORY } from '../../../../../constants/globals';
import { ICellRendererParams } from 'ag-grid-community';
import FieldCoreProcessType from '../../../../../domain/fieldCoreProcess';

type FcpPausedRendererProps = ICellRendererParams & {
  canEditFcps: boolean;
};

function FcpPausedRenderer(props: FcpPausedRendererProps) {
  const isChecked = props.data?.isPaused;
  const [fcpId] = useState(props.data?.id);

  let rowNode = props.api.getDisplayedRowAtIndex(props.rowIndex);
  if (!rowNode) {
    throw Error('No row node');
  }
  let fcpData = rowNode.data;

  if (!fcpData) {
    return '';
  }

  const onPauseChange = () => {
    if (!rowNode) {
      throw Error('No row node');
    }
    // TODO: @Josh could add response handling here
    axios
      .put<any, AxiosResponse<FieldCoreProcessType>>(`${'field-core-processes/' + fcpId + '/paused'}`)
      .then((response) => {
        props.node.setData(response.data);
      });
  };

  return (
    <div className="checkbox-wrapper">
      <label>
        {props.data?.description !== CATEGORY && (
          <input type="checkbox" disabled={!props.canEditFcps} checked={isChecked} onChange={onPauseChange} />
        )}
      </label>
    </div>
  );
}

export default FcpPausedRenderer;
