// Combines Formik with react-select and giving it react-bootstrap styling
// Based on my FormikSelect component, but with multi always true and custom colorings
// Also needs to be able to create new tags so uses https://react-select.com/creatable
// And style stuff for tag colors which uses https://react-select.com/home#custom-styles
import { ErrorMessage, Field, FieldProps } from 'formik';
import React, { useState } from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';
import { ActionMeta, MultiValue, Options, StylesConfig } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import TagType from '../../domain/tag';
import { isColorDark } from '../../helpers/ColorHelper';
import CustomErrorMessage from './CustomErrorMessage';
import styles from './FormikTags.module.scss';

interface TagOption {
  label: string;
  value: TagType;
}

interface FormikTagsProps {
  name: string;
  label: string;
  startingOptions: Options<TagOption>;
  automaticallyAdded?: string[];
}

function FormikTags(props: FormikTagsProps) {
  const { name, label, startingOptions } = props;

  const [options, setOptions] = useState(startingOptions);

  const TEMPORARY_COLOR = '#FFFFFF';

  const colourStyles: StylesConfig<TagOption, true> = {
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data.value.color,
      };
    },
    multiValueLabel: (styles, { data }) => {
      let fontColor = 'black';
      if (isColorDark(data.value.color)) {
        fontColor = 'white';
      }
      return {
        ...styles,
        color: fontColor,
      };
    },
  };

  return (
    <div>
      {/* This is a formik component so formik does validation and manages state */}
      <Field name={name}>
        {(fieldProps: FieldProps) => {
          const { field, form } = fieldProps;
          const onCreateOption = (newTagName: string) => {
            // We could technically call the backend and make a new tag at this point.
            // However, I prefer making them when users actually submit the form.
            const newTagValue = {
              name: newTagName,
              color: TEMPORARY_COLOR,
            };
            const newTagOption = {
              label: newTagName,
              value: newTagValue,
            };
            setOptions((previousOptions) => [...previousOptions, newTagOption]);
            const previousSelectedValues = field.value;
            form.setFieldValue(field.name, [...previousSelectedValues, newTagValue]);
          };
          const onChange = (selectedOptions: MultiValue<TagOption>, actionMeta: ActionMeta<TagOption>) => {
            form.setFieldValue(
              field.name,
              // I could also call field.value to get the previously selected options...
              selectedOptions.map((item: TagOption) => item.value),
            );
          };
          const getValue = () => {
            if (options) {
              const reactSelectSelectedOptions = options.filter((option: TagOption) => {
                const formikSelectedValues = field.value as TagType[];
                const foundOption = formikSelectedValues.find((selectedValue) => {
                  return selectedValue.name === option.value.name;
                });
                // !! converts to boolean
                return !!foundOption;
              });
              return reactSelectSelectedOptions;
            } else {
              console.warn('No options passed to FormikSelect with label: ' + label);
              return [];
            }
          };
          return (
            <FormGroup>
              {label && (
                // This is a react-bootstrap component match label look-and-feel
                <FormLabel>{label}</FormLabel>
              )}
              <div className="d-flex">
                {props.automaticallyAdded &&
                  props.automaticallyAdded.map((tagName: string) => {
                    return <span className={styles.automaticallyAdded}>{tagName}</span>;
                  })}
                {/* This is a react-select component to make type-ahead work */}
                <CreatableSelect
                  name={field.name}
                  value={getValue()}
                  onChange={onChange}
                  onCreateOption={onCreateOption}
                  options={options}
                  isMulti={true}
                  styles={colourStyles}
                />
              </div>
              <ErrorMessage name={name}>
                {(errorMessage) => {
                  return <CustomErrorMessage errorMessage={errorMessage} />;
                }}
              </ErrorMessage>
            </FormGroup>
          );
        }}
      </Field>
    </div>
  );
}

export default FormikTags;
