// Based on https://www.antstack.io/blog/reusable-form-components-using-react-formik-yup/
// This uses Formik "Field children" pattern from https://formik.org/docs/api/field
import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { Form, FormGroup } from 'react-bootstrap';
import CustomErrorMessage from './CustomErrorMessage';

// TODO not sure what props from formik controller are yet.
function FormikBoolean(props: any) {
  const { name, label, ...rest } = props;
  return (
    <div>
      <Field name={name} {...rest}>
        {(fieldProps: any) => {
          return (
            // These are react-bootstrap components to get look-and-feel right
            <FormGroup controlId={name}>
              <Form.Check
                type="checkbox"
                id={name}
                label={label}
                checked={fieldProps.field.value}
                onChange={fieldProps.field.onChange}
              />
              <ErrorMessage name={name}>
                {(errorMessage) => {
                  return <CustomErrorMessage errorMessage={errorMessage} />;
                }}
              </ErrorMessage>
            </FormGroup>
          );
        }}
      </Field>
    </div>
  );
}
export default FormikBoolean;
