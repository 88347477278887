import { AgChartsReact } from 'ag-charts-react';
import React from 'react';
import { MemberScoreSummaryPieChartProps, getGridData, getSeriesData } from '../TeamScoresHelper';

const MemberScoreBreakdownBarChart = (props: MemberScoreSummaryPieChartProps) => {
  const allTeamMembersScores = props.allTeamMembersScores;
  const gridData = getGridData(allTeamMembersScores);
  const seriesData = getSeriesData(gridData, 'column');

  const options = {
    title: {
      text: 'Combined Monthly Team Score',
    },
    data: gridData,
    series: seriesData,
    theme: {
      overrides: {
        column: {
          series: {
            highlightStyle: {
              item: {
                // Set to high number to fill entire stack with the appropriate color
                strokeWidth: 1000,
              },
              series: {
                dimOpacity: 0.2,
                strokeWidth: 2,
              },
            },
          },
        },
      },
    },
    axes: [
      {
        type: 'category',
        position: 'bottom',
      },
      {
        type: 'number',
        position: 'left',
        min: 0,
        max: 100 * seriesData.length,
        tick: {
          // If we change the number of ticks then the max property isn't honored...
          count: 10,
        },
        label: {
          formatter: (params: any) => {
            return (params.value / seriesData.length).toFixed(0) + '%';
          },
        },
      },
    ],
  };

  return (
    <>
      <AgChartsReact options={options} />
    </>
  );
};

export default MemberScoreBreakdownBarChart;
