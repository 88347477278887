import axios, { AxiosRequestConfig } from 'axios';
import { Form, Formik } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import { useQuery } from 'react-query';
import * as Yup from 'yup';
import CoreRecurringTaskScheduleType, {
  CoreRecurringTaskScheduleTypeToSend,
} from '../../../../../domain/coreRecurringTaskSchedule';
import { GET_TAGS_URL, CORE_RECURRING_TASK_SCHEDULES_URL } from '../../../../../constants/urls';
import TagType from '../../../../../domain/tag';
import FormikController from '../../../../../formik/FormikSelect/FormikController';
import { Mode } from '../../../../../state_types/mode';
import { createFeedbackMessageKey, FeedbackMessage } from '../../../../FeedbackMessages/FeedbackMessages';
import { getNameOfCreationUser } from '../../../../../helpers/AuditFieldHelper';
import React from 'react';
import { getSimpleModal, ModifyDialogState } from '../../../../../helpers/GridComponentHelpers';
import { HQ_TIMEZONE } from '../../../../../constants/globals';
import FieldCoreProcessType from '../../../../../domain/fieldCoreProcess';
import { getFormikReadOnlyField } from '../../../../../formik/FormikHelpers';

export interface ModifyCoreRecurringTaskScheduleDialogProps {
  fieldCoreProcess: FieldCoreProcessType;
  state: ModifyDialogState<CoreRecurringTaskScheduleType>;
  onOk: (updatedScheduleFields: CoreRecurringTaskScheduleTypeToSend, idToUpdate: number | null) => Promise<void>;
  onCancel: () => void;
  onDeleteIcon: (scheduleToDelete: CoreRecurringTaskScheduleType) => void;
}

const getMode = (state: ModifyDialogState<CoreRecurringTaskScheduleType>) => {
  if (state.objectToModify) {
    return Mode.Change;
  }
  return Mode.Add;
};

const getModalTitle = (mode: Mode) => {
  let titleAction;
  if (mode === Mode.Add) {
    titleAction = 'Add';
  } else {
    titleAction = 'Change';
  }
  return titleAction + ' Core Recurring Task Schedule';
};

const FREQUENCY_OPTIONS = [
  {
    label: 'Yearly',
    value: 'yearly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Quarterly',
    value: 'quarterly',
  },
];

const DAYS_TO_COMPLETE = 30;

const ModifyCoreRecurringTaskScheduleDialog = (props: ModifyCoreRecurringTaskScheduleDialogProps) => {
  const { state, onOk, onCancel, onDeleteIcon } = props;
  const mode = getMode(state);

  const handleCancel = () => {
    onCancel();
  };

  const tagsRequestConfig = {
    // No parameters
  } as AxiosRequestConfig;
  const { isLoading, error, data, refetch } = useQuery(['getAllTags', tagsRequestConfig], () => {
    return axios.get<any>(GET_TAGS_URL, tagsRequestConfig);
  });
  if (isLoading) return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'Loading...');
  if (error) return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'Error!');
  if (data === undefined) return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'No Data!');

  const tags = data.data as TagType[];

  const tagOptions = tags.map((tag) => {
    return {
      label: tag.name,
      // Value is the object itself. This makes it easy for Formik to pass to backend
      // TODO change to id?
      value: tag,
    };
  });

  // TODO should this be in some sort of useState hook?
  var initialStateOfEditableScheduleFields;
  let onSubmit;
  let deleteButton: JSX.Element | null;
  let createdByText: JSX.Element | null;
  let fcp;
  if (mode === Mode.Change) {
    // Tell typescript that taskToEdit is definitely not null
    // TODO this is hacky, find a way to fix it
    const schedule = state.objectToModify!;

    // Copy only editable fields
    initialStateOfEditableScheduleFields = {
      // Not editable
      // id: task.id,
      frequency: schedule.frequency,
      startDateTime: schedule.startDateTime,

      summaryTemplate: schedule.summaryTemplate,
      descriptionTemplate: schedule.descriptionTemplate,
      linkUrlTemplate: schedule.linkUrlTemplate,
      totalPoints: schedule.totalPoints,
      tags: schedule.tags,

      fieldCoreProcess: schedule.fieldCoreProcess.id,
    } as CoreRecurringTaskScheduleTypeToSend;
    fcp = schedule.fieldCoreProcess;
    const createdByName = getNameOfCreationUser(schedule);
    createdByText = getFormikReadOnlyField('Created by:', createdByName);

    deleteButton = (
      <Button
        className="bi-trash-fill mr-auto"
        variant="danger"
        onClick={() => {
          onDeleteIcon(schedule);
        }}
      >
        Delete
      </Button>
    );

    onSubmit = (updatedSchedule: CoreRecurringTaskScheduleTypeToSend | null, { setSubmitting }: any) => {
      console.log('update schedule dialog ok hit', updatedSchedule);
      setTimeout(() => {
        if (updatedSchedule === null) {
          // If this ever happens, we probably need to use 'useEffect' in some capacity
          alert('updatedSchedule was null, contact developers');
          setSubmitting(false);
          return;
        }

        // update hardcoded values on the UI.
        // TODO is this the best place to put this?
        // I bet it will get lost...
        // Should it be in the backend?
        updatedSchedule.daysToComplete = DAYS_TO_COMPLETE;

        // TODO make this typescript cast have a validation or something
        onOk(updatedSchedule, schedule.id).then(() => {
          // In case new tags were created
          refetch();
          setSubmitting(false);
        });
      }, 400);
    };
  } else {
    // Create
    initialStateOfEditableScheduleFields = {
      frequency: '',
      startDateTime: '',

      summaryTemplate: '',
      descriptionTemplate: '',
      linkUrlTemplate: '',
      totalPoints: undefined,
      tags: [],

      fieldCoreProcess: props.fieldCoreProcess.id,
    } as CoreRecurringTaskScheduleTypeToSend;
    fcp = props.fieldCoreProcess;
    onSubmit = (newSchedule: CoreRecurringTaskScheduleTypeToSend, { setSubmitting }: any) => {
      setTimeout(() => {
        if (newSchedule === null) {
          // If this ever happens, we probably need to use 'useEffect' in some capacity
          alert('new schedule was null, contact developers');
          setSubmitting(false);
          return;
        }

        // initialize hardcoded values on the UI.
        // TODO is this the best place to put this?
        // I bet it will get lost...
        // Should it be in the backend?
        newSchedule.daysToComplete = DAYS_TO_COMPLETE;

        // TODO make this typescript cast have a validation or something
        onOk(newSchedule, null).then(() => {
          // In case new tags were created
          refetch();
          setSubmitting(false);
        });
      }, 400);
    };
    deleteButton = null;
    createdByText = null;
  }

  const autoAddedTags: string[] = [];
  if (fcp) {
    autoAddedTags.push(fcp.name);
  }

  const RecurringTaskScheduleFormSchema = Yup.object().shape({
    frequency: Yup.string().nullable().required('Required'),
    startDateTime: Yup.date().required('Required'),

    summaryTemplate: Yup.string().required('Required'),
    descriptionTemplate: Yup.string().optional(),
    linkUrlTemplate: Yup.string().optional(),
    totalPoints: Yup.number().required('Required'), // TODO make integer only
    // TODO tags?
  });

  return (
    // This component causes 'findDOMNode is deprecated in StrictMode' warning
    // Unfortunately, this workaround on SO didn't work https://stackoverflow.com/a/64325602
    // The best way to fix this is to upgrade to react-bootstrap 2.x/bootstrap 5, but that takes some work
    // TODO fix this warning
    <Modal show={state.isOpen} onHide={handleCancel}>
      {
        <Formik
          initialValues={initialStateOfEditableScheduleFields}
          validationSchema={RecurringTaskScheduleFormSchema}
          onSubmit={onSubmit}
        >
          {(formikProps: any) => (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>{getModalTitle(mode)}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* Only show createdByText on editing */}
                {createdByText}
                <FormikController control="select" name="frequency" label="Frequency" options={FREQUENCY_OPTIONS} />
                <FormikController
                  control="date"
                  name="startDateTime"
                  label="Start Date (Recurs on this day)"
                  timezone={HQ_TIMEZONE}
                />
                <FormikController control="input" name="summaryTemplate" label="Summary" />
                <FormikController control="input" name="descriptionTemplate" label="Description (optional)" />
                <FormikController control="input" name="linkUrlTemplate" label="Link (optional)" />
                <FormikController control="input" name="totalPoints" label="Weight" />
                {/* 'startingOptions' because we are allowing the creation of new tags */}
                <FormikController
                  control="tags"
                  name="tags"
                  label="Tags (optional)"
                  startingOptions={tagOptions}
                  automaticallyAdded={autoAddedTags}
                />
              </Modal.Body>
              <Modal.Footer>
                {/* Only show delete button on editing */}
                {deleteButton}
                <Button variant="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" disabled={formikProps.isSubmitting}>
                  Ok
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      }
    </Modal>
  );
};

function defaultHandleModifyCoreRecurringTaskScheduleDialogOk(
  modifiedSchedule: CoreRecurringTaskScheduleTypeToSend,
  idToUpdate: number | null,
  closeModifyDialog: () => void,
  refetchSchedules: () => void,
  addFeedbackMessage: (feedbackMessage: FeedbackMessage) => void,
) {
  let promise;
  if (idToUpdate !== null) {
    console.log('Updating schedule ' + idToUpdate, modifiedSchedule);
    // POST is for new stuff, PUT is for replacing task (must have ALL fields)
    // This uses PATCH, which loads old task and only updates fields you passed
    // https://stackoverflow.com/a/24241955/13815107
    promise = axios
      .patch(CORE_RECURRING_TASK_SCHEDULES_URL + '/' + idToUpdate + '/', modifiedSchedule)
      .then((response) => {
        closeModifyDialog();
        addFeedbackMessage({
          key: createFeedbackMessageKey('recurringTaskSchedule', 'change', idToUpdate),
          status: 'success',
          messageBody: <span>Recurring task schedule updated successfully.</span>,
        });
        refetchSchedules();
      });
  } else {
    console.log('Creating schedule', modifiedSchedule);
    if (modifiedSchedule.id) {
      console.error('Creating a schedule but sending an id. Was this meant to be a modify?');
      promise = Promise.reject('Creating a schedule but sending an id. Was this meant to be a modify?');
    } else {
      promise = axios.post(CORE_RECURRING_TASK_SCHEDULES_URL + '/', modifiedSchedule).then((response) => {
        closeModifyDialog();
        addFeedbackMessage({
          key: createFeedbackMessageKey('recurringTaskSchedule', 'create'),
          status: 'success',
          messageBody: <span>Recurring task schedule created successfully.</span>,
        });
        refetchSchedules();
      });
    }
  }
  return promise;
}

export default ModifyCoreRecurringTaskScheduleDialog;
export { defaultHandleModifyCoreRecurringTaskScheduleDialogOk };
