import axios, { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import { GET_ALL_TEAMS_URL } from '../../../constants/urls';
import TeamType from '../../../domain/team';

function useGetTeamsByDepartment(department: string) {
  const queryOptions = {
    params: {
      department: department,
    },
  } as AxiosRequestConfig;
  return useQuery(['useGetTeamsByDepartment', queryOptions], async () => {
    const response = await axios.get<TeamType[]>(GET_ALL_TEAMS_URL, queryOptions);
    return response.data;
  });
}

export { useGetTeamsByDepartment };
