import axios from 'axios';
import React from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { TEAM_MEMBERS_URL } from '../../../constants/urls';
import TeamMemberType from '../../../domain/teamMember';
import { createFeedbackMessageKey, FeedbackMessage } from '../../FeedbackMessages/FeedbackMessages';
import CurrentTasksGrid from '../../tasks/CurrentTaskGrid/CurrentTaskGrid';
import TeamType from '../../../domain/team';
import RecurringTaskSchedulesGrid from '../../tasks/recurring/RecurringTaskSchedulesGrid/RecurringTaskSchedulesGrid';
import styles from '../../tasks/BaseTaskGrid/BaseTaskGrid.module.scss';
import RecurringGoalScheduleGrid from '../../goals/recurring/RecurringGoalScheduleGrid/RecurringGoalScheduleGrid';
import { getPositionWithName } from '../../../helpers/TeamMembershipHelpers';

type DeleteMemberDialogState = {
  isOpen: boolean;
  memberToDelete: TeamMemberType | null;
  selectedTeam: TeamType | null;
};

export interface DeleteMemberDialogProps {
  state: DeleteMemberDialogState;
  onOk: (idToDelete: number) => void;
  onCancel: () => void;
}

const DeleteMemberDialog = (props: DeleteMemberDialogProps) => {
  const { state, onOk, onCancel } = props;

  const handleCancel = () => {
    onCancel();
  };

  const handleOk = () => {
    if (state.memberToDelete) {
      onOk(state.memberToDelete.id);
    } else {
      // Should never be reached
      // TODO Get rid of this silly if statement
      throw Error("Team member is null when it shouldn't be");
    }
  };

  return (
    <Modal size="lg" show={state.isOpen} onHide={handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Delete this team member?</Modal.Title>
      </Modal.Header>
      {state.memberToDelete && state.selectedTeam && (
        <>
          <Container fluid className={styles.container + ' d-flex flex-column flex-grow-1'}>
            <Row>
              <Col>
                <h5>Reassign Tasks Before Deleting:</h5>
              </Col>
            </Row>
            <Row>
              <CurrentTasksGrid
                selectedTeam={state.selectedTeam}
                selectedTeamMember={state.memberToDelete}
                hideCreateButton={true}
                height={250}
              />
            </Row>
          </Container>

          <Container fluid className={styles.container + ' d-flex flex-column flex-grow-1'}>
            <Row>
              <Col>
                <h6>Recurring Tasks for {getPositionWithName(state.memberToDelete)}</h6>
              </Col>
            </Row>
            <Row>
              <RecurringTaskSchedulesGrid
                selectedTeam={state.selectedTeam}
                filteredCallsign={state.memberToDelete.callSign}
                hideCreateButton={true}
                memberQuickSelect={true}
              />
            </Row>
          </Container>

          <Container fluid className={styles.container + ' d-flex flex-column flex-grow-1'}>
            <Row>
              <Col>
                <h6>Recurring Goals for {getPositionWithName(state.memberToDelete)}</h6>
              </Col>
            </Row>
            <Row>
              <RecurringGoalScheduleGrid
                selectedTeam={state.selectedTeam}
                filteredCallsign={state.memberToDelete.callSign}
                hideCreateButton={true}
                memberQuickSelect={true}
              />
            </Row>
          </Container>
        </>
      )}

      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleOk}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

function defaultHandleDeleteMemberDialogOk(
  idToDelete: number,
  setDialogState: (state: any) => void,
  disableSubmissionDeleteDialog: () => void,
  refetch: () => void,
  addFeedbackMessage: (feedbackMessage: FeedbackMessage) => void,
) {
  disableSubmissionDeleteDialog();
  return axios.delete(TEAM_MEMBERS_URL + '/' + idToDelete + '/').then((response) => {
    setDialogState({
      isOpen: false,
      scheduleToDelete: null,
    });
    addFeedbackMessage({
      key: createFeedbackMessageKey('teamMember', 'delete', idToDelete),
      status: 'success',
      messageBody: <span>Team member deleted successfully.</span>,
    });
    refetch();
  });
}

export default DeleteMemberDialog;
export { defaultHandleDeleteMemberDialogOk };
export type { DeleteMemberDialogState };
