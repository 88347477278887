import { Button, Col, Container, Row } from 'react-bootstrap';
import React from 'react';

type GenericGridActionsProps<DataType> = {
  // Populated by AG grid because this is one row of data
  data: DataType;
  // Passed in with cellRendererParams
  onEditClick: (task: DataType) => void;
  onRequestChangeClick?: (task: DataType) => void;
  removePadding?: boolean;
};

const GenericGridActions = (props: GenericGridActionsProps<any>) => {
  const dataTypeObject = props.data;
  if (!dataTypeObject) {
    return <div />;
  }
  // TODO ideally this would be default but it breaks the normal OM grids and im working on FCPs right now
  const removePaddingClass = props.removePadding ? 'p-0' : '';
  return (
    <Container className={removePaddingClass}>
      <Row>
        <Col>
          {/* && is react template equivalent to "if xx then include this" */}
          {doActionsContain(dataTypeObject, 'change') && (
            <Button
              onClick={() => {
                props.onEditClick(dataTypeObject);
              }}
              variant="outline"
              size="sm"
            >
              <span className="bi-pencil-fill" />
            </Button>
          )}
          {props.onRequestChangeClick &&
            !doActionsContain(dataTypeObject, 'change') &&
            doActionsContain(dataTypeObject, 'request_change') && (
              <Button
                onClick={() => {
                  // Exclamation point because we know it will be there
                  // because we have a check for it to load this JSX element
                  props.onRequestChangeClick!(dataTypeObject);
                }}
                variant="outline"
                size="sm"
              >
                <span className="bi-chat-dots" />
              </Button>
            )}
        </Col>
      </Row>
    </Container>
  );
};

function doActionsContain(dataTypeObject: any, actionName: string) {
  return dataTypeObject.actions.indexOf(actionName) > -1;
}

export default GenericGridActions;

export type { GenericGridActionsProps };
