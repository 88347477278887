import FcpSubstepType from '../../../../domain/fcpSubstep';
import { Accordion, Button, Card, Form, Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import { useGetTeamsByDepartment } from '../../apis/teams_api';
import Select, { SingleValue } from 'react-select';
import TeamType from '../../../../domain/team';
import { useGetFcpsByDepartment } from '../../apis/fcp_api';
import { GenericOption } from '../../../../formik/FormikSelect/FormikSelect';
import { resetSubstepInstallationsOfTeams } from '../../apis/substep_installation_api';
import FcpSubstepInstallationType from '../../../../domain/fcpSubstepInstallation';

type ResetSubstepsDialogProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;

  fcpSubsteps: FcpSubstepType[];
  onOk: (substepInstallsToReset: FcpSubstepInstallationType[]) => Promise<any>;
  department: string;
};

function createTeamOptions(teamData: undefined | TeamType[]): GenericOption[] {
  if (!teamData) {
    return [];
  } else {
    return teamData.map((team) => {
      return {
        label: team.name,
        value: team.id,
      };
    });
  }
}

function ResetSubstepsDialog(props: ResetSubstepsDialogProps) {
  const [selectedTeamOption, setSelectedTeamOption] = useState<GenericOption | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [substepIdsToReset, setSubstepIdsToReset] = useState<number[]>([]);

  const {
    error: teamsError,
    data: departmentTeams,
    isLoading: isTeamsLoading,
  } = useGetTeamsByDepartment(props.department);

  const teamOptions = createTeamOptions(departmentTeams);

  const { error: fcpsError, data: departmentFcps, isLoading: isFcpsLoading } = useGetFcpsByDepartment(props.department);

  const onCancel = () => {
    setSelectedTeamOption(null);
    props.setIsOpen(false);
  };

  if (teamsError || fcpsError) {
    return <p>Error!</p>;
  } else if (isTeamsLoading || isFcpsLoading) {
    return <p>Loading...</p>;
  } else if (!departmentTeams || !departmentFcps) {
    return <p>No Data </p>;
  }
  const handleChange = (teamOption: SingleValue<GenericOption>) => {
    setSelectedTeamOption(teamOption);
  };

  const onCheckChange = (substep: FcpSubstepType, isChecked: boolean) => {
    if (isChecked) {
      const listWithAddedId = [...substepIdsToReset, substep.id];
      setSubstepIdsToReset(listWithAddedId);
    } else {
      const listWithRemovedId = substepIdsToReset.filter((substepId) => substepId !== substep.id);
      setSubstepIdsToReset(listWithRemovedId);
    }
  };

  const onSubmit = () => {
    setIsSubmitting(true);
    let teamIds = [selectedTeamOption?.value];
    resetSubstepInstallationsOfTeams(teamIds, substepIdsToReset).then(() => {
      setIsSubmitting(false);
      props.setIsOpen(false);
    });
  };

  return (
    <Modal show={props.isOpen} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Reset {props.department} Substeps</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Team (Country)</h5>
        <Select value={selectedTeamOption} onChange={handleChange} options={teamOptions} isClearable={true} />
        <br />
        <h5>Choose Substeps to reset</h5>
        <Accordion>
          {departmentFcps.map((fcp) => {
            const substepsOfThisFcp = props.fcpSubsteps
              .filter((substep) => substep.fcp === fcp.id)
              .sort((substep1, substep2) => substep1.order - substep2.order);
            return (
              <Card key={fcp.id}>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey={'' + fcp.id}>
                    {fcp.name}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={'' + fcp.id}>
                  <Card.Body>
                    {substepsOfThisFcp.map((substep) => {
                      return (
                        <Form.Check
                          onChange={(changeEvent) => onCheckChange(substep, changeEvent.currentTarget.checked)}
                          type={'checkbox'}
                          id={'substep-checkbox-' + substep.id}
                          label={substep.name}
                        />
                      );
                    })}
                    {substepsOfThisFcp.length === 0 && <p className={'font-italic'}>This FCP has no substeps</p>}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            );
          })}
        </Accordion>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          variant="primary"
          type="submit"
          disabled={isSubmitting || !selectedTeamOption?.value}
        >
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ResetSubstepsDialog;
