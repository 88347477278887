function isLocalhost(): boolean {
  return (
    window.location.hostname.startsWith('localhost') ||
    window.location.hostname?.startsWith('127.0.0.1') ||
    window.location.hostname?.startsWith('0.0.0.0')
  );
}

function isStaging(): boolean {
  return window.location.hostname?.startsWith('staging');
}

function isProduction(): boolean {
  return window.location.hostname?.startsWith('operations');
}

export { isLocalhost, isStaging, isProduction };
