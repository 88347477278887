import { AgGridReact } from 'ag-grid-react';
import { getDefaultColDef, useGridComponentStates } from '../../../../helpers/GridComponentHelpers';
import { CATEGORY, HQ_TIMEZONE } from '../../../../constants/globals';
import {
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import React, { useCallback, useMemo } from 'react';

import axios, { AxiosResponse } from 'axios';
import CustomHeader from './renderers/customHeader';
import FieldCoreProcessInstallationType from '../../../../domain/fieldCoreProcessInstallation';
import FieldCoreProcessType, { FieldCoreProcessTypeToSend } from '../../../../domain/fieldCoreProcess';
import GenericGridActions from '../../../GenericGridActions/GenericGridActions';
import ImpManagersCell from './renderers/ImpManagersCell';
import LeadCell from './renderers/LeadCell';
import TeamType from '../../../../domain/team';

import './FcpRecordGrid.agGridChanges.scss';
import '../../../../styles.css';
import GoalSourceFieldType from '../../../../domain/goalSourceField';
import AccountType from '../../../../domain/account';
import FcpPausedRenderer from './renderers/fcpPausedRenderer';
import { DateTime } from 'luxon';
import { roundTo2Decimals } from '../../../../helpers/RoundingHelper';
import {
  formatDateDayBeforeMonthAgGrid,
  formatDateForAgGridOnlyMonthYear,
} from '../../../../helpers/AgGridDateHelpers';
import HeaderWithButton from './renderers/HeaderWithButton';
import ModifyFieldCoreProcessDialog from '../ModifyFieldCoreProcessDialog/ModifyFieldCoreProcessDialog';
import { createFcpWithSubsteps, updateFcpWithSubsteps } from '../../apis/fcp_api';
import { FCP_TRIGGER_TYPES } from '../../../../helpers/FieldCoreProcessHelpers';
import ModifyTeamDialog from '../ModifyTeamDialog/ModifyTeamDialog';
import { TEAMS_URL } from '../../../../constants/urls';

type FCPRecordGridProps = {
  department: string;
  departmentTeams: TeamType[];
  departmentFcps: FieldCoreProcessType[];
  impactMetrics: GoalSourceFieldType[];
  leadPeople: AccountType[];
  canEditFcps: boolean;
  refetchTeams: Function;
  refetchFcps: Function;
};

function getBackgroundColorFromPercent(perc: number) {
  let red_perc = perc;
  let green_perc = perc;

  if (perc >= 0.5) {
    green_perc = 1;
    red_perc = 1 - (red_perc - 0.5) * 2;
  } else {
    red_perc = 1;
    green_perc *= 2;
  }

  let max_of_a_color = parseInt('FF', 16);

  let green_val = Math.round(max_of_a_color * green_perc).toString(16);
  let red_val = Math.round(max_of_a_color * red_perc).toString(16);

  if (green_val === '0') {
    green_val = '00';
  }
  if (red_val === '0') {
    red_val = '00';
  }

  let bg_val = '#' + red_val + green_val + '00';
  return bg_val;
}

/**Calculates the coloring of a cell based on whether it is a category cell,
 * paused cell, completed cell, or a percentage cell.
 *
 * @param fcp the FCP
 * @param id The TEAM id
 * @param paused The TEAM pause
 */
export const getFcpCellStyle = (value: string, fcp: FieldCoreProcessType, id: number, paused: boolean) => {
  if (!fcp) return { color: 'black', backgroundColor: 'aqua' };

  const foundInstallation = fcp.installations.find((installation: FieldCoreProcessInstallationType) => {
    return installation.team === id;
  });

  let bgVal = '';
  let textColor = 'black';
  if (fcp.isPaused || paused) {
    bgVal = 'LightGray';
  } else if (foundInstallation?.installedDate) {
    textColor = 'white';
    bgVal = '#134f5c';
  } else if (
    foundInstallation !== undefined &&
    foundInstallation.installedDate === null &&
    foundInstallation.installationPercentage !== undefined &&
    foundInstallation.installationPercentage !== null &&
    (foundInstallation.startDate || foundInstallation.dueDate)
  ) {
    let perc = foundInstallation.installationPercentage;
    bgVal = getBackgroundColorFromPercent(perc);
  }

  return { color: textColor, backgroundColor: bgVal };
};

const getColumns = (
  teams: TeamType[],
  leadPeople: AccountType[],
  canEditFcps: boolean,
  onFcpEditClick: (fcp: FieldCoreProcessType) => void,
  onTeamEditClick: (team: TeamType) => void,
) => {
  var cols = [
    {
      field: 'category',
      headerName: 'Category',
      rowGroup: true,
      hide: true,
      valueGetter: (params: ValueGetterParams) => {
        const fcp = params.data as FieldCoreProcessType;
        return fcp?.category || '(Uncategorized)';
      },
    },
    // {
    //  THIS IS MOVED TO autoGroupColumnDef
    //   field: 'name',
    // },
    {
      headerName: 'Lead',
      width: 90,
      lockPosition: true,
      pinned: true,
      cellRenderer: LeadCell,
      cellRendererParams: { leadPeople: leadPeople, canEditFcps: canEditFcps },
      menuTabs: [],
      headerComponentParams: { isTeam: false },
    },
    {
      headerName: 'Implementation Manager',
      width: 120,
      lockPosition: true,
      pinned: true,
      hide: false,
      cellRenderer: ImpManagersCell,
      cellRendererParams: { leadPeople: leadPeople, canEditFcps: canEditFcps },
      menuTabs: [],
      headerComponentParams: { isTeam: false },
    },
    {
      headerName: 'Triggered',
      width: 130,
      lockPosition: true,
      pinned: true,
      menuTabs: [],
      valueGetter: (params) => {
        const fcp = params.data;
        if (!fcp || !fcp.triggerInfo) {
          return '';
        }
        let value = 'UNKNOWN TYPE';
        if (fcp.triggerInfo.type === FCP_TRIGGER_TYPES.GREENLIGHT) {
          value = 'On Greenlight';
        } else if (fcp.triggerInfo.type === FCP_TRIGGER_TYPES.FCP_INST) {
          value = 'On ' + fcp.triggerInfo.name + ' Installed';
        } else if (fcp.triggerInfo.type === FCP_TRIGGER_TYPES.IM) {
          value = 'When ' + fcp.triggerInfo.name + ' >' + fcp.triggerInfo.impactMetricThreshold;
        } else if (fcp.triggerInfo.type === FCP_TRIGGER_TYPES.NONE) {
          value = 'None';
        }
        return value;
      },
      headerComponentParams: { isTeam: false },
    },
    {
      headerName: 'Pause',
      width: 60,
      resizable: false,
      lockPosition: true,
      pinned: true,
      cellRenderer: FcpPausedRenderer,
      cellRendererParams: { canEditFcps: canEditFcps },
      menuTabs: [],
      headerComponentParams: { isTeam: false },
    },
    {
      headerName: '',
      width: 60,
      resizable: false,
      lockPosition: true,
      pinned: true,
      cellRenderer: GenericGridActions,
      cellRendererParams: {
        onEditClick: onFcpEditClick,
        removePadding: true,
      },
    },
    ...teams.map((team) => ({
      headerName: team.country.length < 9 ? team.country : team.country.substring(0, 9).concat('...'),
      filter: false,
      sortable: false,
      menuTabs: [],
      width: 40,
      headerClass: 'fcp-header-cell',
      colId: 'team_' + team.id.toString(),
      cellClass: 'fcp-cell',
      cellStyle: (params: ICellRendererParams) => {
        return getFcpCellStyle(params.value, params.data as FieldCoreProcessType, team.id, team.isPaused);
      },
      valueFormatter: (params: ValueFormatterParams) => {
        // Find installation date for this fcp and country (team) and display it
        const fcp: FieldCoreProcessType = params.data;

        if (!fcp || fcp.description === CATEGORY) return '';

        const foundInstallation = fcp.installations.find((installation: FieldCoreProcessInstallationType) => {
          return installation.team === team.id;
        });

        //Only try to get a datetime object of the installation
        //both has data and has a non-null date.
        if (
          foundInstallation !== undefined &&
          foundInstallation.installedDate !== null &&
          foundInstallation.installedDate !== undefined
        ) {
          const installationDate = DateTime.fromISO(foundInstallation.installedDate, { zone: HQ_TIMEZONE });
          if (installationDate.year === DateTime.now().year) {
            return formatDateDayBeforeMonthAgGrid(foundInstallation.installedDate, HQ_TIMEZONE);
          } else {
            return formatDateForAgGridOnlyMonthYear(foundInstallation.installedDate, HQ_TIMEZONE);
          }
        } else if (
          foundInstallation !== undefined &&
          foundInstallation.installationPercentage !== undefined &&
          foundInstallation.installationPercentage !== null &&
          foundInstallation.startDate
        ) {
          let percentage = roundTo2Decimals(foundInstallation.installationPercentage * 100).toString();
          return percentage + '%';
        } else {
          return '';
        }
      },
      headerComponentParams: {
        team: team,
        teamPaused: team.isPaused,
        teamId: team.id,

        colId: 'team_' + team.id.toString(),
        isTeam: true,
        greenlight: team.activationDate,
        timezone: team.timezone,

        canEditFcps: canEditFcps,
        onEditClick: onTeamEditClick,
      },
    })),
  ] as ColDef<FieldCoreProcessType>[];
  return cols;
};
export const FcpRecordGrid = (props: FCPRecordGridProps) => {
  const fcpStates = useGridComponentStates<FieldCoreProcessType>();
  const teamStates = useGridComponentStates<TeamType>();

  const columnDefs = useMemo(
    () =>
      getColumns(
        props.departmentTeams,
        props.leadPeople,
        props.canEditFcps,
        fcpStates.openModifyDialog,
        teamStates.openModifyDialog,
      ),
    [
      props.departmentTeams,
      props.leadPeople,
      props.canEditFcps,
      fcpStates.openModifyDialog,
      teamStates.openModifyDialog,
    ],
  );

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerComponent: HeaderWithButton,
      headerComponentParams: {
        title: `${props.department} Field Core Processes`,
        buttonText: '+ Add FCP',
        onClick: () => fcpStates.openModifyDialog(null),
      },
      field: 'name',
      minWidth: 270,
      cellRendererParams: {
        suppressCount: true,
      },
      menuTabs: [],
      lockPosition: true,
      pinned: true,
      editable: true,
      valueSetter: (params: any) => {
        //Name could have letters, spaces, parentheses, and maybe numbers
        //And can't be empty.
        const re = new RegExp('^[0-9a-zA-Z ()]+$');
        if (re.test(params.newValue)) params.data.name = params.newValue.trim();
        return true;
      },

      cellStyle: (cellClassParams) => {
        if (cellClassParams?.data?.isPaused) {
          return {
            backgroundColor: 'lightgrey',
          };
        } else {
          return {};
        }
      },

      // editable: canEditSubs,
      suppressColumnsToolPanel: true,
      children: [],
    } as ColGroupDef & ColDef;
  }, [fcpStates, props.department]);

  const departmentFcps = useMemo(() => props.departmentFcps, [props.departmentFcps]);

  const rowClassRules = useMemo(() => {
    return {
      // row style function
      'category-row': (params: any) => {
        return !params.data || params.data.description === CATEGORY;
      },

      // row style function
      'paused-fcp': (params: any) => {
        return params.data && params.data.description !== CATEGORY && params.data.isPaused;
      },
    };
  }, []);

  // const getRowClass = (params:any) => {
  //   if (true) {
  //     return 'paused-fcp';
  //   }
  // };

  // const rowStyle = { "background": "blue" };

  // const rowClass = 'paused-fcp';

  const onCellValueChanged = (params: CellValueChangedEvent<any>) => {
    axios.put(`${'field-core-processes/' + params.data.id + '/name'}`, { name: params.newValue }).then((response) => {
      console.log(response);
    });
  };

  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeader,
      actionsRenderer: GenericGridActions,
    };
  }, []);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            // suppress sections of column tool panel
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            // prevents custom layout changing when columns are reordered in the grid
            //suppressSyncLayoutWithGrid: true,
            // prevents columns being reordered from the columns tool panel
            //suppressColumnMove: true,
          },
        },
      ],
      defaultToolPanel: 'columns',
    };
  }, []);

  let onFcpOk = useCallback(
    (modifiedFcp: FieldCoreProcessTypeToSend, idToUpdate?: number | null) => {
      if (idToUpdate !== undefined && idToUpdate !== null) {
        return updateFcpWithSubsteps(modifiedFcp, idToUpdate).then((response) => {
          props.refetchFcps();
          fcpStates.closeModifyDialog();
        });
      } else {
        return createFcpWithSubsteps(modifiedFcp).then((response) => {
          fcpStates.closeModifyDialog();
        });
      }
    },
    [fcpStates, props],
  );

  const onTeamOk = useCallback(
    (updatedTeamFields: TeamType, updatedTeamId?: number | null) => {
      return axios.patch<any, AxiosResponse<TeamType>>(TEAMS_URL + updatedTeamId + '/', updatedTeamFields).then(() => {
        // TODO never found a way to make AG grid pick up on the updated team object
        //  Since pausing and greenlighting is rare, just refetch everything
        props.refetchFcps();
        props.refetchTeams();
        teamStates.closeModifyDialog();
      });
    },
    [props, teamStates],
  );

  return (
    <>
      <div className="ag-theme-balham grid-wrapper">
        <AgGridReact
          suppressClickEdit={!props.canEditFcps}
          debounceVerticalScrollbar={true}
          columnDefs={columnDefs}
          suppressMenuHide={true}
          // suppressContextMenu={true}
          tooltipShowDelay={450}
          defaultColDef={getDefaultColDef()}
          rowData={departmentFcps}
          onCellValueChanged={onCellValueChanged}
          // rowClass={'paused-fcp'}
          // rowStyle={rowStyle}
          // rowClass={rowClass}
          components={components}
          headerHeight={80}
          rowClassRules={rowClassRules}
          // getRowClass={getRowClass}
          // statusBar={getDefaultStatusBar()}
          suppressRowHoverHighlight={true}
          suppressRowVirtualisation={true}
          suppressColumnVirtualisation={true}
          autoGroupColumnDef={autoGroupColumnDef}
          sideBar={sideBar}
          onGridReady={function (params) {
            params.api.closeToolPanel();
          }}
        />
        <ModifyTeamDialog
          state={teamStates.modifyDialogState}
          onOk={onTeamOk}
          onCancel={teamStates.closeModifyDialog}
          // onDeleteIcon={}
          canEditFcps={props.canEditFcps}
        />
      </div>
      <ModifyFieldCoreProcessDialog
        state={fcpStates.modifyDialogState}
        onOk={onFcpOk}
        onCancel={fcpStates.closeModifyDialog}
        // onDeleteIcon={}
        department={props.department}
        canEditFcps={props.canEditFcps}
      />
    </>
  );
};
