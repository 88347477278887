import { DateTime } from 'luxon';
import _ from 'lodash';

function formatDateForAgGrid(date: string, timezone: string) {
  const datetime = DateTime.fromISO(date, { zone: timezone });
  const shortDateString = datetime.toLocaleString({ month: 'short', day: 'numeric' });
  return shortDateString;
}

function formatDateDayBeforeMonthAgGrid(date: string, timezone: string) {
  const datetime = DateTime.fromISO(date, { zone: timezone });
  const shortDateString = datetime.toFormat('dd MMM');
  return shortDateString;
}

function formatDateForAgGridOnlyMonthYear(date: string, timezone: string) {
  const datetime = DateTime.fromISO(date, { zone: timezone });
  // Note that single quote is unescapable in Luxon, so using backtic
  const shortDateString = datetime.toFormat('MMM ’yy');
  return shortDateString;
}

function formatDateForAgGridWithYear(date: string, timezone: string) {
  const datetime = DateTime.fromISO(date, { zone: timezone });
  const shortDateString = datetime.toLocaleString({ month: 'short', day: 'numeric', year: 'numeric' });
  return shortDateString;
}

// Assumes that all dates in a grid are the SAME TIMEZONE, which is the one passed in.
// TODO: what if each cell is a different timezone???
//   We would not pass in timezone but instead need the row object, which would need to have the timezone attached.
function compareDatesForAgGridFilter(
  filterLocalDateAtMidnight: Date,
  cellValue: string,
  timezoneOfFilterInput: string,
) {
  const datetimeStringInUtc = cellValue;
  if (!datetimeStringInUtc) {
    return -1;
  }
  const cellZonedDatetime = DateTime.fromISO(datetimeStringInUtc, { zone: timezoneOfFilterInput });
  // Assuming this is timezone replacement with same clock time, not same timezone conversion with same moment
  const inputZonedDatetime = DateTime.fromJSDate(filterLocalDateAtMidnight, { zone: timezoneOfFilterInput });

  const cellStartOfDay = cellZonedDatetime.startOf('day');
  const inputStartOfDay = inputZonedDatetime.startOf('day');

  if (cellStartOfDay.toISO() === inputStartOfDay.toISO()) {
    return 0;
  }
  if (cellStartOfDay.toISO() < inputStartOfDay.toISO()) {
    return -1;
  }
  if (cellStartOfDay.toISO() > inputStartOfDay.toISO()) {
    return 1;
  }
}

function isoToJsDate(isoDate: string | undefined, timezone: string) {
  if (!isoDate) {
    return undefined;
  }
  const dateTime = DateTime.fromISO(isoDate, { zone: timezone });
  return dateTime.toJSDate();
}

function jsToIsoDate(jsDate: Date | undefined | null) {
  if (!jsDate) {
    return undefined;
  }
  const dateTime = DateTime.fromJSDate(jsDate);
  return dateTime.toISODate();
}

function isWeekend(date: string, timezone: string, daysOffWork: string[]): boolean {
  const datetime = DateTime.fromISO(date, { zone: timezone });
  if (!daysOffWork) {
    return datetime.weekdayLong === 'Saturday' || datetime.weekdayLong === 'Sunday';
  } else {
    let isWeekend = false;
    _.forEach(daysOffWork, (weekend_day) => {
      if (datetime.weekdayLong === weekend_day) {
        isWeekend = true;
      }
    });
    return isWeekend;
  }
}

function isSelectedMonthTheCurrentMonth(selectedMonthString: string, timezone?: string): boolean {
  const startOfCurrentMonth = getStartOfCurrentMonth(timezone);
  return getStartOfMonthFromString(selectedMonthString, timezone).equals(startOfCurrentMonth);
}

function getStartOfCurrentMonth(timezone?: string): DateTime {
  let nowInTeamsTimezone = DateTime.now().setZone(timezone);
  return nowInTeamsTimezone.startOf('month');
}

function getEndOfCurrentMonth(timezone?: string): DateTime {
  let nowInTeamsTimezone = DateTime.now().setZone(timezone);
  return nowInTeamsTimezone.endOf('month');
}

function getStartOfMonthFromString(monthString: string, timezone?: string): DateTime {
  const selectedMonthDateTime = DateTime.fromFormat(monthString, 'yyyy-MM');
  const zonedSelectedMonthDateTime = selectedMonthDateTime.setZone(timezone);
  return zonedSelectedMonthDateTime.startOf('month');
}

function getEndOfMonthFromString(monthString: string, timezone?: string): DateTime {
  const selectedMonthDateTime = DateTime.fromFormat(monthString, 'yyyy-MM');
  const zonedSelectedMonthDateTime = selectedMonthDateTime.setZone(timezone);
  return zonedSelectedMonthDateTime.endOf('month');
}

export {
  formatDateForAgGrid,
  formatDateDayBeforeMonthAgGrid,
  formatDateForAgGridOnlyMonthYear,
  formatDateForAgGridWithYear,
  compareDatesForAgGridFilter,
  isWeekend,
  isSelectedMonthTheCurrentMonth,
  getStartOfCurrentMonth,
  getEndOfCurrentMonth,
  getStartOfMonthFromString,
  getEndOfMonthFromString,
  isoToJsDate,
  jsToIsoDate,
};
