import { RowDoubleClickedEvent, ValueFormatterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import axios, { AxiosRequestConfig } from 'axios';
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import FieldCoreProcessType from '../../../../../domain/fieldCoreProcess';
import { compareDatesForAgGridFilter, formatDateForAgGrid } from '../../../../../helpers/AgGridDateHelpers';
import PermissionType from '../../../../../domain/permission';
import TaskGridSummary from '../../../../tasks/TaskGridSummary/TaskGridSummary';
import { doActionsContain, hasPermission } from '../../../../../helpers/PermissionHelper';
import {
  CORE_RECURRING_TASK_SCHEDULES_URL,
  CORE_RECURRING_TASK_SCHEDULE_PERMISSIONS_URL,
} from '../../../../../constants/urls';
import TaskGridTags from '../../../../tasks/TaskGridTags/TaskGridTags';
import {
  getDefaultColDef,
  defaultOnGridReady,
  getDefaultUseQueryConfig,
  useGridComponentStates,
  getDefaultStatusBar,
} from '../../../../../helpers/GridComponentHelpers';
import GenericDeleteDialog from '../../../../GenericDeleteDialog/GenericDeleteDialog';
import CoreRecurringTaskScheduleType from '../../../../../domain/coreRecurringTaskSchedule';
import { createFeedbackMessageKey, FeedbackMessage } from '../../../../FeedbackMessages/FeedbackMessages';
import GenericGridActions from '../../../../GenericGridActions/GenericGridActions';
import ModifyCoreRecurringTaskScheduleDialog, {
  defaultHandleModifyCoreRecurringTaskScheduleDialogOk,
} from '../ModifyCoreRecurringTaskScheduleDialog/ModifyCoreRecurringTaskScheduleDialog';
import { HQ_TIMEZONE } from '../../../../../constants/globals';

type CoreRecurringTaskScheduleGridProps = {
  fieldCoreProcess: FieldCoreProcessType;
  idToFocusOn?: number;
};

const getColumns = (openModifyDialog: (objectToModify: CoreRecurringTaskScheduleType) => void, hqTimezone: string) => {
  return [
    {
      field: 'summaryTemplate',
      headerName: 'Summary',
      tooltipField: 'templateTaskToCreate.descriptionTemplate',
      // Use flex instead of width to fill up the rest of the space
      // width: null,
      flex: 1,
      cellRenderer: 'summaryRenderer',
    },
    {
      field: 'tags',
      headerName: 'Tags',
      cellRenderer: 'tagsRenderer',
      width: 120,

      // TODO figure out how to do this right
      filter: false,
      // filterParams: {
      //   valueFormatter: (params: ValueFormatterParams) => {
      //     return params.value.map((tag: TagType) => {
      //       return tag.name;
      //     }).join(' ,')
      //   }
      // }
    },
    {
      field: 'frequency',
      headerName: 'Freq.',
      width: 90,
    },
    {
      field: 'nextDueDateTime',
      headerName: 'Next Due',
      width: 110,
      valueFormatter: (params: ValueFormatterParams) => {
        if (!params.value) {
          return '';
        }
        return formatDateForAgGrid(params.value, hqTimezone);
      },
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          return compareDatesForAgGridFilter(filterLocalDateAtMidnight, cellValue, hqTimezone);
        },
      },
    },
    {
      field: 'totalPoints',
      headerName: 'Weight',
      width: 90,
    },
    {
      headerName: 'Actions',
      width: 90,
      cellRenderer: 'actionsRenderer',
      cellRendererParams: {
        onEditClick: (scheduleToEdit: CoreRecurringTaskScheduleType) => {
          openModifyDialog(scheduleToEdit);
        },
      },
    },
  ];
};

const CoreRecurringTaskScheduleGrid = (props: CoreRecurringTaskScheduleGridProps) => {
  let gridComponentStates = useGridComponentStates<CoreRecurringTaskScheduleType>();
  const [columnDefs] = React.useState(getColumns(gridComponentStates.openModifyDialog, HQ_TIMEZONE));

  const coreRecurringTaskSchedulesRequestConfig = {
    params: {
      field_core_process: props.fieldCoreProcess.id,
    },
  } as AxiosRequestConfig;

  const {
    isRefetching: isRefetchingSchedules,
    error: schedulesError,
    data: schedulesData,
    refetch: refetchSchedules,
  } = useQuery(
    ['getCoreRecurringTaskSchedulesForFcp', coreRecurringTaskSchedulesRequestConfig],
    () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      if (gridComponentStates.gridApi) {
        gridComponentStates.gridApi.showLoadingOverlay();
      }
      return axios.get<CoreRecurringTaskScheduleType[]>(
        CORE_RECURRING_TASK_SCHEDULES_URL,
        coreRecurringTaskSchedulesRequestConfig,
      );
    },
    getDefaultUseQueryConfig<CoreRecurringTaskScheduleType>(
      gridComponentStates.gridApi,
      gridComponentStates.openModifyDialog,
      props.idToFocusOn,
    ),
  );

  const getRecurringTaskSchedulePermissionsRequestConfig = {
    params: {
      // No parameters
    },
  } as AxiosRequestConfig;
  const {
    error: schedulePermissionsError,
    data: schedulePermissionsData,
    isLoading: isPermissionsLoading,
  } = useQuery(['getRecurringTaskSchedulePermissions', getRecurringTaskSchedulePermissionsRequestConfig], () => {
    return axios.get<PermissionType[]>(
      CORE_RECURRING_TASK_SCHEDULE_PERMISSIONS_URL,
      getRecurringTaskSchedulePermissionsRequestConfig,
    );
  });

  if (schedulesError || schedulePermissionsError) return <p>Error!</p>;

  const coreRecurringTaskSchedules = schedulesData?.data;
  const schedulePermissions = schedulePermissionsData?.data;

  if (!isPermissionsLoading && (!schedulePermissions || schedulePermissions.length === 0)) {
    return <p>No permission for core recurring task schedules.</p>;
  }

  const handleModifyDialogDeleteIcon = (updatedSchedule: CoreRecurringTaskScheduleType) => {
    gridComponentStates.closeModifyDialog();
    gridComponentStates.openDeleteDialog(updatedSchedule);
  };

  const handleRowDoubleClick = (event: RowDoubleClickedEvent) => {
    const schedule = event.data as CoreRecurringTaskScheduleType;
    if (doActionsContain(schedule, 'change')) {
      gridComponentStates.openModifyDialog(schedule);
    }
  };

  const onCreateClick = () => {
    // Null for new object
    gridComponentStates.openModifyDialog(null);
  };

  return (
    <Container
      fluid
      className={
        //styles.container +
        ' d-flex flex-column flex-grow-1'
      }
    >
      <Row className="d-flex flex-column flex-grow-1">
        <Col>
          <div
            className="ag-theme-balham"
            style={{
              height: '200px',
              width: '100%',
            }}
          >
            <AgGridReact
              onGridReady={(params) => {
                defaultOnGridReady(
                  params,
                  coreRecurringTaskSchedules,
                  isRefetchingSchedules,
                  gridComponentStates.setGridApi,
                );
              }}
              frameworkComponents={{
                actionsRenderer: GenericGridActions,
                tagsRenderer: TaskGridTags,
                // dateAndCreationIconRenderer: TaskGridDateAndCreationIcon,
                summaryRenderer: TaskGridSummary,
              }}
              onRowDoubleClicked={handleRowDoubleClick}
              columnDefs={columnDefs}
              // 2022-18-07 Kirk, Austin, Brad - filter is too buried, make the button always visible
              suppressMenuHide={true}
              tooltipShowDelay={450}
              defaultColDef={getDefaultColDef()}
              // Normally we pass in row data via api on react-query onSuccess instead.
              // Otherwise this comes through as undefined on user tab switching which breaks loading spinner
              // However, apparently onSuccess doesn't get called when the data was cached
              // That means when you flip Legal to Station Expansion and back to Legal, Legal Cases don't show up
              // Using rowData fixes this, but I'm hoping for a better solution later.
              rowData={coreRecurringTaskSchedules}
              statusBar={getDefaultStatusBar()}
            ></AgGridReact>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {schedulePermissions && hasPermission(schedulePermissions, 'add_corerecurringtaskschedule') && (
            <Button variant="primary" onClick={onCreateClick}>
              Create a core recurring task <i className="bi bi-plus" />
            </Button>
          )}
        </Col>
      </Row>
      <GenericDeleteDialog
        state={gridComponentStates.deleteDialogState}
        onCancel={gridComponentStates.closeDeleteDialog}
        onOk={(idToDelete) =>
          handleDeleteCoreRecurringTaskScheduleDialogOk(
            idToDelete,
            gridComponentStates.closeDeleteDialog,
            gridComponentStates.disableSubmissionDeleteDialog,
            refetchSchedules,
            gridComponentStates.addFeedbackMessage,
          )
        }
        objectTypeString="core recurring task schedule"
      />
      <ModifyCoreRecurringTaskScheduleDialog
        state={gridComponentStates.modifyDialogState}
        onCancel={gridComponentStates.closeModifyDialog}
        onOk={(modifySchedule, idToUpdate) =>
          defaultHandleModifyCoreRecurringTaskScheduleDialogOk(
            modifySchedule,
            idToUpdate,
            gridComponentStates.closeModifyDialog,
            refetchSchedules,
            gridComponentStates.addFeedbackMessage,
          )
        }
        onDeleteIcon={handleModifyDialogDeleteIcon}
        fieldCoreProcess={props.fieldCoreProcess}
      />
    </Container>
  );
};

// TODO should I make this generic, too?
function handleDeleteCoreRecurringTaskScheduleDialogOk(
  idToDelete: number,
  closeDeleteDialog: () => void,
  disableSubmissionDeleteDialog: () => void,
  refetch: () => void,
  addFeedbackMessage: (feedbackMessage: FeedbackMessage) => void,
) {
  disableSubmissionDeleteDialog();
  return axios.delete(CORE_RECURRING_TASK_SCHEDULES_URL + '/' + idToDelete + '/').then((response) => {
    closeDeleteDialog();
    addFeedbackMessage({
      key: createFeedbackMessageKey('coreRecurringTaskSchedule', 'delete', idToDelete),
      status: 'success',
      messageBody: <span>Core recurring task schedule deleted successfully.</span>,
    });
    refetch();
  });
}

export default CoreRecurringTaskScheduleGrid;
