// @ts-nocheck
import { MembersScoreHistoryMember } from '../../../pages/TeamScores/TeamScores';
import TeamType from '../../../domain/team';

type MemberScoreSummaryPieChartProps = {
  selectedTeam: TeamType;
  allTeamMembersScores: MembersScoreHistoryMember[];
};

const getGridData = (allTeamMemberScores: MembersScoreHistoryMember[]): any[] => {
  let data = {};
  allTeamMemberScores.forEach((memberScore: MembersScoreHistoryMember) => {
    memberScore.scores.forEach((monthScore) => {
      // @ts-ignore
      data[monthScore.month] = { ...data[monthScore.month], [memberScore.callSign]: monthScore.score * 100 };
    });
  });

  // @ts-ignore
  return Object.entries(data).map(([key, value]) => ({ ...value, month: key }));
};

const randomInt = () => Math.floor(Math.random() * 255);

const randomHexColor = () => {
  let hr = randomInt().toString(16).padStart(2, '0');
  let hg = randomInt().toString(16).padStart(2, '0');
  let hb = randomInt().toString(16).padStart(2, '0');
  return '#' + hr + hg + hb;
};

const callsignColorMap = (callsigns) => {
  let colorMap = {};
  callsigns.forEach((cs) => {
    colorMap = {
      ...colorMap,
      [cs]: colorMap[cs] ? colorMap[cs] : randomHexColor(),
    };
  });
  return colorMap;
};

const getSeriesData = (gridData: any[], type?: string) => {
  let mostNonZeroCallsignsInMonth = 0;
  let sortedSeriesData = {};

  gridData.forEach((month) => {
    const callsignsGreaterThanZero = Object.keys(month).filter((callsign) => month[callsign] > 0);

    if (callsignsGreaterThanZero.length > mostNonZeroCallsignsInMonth) {
      mostNonZeroCallsignsInMonth = callsignsGreaterThanZero.length;
      sortedSeriesData = Object.keys(month)
        .filter((month) => month !== 'month')
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
        .sort((a, b) => month[a] - month[b]);
    }
  });

  const colorMap = callsignColorMap(sortedSeriesData);

  const allMonthSeriesData = sortedSeriesData.map((callsign) => ({
    xKey: 'month',
    yKey: callsign,
    stacked: true,
    yName: callsign,
    stroke: colorMap?.[callsign],
    fill: colorMap?.[callsign],
    marker: {
      fill: colorMap?.[callsign],
      stroke: colorMap?.[callsign],
    },
    tooltip: {
      renderer: function (params) {
        return (
          '<div class="ag-chart-tooltip-title" style="background-color:' +
          params.color +
          '">' +
          params.yName +
          '</div>' +
          '<div class="ag-chart-tooltip-content">' +
          params.yValue.toFixed(0) +
          '%' +
          '</div>'
        );
      },
    },
    type,
  }));
  return allMonthSeriesData;
};

export { getGridData, getSeriesData };
export type { MemberScoreSummaryPieChartProps };
