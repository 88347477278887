// Based on https://www.antstack.io/blog/reusable-form-components-using-react-formik-yup/
import React from 'react';
import FormikInput from './FormikInput';
import FormikSelect from './FormikSelect';
import FormikCheckbox from './FormikCheckbox';
import FormikDate from './FormikDate';
import FormikTags from './FormikTags';

// TODO not sure what the props from Formik are yet
function FormikController(props: any) {
  const { control, ...rest } = props;
  switch (control) {
    case 'input':
      return <FormikInput {...rest} />;
    case 'select':
      return <FormikSelect {...rest} />;
    case 'checkbox':
      return <FormikCheckbox {...rest} />;
    case 'date':
      return <FormikDate {...rest} />;
    case 'tags':
      return <FormikTags {...rest} />;
    default:
      return null;
  }
}
export default FormikController;
