import { ObjectWithId } from '../domain/shared';

function getObjectFromListById<ModelObject extends ObjectWithId>(
  objectsWithIdsList: ModelObject[],
  id: number | undefined | null,
) {
  if (!id) {
    return undefined;
  }

  const found = objectsWithIdsList.find((objectWithId) => {
    return objectWithId.id === id;
  });
  return found;
}

function makeInQueryFromList<ModelObject extends ObjectWithId>(objectsWithIdsList: ModelObject[] | undefined) {
  if (!objectsWithIdsList) {
    return undefined;
  }
  return getListOfIdsFromListOfObjects(objectsWithIdsList).join(',');
}

function getListOfIdsFromListOfObjects<ModelObject extends ObjectWithId>(objectsWithIdsList: ModelObject[]): number[] {
  return objectsWithIdsList.map((memberObject) => memberObject.id);
}

export { getObjectFromListById, makeInQueryFromList, getListOfIdsFromListOfObjects };
