import axios, { AxiosResponse } from 'axios';
import { ICellRendererParams } from 'ag-grid-community';
import PersonCell from './PersonCell';
import AccountType from '../../../../../domain/account';
import FieldCoreProcessType from '../../../../../domain/fieldCoreProcess';

type ImpManagersCellProps = ICellRendererParams & {
  canEditFcps: boolean;
  leadPeople: AccountType[];
};

function ImpManagersCell(props: ImpManagersCellProps) {
  const fcpId = props.data?.id;

  const onPersonChange = (newId: number) => {
    axios
      .put<any, AxiosResponse<FieldCoreProcessType>>(
        `${'field-core-processes/' + fcpId + '/imp-manager'}`,
        //Set ID to the imp manager id unless choosing the blank option
        { imp_manager_id: newId },
      )
      .then((response) => {
        props.node.setData(response.data);
      });
  };
  const person = props.data?.implementationManager;
  return <PersonCell person={person} onPersonChange={onPersonChange} {...props} />;
}

export default ImpManagersCell;
