import * as React from 'react';
import TeamType from '../../../domain/team';
import Select from 'react-select';
import { GenericOption } from '../../../formik/FormikSelect/FormikSelect';
import { useQuery } from 'react-query';
import axios, { AxiosRequestConfig } from 'axios';
import TeamMemberType from '../../../domain/teamMember';
import { TEAM_MEMBERS_URL } from '../../../constants/urls';
import { getReactSelectStylesForAgGridCell } from '../../../helpers/ReactSelectStyleHelpers';
import { ObjectWithId } from '../../../domain/shared';
import { getAssignedToString } from '../../../helpers/GridComponentHelpers';

type MemberSelectProps = {
  // Populated by AG grid based on 'field':'actions' in column configuration
  value: TeamMemberType | undefined;
  // Populated by AG grid because this is one row of data
  data: ObjectWithId;
  selectedTeam: TeamType;
  onChange: (teamMember: TeamMemberType | undefined, oldObject: ObjectWithId) => void;
};
export const MemberSelect = (props: MemberSelectProps) => {
  const requestConfig = {
    params: {
      team: props.selectedTeam.id,
    },
  } as AxiosRequestConfig;
  const useQueryConfig = {
    staleTime: 100000, // Cache for this long, in ms
  };
  const { isLoading, error, data } = useQuery(
    ['getTeamMembersForTeam', requestConfig, props.selectedTeam.id],
    () => {
      return axios.get<TeamMemberType[]>(TEAM_MEMBERS_URL, requestConfig);
    },
    useQueryConfig,
  );
  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error!</p>;
  if (data === undefined) return <p>No data</p>;

  const teamMembers = data.data;

  const teamMemberOptions = teamMembers.map((teamMember) => {
    const optionLabel = getAssignedToString(teamMember);
    return {
      label: optionLabel,
      // Value is the id. We GET sub-objects from the frontend but only POST/PUT/PATCH the ids of sub-objects
      value: teamMember.id,
    };
  });

  const handleChange = (option: GenericOption | null) => {
    if (option === null) {
      return props.onChange(undefined, props.data);
    }
    const newTeamMember = teamMembers.find((teamMember) => {
      return teamMember.id === option.value;
    });
    props.onChange(newTeamMember, props.data);
  };

  const getValue = () => {
    return teamMemberOptions.find((teamMemberOption) => teamMemberOption.value === props.value?.id);
  };

  return (
    <Select
      name="member-select"
      // TODO would like to use this, but using React.useState() doesn't re-render at the right times
      // isLoading={isLoadingSelect}
      value={getValue()}
      onChange={handleChange}
      options={teamMemberOptions}
      styles={getReactSelectStylesForAgGridCell()}
      placeholder="UNASSIGNED!"
      // Allows to break out of Ag Grids
      menuPortalTarget={document.body}
    />
  );
};

export type { MemberSelectProps };
