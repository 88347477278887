import axios, { AxiosRequestConfig } from 'axios';
import { FieldArray, FieldArrayRenderProps, Form, Formik, FormikProps } from 'formik';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import RecurringGoalScheduleType, {
  convertSecondaryAssignedToMembersTypeToSend,
  convertToTypeToSend,
  getDisplayValue,
  RecurringGoalScheduleTypeToSend,
} from '../../../../domain/recurringGoalSchedule';
import { RECURRING_GOAL_SCHEDULES_URL, GOAL_SOURCE_FIELDS_URL, TEAM_MEMBERS_URL } from '../../../../constants/urls';
import FormikController from '../../../../formik/FormikSelect/FormikController';
import { Mode } from '../../../../state_types/mode';
import { createFeedbackMessageKey, FeedbackMessage } from '../../../FeedbackMessages/FeedbackMessages';
import { getNameOfCreationUser } from '../../../../helpers/AuditFieldHelper';
import React from 'react';
import { getSimpleModal, ModifyDialogState } from '../../../../helpers/GridComponentHelpers';
import GoalSourceFieldType from '../../../../domain/goalSourceField';
import { useQuery } from 'react-query';
import { SingleValue } from 'react-select';
import { GenericOption } from '../../../../formik/FormikSelect/FormikSelect';
import { getObjectFromListById } from '../../../../helpers/ObjectIdHelpers';
import TeamType from '../../../../domain/team';
import TeamMemberType from '../../../../domain/teamMember';
import { getFormikReadOnlyField } from '../../../../formik/FormikHelpers';
import {
  LINEAR_FROM_CUTOFF,
  SCORING_TYPE_OPTIONS,
} from '../../../fcp/goals/recurring/ModifyCoreRecurringGoalScheduleDialog/ModifyCoreRecurringGoalScheduleDialog';
import styles from './ModifyRecurringGoalScheduleDialog.module.scss';
import GoalScoringVisualLineGraph from '../GoalScoringVisualLineGraph/GoalScoringVisualLineGraph';
import { getCallSignWithPositionAndName } from '../../../../helpers/TeamMembershipHelpers';

export interface ModifyRecurringGoalScheduleDialogProps {
  selectedTeam: TeamType;
  state: ModifyDialogState<RecurringGoalScheduleType>;
  onOk: (updatedScheduleFields: RecurringGoalScheduleTypeToSend, idToUpdate: number | null) => Promise<void>;
  onCancel: () => void;
  onDeleteIcon: (scheduleToDelete: RecurringGoalScheduleType) => void;
}

const getMode = (state: ModifyDialogState<RecurringGoalScheduleType>) => {
  if (state.objectToModify) {
    return Mode.Change;
  }
  return Mode.Add;
};

const FREQUENCY_OPTIONS = [
  {
    label: 'Yearly',
    value: 'yearly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Quarterly',
    value: 'quarterly',
  },
];

const UNIT_OF_MEASURE_LABEL_MAP: Record<string, string> = {
  percent: '%',
  day: 'Day(s)',
};

const getModalTitle = (mode: Mode) => {
  let titleAction;
  if (mode === Mode.Add) {
    titleAction = 'Add';
  } else {
    titleAction = 'Change';
  }
  return titleAction + ' Recurring Goal Schedule';
};

function getUnitOfMeasureElement(formikProps: any, sourceFields: GoalSourceFieldType[]) {
  const label = getUnitOfMeasureLabel(formikProps, sourceFields);
  let unitOfMeasureElement: JSX.Element | null = null;
  if (label) {
    unitOfMeasureElement = <div className={styles.unitOfMeasure}>{label}</div>;
  }
  return unitOfMeasureElement;
}

function getUnitOfMeasureLabel(formikProps: any, sourceFields: GoalSourceFieldType[]) {
  const unitOfMeasure = getUnitOfMeasure(formikProps, sourceFields);
  if (unitOfMeasure && UNIT_OF_MEASURE_LABEL_MAP[unitOfMeasure]) {
    return UNIT_OF_MEASURE_LABEL_MAP[unitOfMeasure];
  }
  return null;
}

function getUnitOfMeasure(formikProps: any, sourceFields: GoalSourceFieldType[]) {
  const sourceFieldId = formikProps.values.sourceField;
  const sourceField = getObjectFromListById(sourceFields, sourceFieldId);
  const unitOfMeasure = sourceField?.unitOfMeasure;
  return unitOfMeasure;
}

function getBetterDirection(formikProps: any, sourceFields: GoalSourceFieldType[]) {
  const sourceFieldId = formikProps.values.sourceField;
  const sourceField = getObjectFromListById(sourceFields, sourceFieldId);
  const betterDirection = sourceField?.betterDirection;
  return betterDirection;
}

const ModifyRecurringGoalScheduleDialog = (props: ModifyRecurringGoalScheduleDialogProps) => {
  const { state, onOk, onCancel, onDeleteIcon } = props;
  const mode = getMode(state);

  const handleCancel = () => {
    onCancel();
  };

  const requestConfig = {
    params: {
      // No params
    },
  } as AxiosRequestConfig;
  const { isLoading, error, data } = useQuery(['getAllSourceFields', requestConfig], () => {
    return axios.get<GoalSourceFieldType[]>(GOAL_SOURCE_FIELDS_URL, requestConfig);
  });

  const memberRequestConfig = {
    params: {
      team: props.selectedTeam.id,
    },
  } as AxiosRequestConfig;
  const {
    isLoading: isLoadingMembers,
    error: membersError,
    data: membersData,
  } = useQuery(['getTeamMembersForTeam', memberRequestConfig], () => {
    return axios.get<TeamMemberType[]>(TEAM_MEMBERS_URL, memberRequestConfig);
  });
  if (isLoading || isLoadingMembers)
    return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'Loading...');
  if (error || membersError) return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'Error!');
  if (data === undefined || membersData === undefined)
    return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'No Data!');

  const sourceFields = data.data;
  const sourceFieldOptions = sourceFields.map((sourceField) => {
    const optionLabel = sourceField.name + ' - ' + sourceField.source.name;
    return {
      label: optionLabel,
      value: sourceField.id,
    };
  });

  const teamMembers = membersData.data;
  const teamMemberOptions = teamMembers.map((teamMember) => {
    const optionLabel = getCallSignWithPositionAndName(teamMember);
    return {
      label: optionLabel,
      value: teamMember.id,
    };
  });

  // TODO should this be in some sort of useState hook?
  var initialStateOfEditableScheduleFields;
  let onSubmit;
  let deleteButton: JSX.Element | null;
  let createdByText: JSX.Element | null;
  let allowAutoPush = false;
  let autoPushExceedByDeltaValue: number | undefined;
  let autoPushChangeDeltaValue: number | undefined;
  let autoPushBestExpectedValue: number | undefined;
  if (mode === Mode.Change) {
    // Tell typescript that taskToEdit is definitely not null
    // TODO this is hacky, find a way to fix it
    const schedule = state.objectToModify!;

    // Read only
    allowAutoPush = schedule.allowAutoPush;
    autoPushExceedByDeltaValue = schedule.autoPushExceedByDeltaValue;
    autoPushChangeDeltaValue = schedule.autoPushChangeDeltaValue;
    autoPushBestExpectedValue = schedule.autoPushBestExpectedValue;

    // Copy only editable fields
    initialStateOfEditableScheduleFields = {
      // Not editable
      // id: task.id,
      frequency: schedule.frequency,
      startDateTime: schedule.startDateTime,

      summaryTemplate: schedule.summaryTemplate,
      linkUrlTemplate: schedule.linkUrlTemplate,
      descriptionTemplate: schedule.descriptionTemplate,

      scoringType: schedule.scoringType,
      totalPoints: schedule.totalPoints,
      sourceField: schedule.sourceField,
      goalValue: getDisplayValue(schedule.goalValue, schedule.unitOfMeasure),
      cutoffValue: getDisplayValue(schedule.cutoffValue, schedule.unitOfMeasure),

      enableAutoPush: schedule.enableAutoPush,

      assignedToMember: schedule.assignedToMember?.id,

      secondaryAssignedToMembers: convertSecondaryAssignedToMembersTypeToSend(schedule.secondaryAssignedToMembers),

      // Not editable in the UI, but needed on create, so included
      // TODO now create is separated, is above comment still valid???
      team: schedule.team.id,
    } as RecurringGoalScheduleTypeToSend;
    const createdByName = getNameOfCreationUser(schedule);
    createdByText = getFormikReadOnlyField('Created by:', createdByName);

    deleteButton = (
      <Button
        className="bi-trash-fill mr-auto"
        variant="danger"
        onClick={() => {
          onDeleteIcon(schedule);
        }}
      >
        Delete
      </Button>
    );

    onSubmit = (updatedSchedule: RecurringGoalScheduleTypeToSend, { setSubmitting }: any) => {
      console.log('update schedule dialog ok hit', updatedSchedule);
      const sourceField = getObjectFromListById(sourceFields, updatedSchedule.sourceField);
      const unitOfMeasure = sourceField?.unitOfMeasure;
      // TODO this is hacky
      updatedSchedule._unitOfMeasure = unitOfMeasure;
      setTimeout(() => {
        // TODO make this typescript cast have a validation or something
        onOk(updatedSchedule, schedule.id).then(() => {
          // In case new tags were created
          // refetch();
          setSubmitting(false);
        });
      }, 400);
    };
  } else {
    // Create
    initialStateOfEditableScheduleFields = {
      frequency: '',
      startDateTime: '',

      summaryTemplate: '',
      linkUrlTemplate: '',
      descriptionTemplate: '',

      scoringType: '',
      totalPoints: undefined,
      sourceField: undefined,
      goalValue: undefined,
      cutoffValue: undefined,

      enableAutoPush: false,

      assignedToMember: undefined,

      secondaryAssignedToMembers: [],

      team: props.selectedTeam.id,
    } as RecurringGoalScheduleTypeToSend;
    onSubmit = (newSchedule: RecurringGoalScheduleTypeToSend, { setSubmitting }: any) => {
      console.log('create schedule dialog ok hit', newSchedule);
      const sourceField = getObjectFromListById(sourceFields, newSchedule.sourceField);
      const unitOfMeasure = sourceField?.unitOfMeasure;
      // TODO this is hacky
      newSchedule._unitOfMeasure = unitOfMeasure;
      setTimeout(() => {
        // TODO make this typescript cast have a validation or something
        onOk(newSchedule, null).then(() => {
          // In case new tags were created
          // refetch();
          setSubmitting(false);
        });
      }, 400);
    };
    deleteButton = null;
    createdByText = null;
  }

  const RecurringGoalScheduleFormSchema = Yup.object().shape({
    frequency: Yup.string().nullable().required('Required'),
    startDateTime: Yup.date().required('Required'),

    summaryTemplate: Yup.string().required('Required'),
    linkUrlTemplate: Yup.string().optional(),
    descriptionTemplate: Yup.string().optional(),
    scoringType: Yup.string().nullable().required('Required'), // TODO make enum
    totalPoints: Yup.number().required('Required'), // TODO make integer only
    sourceField: Yup.number().nullable().required('Required'),
    goalValue: Yup.number().required('Required'), // TODO make decimal or integer
    cutoffValue: Yup.number().when('scoringType', {
      is: LINEAR_FROM_CUTOFF,
      then: Yup.number().required('Required'), // TODO make decimal or integer
      otherwise: Yup.number().optional(),
    }),
    enableAutoPush: Yup.boolean().required('Required'),
    assignedToMember: Yup.number().nullable(),
    secondaryAssignedToMembers: Yup.array()
      .of(
        Yup.object().shape({
          assignedToMember: Yup.number().nullable().required('Required'),
          totalPoints: Yup.number().required('Required'),
        }),
      )
      .optional(),
    // TODO tags?
  });

  function getSecondaryAssignedToLabel(index: number) {
    // primary assigned to is #1 to humans, so index 0 (first) assigned to of this array is #2
    const numberOfAssignedTo = index + 2;
    return 'Assigned to #' + numberOfAssignedTo;
  }

  function getSecondaryAssignedToWeightLabel(index: number) {
    // primary assigned to is #1 to humans, so index 0 (first) assigned to of this array is #2
    const numberOfAssignedTo = index + 2;
    return 'Weight for #' + numberOfAssignedTo;
  }

  return (
    // This component causes 'findDOMNode is deprecated in StrictMode' warning
    // Unfortunately, this workaround on SO didn't work https://stackoverflow.com/a/64325602
    // The best way to fix this is to upgrade to react-bootstrap 2.x/bootstrap 5, but that takes some work
    // TODO fix this warning
    <Modal show={state.isOpen} onHide={handleCancel}>
      {
        <Formik
          initialValues={initialStateOfEditableScheduleFields}
          validationSchema={RecurringGoalScheduleFormSchema}
          onSubmit={onSubmit}
        >
          {(formikProps: FormikProps<RecurringGoalScheduleTypeToSend>) => {
            const summaryTemplate = formikProps.values.summaryTemplate;
            const handleSourceFieldChange = (option: SingleValue<GenericOption>, setFieldValue: any) => {
              if (option && option.value && !summaryTemplate) {
                const sourceField = getObjectFromListById(sourceFields, option.value);
                if (sourceField) {
                  setFieldValue('summaryTemplate', sourceField.name);
                } else {
                  console.error('handleSourceFieldChange encountered bad option lookup: ', option, sourceFields);
                }
              }
            };

            const unitOfMeasureElement = getUnitOfMeasureElement(formikProps, sourceFields);
            const unitOfMeasureLabel = getUnitOfMeasureLabel(formikProps, sourceFields);
            const unitOfMeasure = getUnitOfMeasure(formikProps, sourceFields);
            const betterDirection = getBetterDirection(formikProps, sourceFields);
            let goalValueLabel = 'Goal Value';
            let cutoffLabel = 'Partial Credit Start/Limit';
            if (betterDirection) {
              goalValueLabel = goalValueLabel += ' (' + betterDirection.toLowerCase() + ' is better)';
              if (betterDirection.toLowerCase() === 'higher') {
                cutoffLabel = 'Partial Credit Starting Point';
              } else if (betterDirection.toLowerCase() === 'lower') {
                cutoffLabel = 'Partial Credit Limit';
              }
            }

            const filteredTeamMemberOptions = teamMemberOptions.filter((teamMember) => {
              // When we add secondary teamMembers don't allow multiple of the same
              return (
                formikProps.values.assignedToMember !== teamMember?.value &&
                formikProps.values.secondaryAssignedToMembers.find(
                  (secondaryTeamMember) => secondaryTeamMember.assignedToMember === teamMember?.value,
                ) === undefined
              );
            });
            return (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>{getModalTitle(mode)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* Only show createdByText on editing */}
                  {createdByText}
                  <FormikController control="select" name="frequency" label="Frequency" options={FREQUENCY_OPTIONS} />
                  <FormikController
                    control="date"
                    name="startDateTime"
                    label="Start Date (Recurs on this day)"
                    timezone={props.selectedTeam.timezone}
                  />
                  <FormikController
                    control="select"
                    name="sourceField"
                    label="Data Source"
                    options={sourceFieldOptions}
                    onChange={handleSourceFieldChange}
                  />
                  <FormikController control="input" name="summaryTemplate" label="Summary" />
                  <FormikController control="input" name="linkUrlTemplate" label="Link (optional)" />
                  <FormikController control="input" name="descriptionTemplate" label="Description (optional)" />
                  <FormikController control="input" name="totalPoints" label="Weight" />
                  <FormikController
                    control="select"
                    name="assignedToMember"
                    label="Assigned To"
                    options={teamMemberOptions}
                  />
                  {/*https://formik.org/docs/api/fieldarray*/}
                  <FieldArray name="secondaryAssignedToMembers">
                    {(arrayHelpers: FieldArrayRenderProps) => {
                      return (
                        <div>
                          {formikProps.values.secondaryAssignedToMembers.map((assignedToMember, index) => {
                            return (
                              <div className="d-flex">
                                <FormikController
                                  className="flex-grow-1"
                                  control="select"
                                  name={`secondaryAssignedToMembers[${index}].assignedToMember`}
                                  label={getSecondaryAssignedToLabel(index)}
                                  options={filteredTeamMemberOptions}
                                />
                                <FormikController
                                  className="flex-grow-1"
                                  control="input"
                                  name={`secondaryAssignedToMembers[${index}].totalPoints`}
                                  label={getSecondaryAssignedToWeightLabel(index)}
                                />
                                <Button
                                  className="align-self-start"
                                  variant="outline-danger"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  x
                                </Button>
                              </div>
                            );
                          })}
                          <div className="d-flex justify-content-end">
                            <Button
                              variant="outline-primary"
                              onClick={() =>
                                arrayHelpers.push({
                                  // Without these, errors won't show up
                                  assignedToMember: undefined,
                                  totalPoints: undefined,
                                })
                              }
                            >
                              Add more +
                            </Button>
                          </div>
                        </div>
                      );
                    }}
                  </FieldArray>
                  <FormikController
                    control="select"
                    name="scoringType"
                    label="Scoring Type"
                    options={SCORING_TYPE_OPTIONS}
                  />
                  <div className="d-flex align-items-center">
                    <FormikController className="flex-grow-1" control="input" name="goalValue" label={goalValueLabel} />
                    {unitOfMeasureElement}
                  </div>
                  {allowAutoPush && (
                    <Row>
                      <Col md={2} />
                      <Col>
                        <FormikController control="checkbox" name="enableAutoPush" label="Enable Auto Push" />
                        {formikProps.values.enableAutoPush && (
                          <>
                            <p className={styles.autoPushDescription}>
                              If the team gets a result of {betterDirection} than
                              <span className={styles.autoPushDescriptionValue}>
                                {' ' +
                                  getAutoPushExceedByAbsoluteValue(
                                    formikProps.values.goalValue,
                                    getDisplayValue(autoPushExceedByDeltaValue, unitOfMeasure),
                                    betterDirection,
                                  )}
                                {' ' + unitOfMeasureLabel}
                              </span>
                              <br />
                              Then the next goal will be set to
                              <span className={styles.autoPushDescriptionValue}>
                                {' ' +
                                  getAutoPushChangeAbsoluteValue(
                                    formikProps.values.goalValue,
                                    getDisplayValue(autoPushChangeDeltaValue, unitOfMeasure),
                                    betterDirection,
                                  ) +
                                  ' '}
                                {unitOfMeasureLabel}
                              </span>
                              <br />
                              This will continue{' '}
                              {autoPushBestExpectedValue ? 'until the goal reaches ' : "until goals can't be reached"}
                              {autoPushBestExpectedValue && (
                                <span className={styles.autoPushDescriptionValue}>
                                  {' ' + getDisplayValue(autoPushBestExpectedValue, unitOfMeasure) + ' '}
                                  {unitOfMeasureLabel}
                                </span>
                              )}
                            </p>
                          </>
                        )}
                      </Col>
                    </Row>
                  )}
                  {formikProps.values.scoringType === LINEAR_FROM_CUTOFF && (
                    <>
                      <div className="d-flex align-items-center">
                        <FormikController
                          className="flex-grow-1"
                          control="input"
                          name="cutoffValue"
                          label={cutoffLabel}
                        />
                        {unitOfMeasureElement}
                      </div>
                      {formikProps.values.enableAutoPush && (
                        <Row>
                          <Col md={2} />
                          <Col>
                            <p className={styles.autoPushDescription}>{cutoffLabel} is not effected by auto-push</p>
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                  <div style={{ height: 100 }}>
                    <GoalScoringVisualLineGraph
                      goalScheduleToGraph={formikProps.values}
                      betterDirection={betterDirection}
                      unitOfMeasure={unitOfMeasure}
                    />
                  </div>
                  {/* 'startingOptions' because we are allowing the creation of new tags */}
                  {/*<FormikController control="tags" name="tags" label="Tags" startingOptions={tagOptions}/>*/}
                </Modal.Body>
                <Modal.Footer>
                  {/* Only show delete button on editing */}
                  {deleteButton}
                  <Button variant="secondary" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit" disabled={formikProps.isSubmitting}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      }
    </Modal>
  );
};

function defaultHandleModifyRecurringGoalScheduleDialogOk(
  modifiedSchedule: RecurringGoalScheduleTypeToSend,
  idToUpdate: number | null,
  closeModifyDialog: () => void,
  refetchSchedules: () => void,
  addFeedbackMessage: (feedbackMessage: FeedbackMessage) => void,
) {
  // TODO I don't like this it is hacky
  const modifiedScheduleToSend = convertToTypeToSend(modifiedSchedule, modifiedSchedule._unitOfMeasure);
  let promise;
  if (idToUpdate !== null) {
    console.log('Updating schedule ' + idToUpdate, modifiedScheduleToSend);
    // POST is for new stuff, PUT is for replacing task (must have ALL fields)
    // This uses PATCH, which loads old task and only updates fields you passed
    // https://stackoverflow.com/a/24241955/13815107
    promise = axios
      .patch(RECURRING_GOAL_SCHEDULES_URL + '/' + idToUpdate + '/', modifiedScheduleToSend)
      .then((response) => {
        closeModifyDialog();
        addFeedbackMessage({
          key: createFeedbackMessageKey('recurringTaskSchedule', 'change', idToUpdate),
          status: 'success',
          messageBody: <span>Recurring goal schedule updated successfully.</span>,
        });
        refetchSchedules();
      });
  } else {
    console.log('Creating schedule', modifiedScheduleToSend);
    if (modifiedScheduleToSend.id) {
      console.error('Creating a schedule but sending an id. Was this meant to be a modify?');
      promise = Promise.reject('Creating a schedule but sending an id. Was this meant to be a modify?');
    } else {
      promise = axios.post(RECURRING_GOAL_SCHEDULES_URL + '/', modifiedScheduleToSend).then((response) => {
        closeModifyDialog();
        addFeedbackMessage({
          key: createFeedbackMessageKey('recurringTaskSchedule', 'create'),
          status: 'success',
          messageBody: <span>Recurring goal schedule created successfully.</span>,
        });
        refetchSchedules();
      });
    }
  }
  return promise;
}

function getAutoPushChangeAbsoluteValue(
  goalValue: number | undefined,
  autoPushChangeDeltaValue: number | undefined,
  betterDirection?: string,
) {
  if (goalValue && autoPushChangeDeltaValue && betterDirection) {
    const changeDeltaNumber = Number(autoPushChangeDeltaValue);
    const goalValueNumber = Number(goalValue);
    if (betterDirection && betterDirection.toLowerCase() === 'higher') {
      return goalValueNumber + changeDeltaNumber;
    } else if (betterDirection && betterDirection.toLowerCase() === 'lower') {
      return goalValueNumber - changeDeltaNumber;
    } else {
      return '???';
    }
  }
  return '???';
}

function getAutoPushExceedByAbsoluteValue(
  goalValue: number | undefined,
  autoPushExceedByDeltaValue: number | undefined,
  betterDirection?: string,
) {
  if (goalValue && autoPushExceedByDeltaValue && betterDirection) {
    const exceedByDeltaNumber = Number(autoPushExceedByDeltaValue);
    const goalValueNumber = Number(goalValue);
    if (betterDirection && betterDirection.toLowerCase() === 'higher') {
      return goalValueNumber + exceedByDeltaNumber;
    } else if (betterDirection && betterDirection.toLowerCase() === 'lower') {
      return goalValueNumber - exceedByDeltaNumber;
    } else {
      return '???';
    }
  }
  return '???';
}

export default ModifyRecurringGoalScheduleDialog;
export {
  defaultHandleModifyRecurringGoalScheduleDialogOk,
  getUnitOfMeasureElement,
  getUnitOfMeasureLabel,
  getUnitOfMeasure,
  getBetterDirection,
};
