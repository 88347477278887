import AccountType from '../domain/account';

function getAccountString(account: AccountType): string {
  let accountString;
  if (account.email && account.firstName && account.lastName) {
    accountString = account.email + ' - ' + account.firstName + ' ' + account.lastName;
  } else if (account.email) {
    accountString = account.email;
  } else {
    accountString = 'Unknown user with auth0Id: ' + account.auth0Id;
  }
  return accountString;
}

function getAccountName(account: AccountType): string {
  let accountString;
  if (account.firstName) {
    accountString = account.firstName;
    if (account.lastName) {
      accountString += ' ' + account.lastName;
    }
  } else if (account.lastName) {
    accountString = account.lastName;
  } else if (account.email) {
    accountString = account.email;
  } else {
    accountString = 'UNASSIGNED';
  }
  return accountString;
}

function getShortAccountName(account: AccountType): string {
  let accountString;
  if (account.firstName) {
    accountString = account.firstName;
  } else if (account.email) {
    accountString = account.email.split('@')[0];
  } else {
    accountString = 'UNASSIGNED';
  }
  return accountString;
}

export { getAccountString, getAccountName, getShortAccountName };
