import axios, { AxiosResponse } from 'axios';
import { ICellRendererParams } from 'ag-grid-community';
import PersonCell from './PersonCell';
import AccountType from '../../../../../domain/account';
import FieldCoreProcessType from '../../../../../domain/fieldCoreProcess';

type LeadCellProps = ICellRendererParams & {
  canEditFcps: boolean;
  leadPeople: AccountType[];
};

function LeadCell(props: LeadCellProps) {
  const fcpId = props.data?.id;

  const onPersonChange = (newId: number) => {
    axios
      .put<any, AxiosResponse<FieldCoreProcessType>>(
        `${'field-core-processes/' + fcpId + '/lead'}`,
        //Set ID to the lead id unless choosing the blank option
        { lead_id: newId },
      )
      .then((response) => {
        props.node.setData(response.data);
      });
  };

  const person = props.data?.lead;
  return <PersonCell person={person} onPersonChange={onPersonChange} {...props} />;
}

export default LeadCell;
