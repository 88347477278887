import axios, { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import {
  FIELD_CORE_PROCESS_SUBSTEP_INSTALLATION_STATS_URL,
  FIELD_CORE_PROCESS_SUBSTEP_INSTALLATION_URL,
} from '../../../constants/urls';
import FcpSubstepInstallationType from '../../../domain/fcpSubstepInstallation';
import FcpSubstepInstallationStatType from '../../../domain/fcpSubstepInstallationStat';

function useGetSubstepInstallationsByAssignee(assigneeId: number | undefined) {
  const queryOptions = {
    params: {
      assignee: assigneeId,
    },
  } as AxiosRequestConfig;
  return useQuery(
    ['useGetSubstepInstallationsByAssignee', queryOptions],
    async () => {
      const response = await axios.get<FcpSubstepInstallationType[]>(
        FIELD_CORE_PROCESS_SUBSTEP_INSTALLATION_URL,
        queryOptions,
      );
      return response.data;
    },
    {
      enabled: assigneeId !== undefined,
    },
  );
}

function resetSubstepInstallationsOfTeams(teamIds: number[], substepIds: number[]) {
  const data = {
    teams: teamIds,
    substeps: substepIds,
  };
  return axios.put(`${FIELD_CORE_PROCESS_SUBSTEP_INSTALLATION_URL}reset/`, data);
}

function useGetSubstepInstallationStats(startDate: string, endDate: string) {
  const queryOptions = {
    params: {},
  } as AxiosRequestConfig;
  return useQuery(['useGetSubstepInstallationStatsByDepartment', queryOptions], async () => {
    const response = await axios.get<FcpSubstepInstallationStatType[]>(
      `${FIELD_CORE_PROCESS_SUBSTEP_INSTALLATION_STATS_URL}/${startDate}/${endDate}`,
      queryOptions,
    );
    return response.data;
  });
}

export { useGetSubstepInstallationsByAssignee, resetSubstepInstallationsOfTeams, useGetSubstepInstallationStats };
