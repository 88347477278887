import { ChangeEvent, useEffect, useState } from 'react';
import { CATEGORY } from '../../../../../constants/globals';
import { ICellRendererParams } from 'ag-grid-community';
import AccountType from '../../../../../domain/account';

type LeadPersonCellProps = ICellRendererParams & {
  canEditFcps: boolean;
  onPersonChange: Function;
  leadPeople: AccountType[];
  person: AccountType;
};

function PersonCell(props: LeadPersonCellProps) {
  const [currPerson, setCurrPerson] = useState<number | string>('Unassigned');

  useEffect(() => {
    if (props.person) setCurrPerson(props.person.id);
  }, [props.person]);

  if (!props.data) {
    return <div></div>;
  }
  const onPersonChangeEventHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    const newId = parseInt(event.target.value);
    props.onPersonChange(newId);
  };

  const leadPeopleOptions = [<option key={-1} value={-1}></option>];

  for (let i = 0; i < props.leadPeople.length; i++) {
    // debugger
    leadPeopleOptions.push(
      <option key={i} value={props.leadPeople[i].id}>
        {' '}
        {props.leadPeople[i].firstName}{' '}
      </option>,
    );
  }

  return (
    <div>
      {props.data.description !== CATEGORY && (
        <select disabled={!props.canEditFcps} value={currPerson} onChange={onPersonChangeEventHandler}>
          {leadPeopleOptions}
        </select>
      )}
    </div>
  );
}

export default PersonCell;
