import React, { useState } from "react";
import "../../styles.css"

function ToggleSwitch(props: {callback: Function}) {
  const [isToggled, setIsToggled] = useState(false);
  return (
    <label className="toggle-switch">
      <input type="checkbox" checked={isToggled} onChange={() => {setIsToggled(!isToggled);props.callback()}} />
      <span className="switch" />
    </label>
  );
}
export default ToggleSwitch;