import axios, { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import FcpSubstepType from '../../../domain/fcpSubstep';
import { FIELD_CORE_PROCESS_SUBSTEP_URL } from '../../../constants/urls';

function useGetSubstepsByDepartment(department: string) {
  const queryOptions = {
    params: {
      fcp__department: department,
    },
  } as AxiosRequestConfig;
  return useQuery(['useGetSubstepsByDepartment', queryOptions], async () => {
    const response = await axios.get<FcpSubstepType[]>(FIELD_CORE_PROCESS_SUBSTEP_URL, queryOptions);
    return response.data;
  });
}

export { useGetSubstepsByDepartment };
