import styles from './CustomErrorMessage.module.scss';

type CustomErrorMessageProps = {
  errorMessage: string;
};

const CustomErrorMessage = (props: CustomErrorMessageProps) => {
  return <div className={styles.errorText}>* {props.errorMessage}</div>;
};

export default CustomErrorMessage;
