import React from 'react';
import { IHeaderParams } from 'ag-grid-community';
import { Button, Col, Container, Row } from 'react-bootstrap';

import '../../../../../styles.css';
import TeamType from '../../../../../domain/team';

export interface ICustomHeaderParams extends IHeaderParams {
  teamPaused?: boolean;
  isTeam?: boolean;
  team?: TeamType;
  greenlight?: string;

  teamId: number;
  colId: string;
  timezone: string;

  onEditClick: Function;
  canEditFcps: boolean;
}

function CustomHeader(props: ICustomHeaderParams) {
  // let reformattedGreenlightDate = '';
  // if (props.greenlight) {
  //   reformattedGreenlightDate = reformatDate(DateTime.fromISO(props.greenlight).toLocaleString());
  // }
  // const [confirmDelete, setConfirmDelete] = useState(false);

  /* Handle styling when the pause checkmark is click */
  // const onPauseChange = () => {
  //   axios.put<any, AxiosResponse<TeamType>>(`${'teams/' + props.teamId + '/paused'}`).then((response) => {
  //     const isPausedNow = response.data.isPaused;
  //     const colDef = props.column.getColDef();
  //     colDef.headerComponentParams.teamPaused = isPausedNow;
  //     colDef.cellStyle = (params: any) => {
  //       return getFcpCellStyle(params.data as FieldCoreProcessType, props.teamId, isPausedNow);
  //     };
  //     props.column.setColDef(props.column.getColDef(), colDef);
  //   });
  // };

  /** Set new greenlight date or remove date
   *
   * @param event String of new date
   */
  // const onGreenlightDate = (event: any) => {
  //   let newDate = null;
  //   if (event?.target?.value) {
  //     newDate = DateTime.fromSQL(event.target.value).setZone(props.timezone).toISO();
  //   }
  //   axios
  //     .put<any, AxiosResponse<TeamType>>(`${'teams/' + props.teamId + '/activation'}`, { activation: newDate })
  //     .then((response) => {
  //       const activationDateNow = response.data.activationDate;
  //       const colDef = props.column.getColDef();
  //       colDef.headerComponentParams.greenlight = activationDateNow;
  //       props.column.setColDef(props.column.getColDef(), colDef);
  //     });
  // };

  // const onDeleteClick = () => {
  //   if (props.canEditFcps) {
  //     if (confirmDelete) {
  //       onGreenlightDate(null);
  //     } else {
  //       setConfirmDelete(true);
  //     }
  //   }
  // };

  const teamTitleClasses = props.isTeam ? 'rotate-title ps-2 justify-content-start' : 'justify-content-center';

  return (
    <Container className={'p-0'}>
      <Row>
        <Col className={'d-flex flex-column '}>
          {props.displayName !== 'Implementation Manager' && (
            <div className={'d-flex align-items-center ' + teamTitleClasses}>{props.displayName}</div>
          )}
          {props.displayName === 'Implementation Manager' && (
            <div>
              <div className={'d-flex align-items-center justify-content-center'}>Implementation</div>
              <div className={'d-flex align-items-center justify-content-center'}>Manager</div>
            </div>
          )}
          {props.isTeam && (
            <>
              <Button
                onClick={() => {
                  props.onEditClick(props.team);
                }}
                variant="outline"
                size="sm"
                className={'d-flex justify-content-center'}
              >
                <span className="bi-pencil-fill" />
              </Button>
              {/*<Row>*/}
              {/*  <Col className="p-0">*/}
              {/*    <input*/}
              {/*      type="date"*/}
              {/*      disabled={!props.canEditFcps}*/}
              {/*      className={'date-style-common greenlight-rotate-override fcp-cell'}*/}
              {/*      defaultValue={reformattedGreenlightDate}*/}
              {/*      min="0000-00-00"*/}
              {/*      max="9999-12-31"*/}
              {/*      onBlur={(e) => {*/}
              {/*        onGreenlightDate(e);*/}
              {/*      }}*/}
              {/*    />*/}
              {/*    {reformattedGreenlightDate && (*/}
              {/*      <span onClick={onDeleteClick}>*/}
              {/*        <button*/}
              {/*          className={confirmDelete ? 'clear-greenlight-date-confirm-button' : 'clear-greenlight-date-button'}*/}
              {/*          onMouseLeave={() => setConfirmDelete(false)}*/}
              {/*        >*/}
              {/*          X*/}
              {/*        </button>*/}
              {/*      </span>*/}
              {/*    )}*/}
              {/*  </Col>*/}
              {/*</Row>*/}
              {/*<Row>*/}
              {/*  <Col className="d-flex flex-column align-items-center p-1">*/}
              {/*    <span>*/}
              {/*      <span className={'p-2'}>Pause:</span>*/}
              {/*      <input*/}
              {/*        type="checkbox"*/}
              {/*        disabled={!props.canEditFcps}*/}
              {/*        checked={props.teamPaused}*/}
              {/*        onChange={onPauseChange}*/}
              {/*      />*/}
              {/*    </span>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default CustomHeader;
