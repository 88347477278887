// Based on https://www.antstack.io/blog/reusable-form-components-using-react-formik-yup/
// This uses Formik "Field children" pattern from https://formik.org/docs/api/field
import React from 'react';
import { Field, ErrorMessage, FieldProps } from 'formik';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import CustomErrorMessage from './CustomErrorMessage';

type FormikInputInnerProps = {
  name: string;
  label: string;
  fieldProps: any;
};

// TODO not sure what props from formik controller are yet.
function FormikInput(props: any) {
  const { name, label, className, ...rest } = props;
  return (
    <div className={className}>
      <Field name={name} {...rest}>
        {(fieldProps: FieldProps) => {
          return <FormikInputInner name={name} label={label} fieldProps={fieldProps} />;
        }}
      </Field>
    </div>
  );
}

// Need separate component for debounce react hooks
function FormikInputInner(props: FormikInputInnerProps) {
  // I really want to use debounce but I will need to make every form submit flush all debounces.
  // I'm not sure how to do that right now.
  // const { text, setTextDebounced } = useDebouncedInput(props.fieldProps.field.value, props.fieldProps.field.onChange);

  return (
    // These are react-bootstrap components to get look-and-feel right
    <FormGroup controlId={props.name}>
      {props.label && <FormLabel>{props.label}</FormLabel>}
      {/*https://stackoverflow.com/a/65826542/13815107 
                react-bootstrap inputs are never supposed to be null 
                Formik also can't handle nulls (weird error text) or undefineds (NO error text) well
                Therefore, null/undefined should NEVER be passed in, use '' */}
      <FormControl type={'text'} value={props.fieldProps.field.value} onChange={props.fieldProps.field.onChange} />
      {/*<FormControl type={'text'} value={props.fieldProps.field.value} onChange={props.fieldProps.field.onChange} />*/}
      {/* There is a component={} field on ErrorMessage, but I can't figure out the props it passes */}
      <ErrorMessage name={props.name}>
        {(errorMessage) => {
          return <CustomErrorMessage errorMessage={errorMessage} />;
        }}
      </ErrorMessage>
    </FormGroup>
  );
}

export default FormikInput;
