// import { Container } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';
import TeamType from '../../domain/team';
import Dashboard from '../../pages/Dashboard/Dashboard';
import MemberAdmin from '../../pages/MemberAdmin/MemberAdmin';
import MemberScores from '../../pages/MemberScores/MemberScores';
import AllAssignments from '../../pages/AllAssignments/AllAssignments';
import FieldCoreAssignments from '../../pages/FieldCoreAssignments/FieldCoreAssignments';
import React from 'react';
import { SmrResponsibilities } from '../../pages/SmrResponsibilities/SmrResponsibilities';
import { usePermissions } from '../../effects/usePermissions';
import TeamTasks from '../../pages/TeamTasks/TeamTasks';
import { FieldCoreProcesses } from '../../pages/FieldCoreProcesses/FieldCoreProcesses';
import TeamScores from '../../pages/TeamScores/TeamScores';

const NO_PERMISSION_ELEMENT = (
  <p>
    {' '}
    You don't have permission for this page. <br />
    Please log in or contact an administrator to give you permission.
  </p>
);

type AllRoutesProps = {
  selectedTeam: TeamType | null;
};

const AllRoutes = (props: AllRoutesProps) => {
  const permissionResponse = usePermissions(props.selectedTeam);
  if (permissionResponse.isLoading) return <p>Loading...</p>;
  if (permissionResponse.error || !permissionResponse.permissionFlags) return <p>Error!</p>;

  const permissionFlags = permissionResponse.permissionFlags;

  // Exclamation points to tell typescript 'I know as a dev that selectedTeam can't be null here'
  // This is because we check it in permissionFlags.canViewDashboard, etc
  const dashboardElement = permissionFlags.canViewDashboard ? (
    <Dashboard selectedTeam={props.selectedTeam!} />
  ) : (
    NO_PERMISSION_ELEMENT
  );
  const allAssignmentsElement = permissionFlags.canViewAllAssignments ? (
    <AllAssignments selectedTeam={props.selectedTeam!} />
  ) : (
    NO_PERMISSION_ELEMENT
  );
  const memberScoresElement = permissionFlags.canViewMemberScores ? (
    <MemberScores selectedTeam={props.selectedTeam!} />
  ) : (
    NO_PERMISSION_ELEMENT
  );
  const teamTasks = permissionFlags.canViewMemberScores ? (
    <TeamTasks selectedTeam={props.selectedTeam!} />
  ) : (
    NO_PERMISSION_ELEMENT
  );

  const teamScoresElement = permissionFlags.canViewTeamScores ? (
    <TeamScores selectedTeam={props.selectedTeam!} />
  ) : (
    NO_PERMISSION_ELEMENT
  );
  const memberAdminElement = permissionFlags.canViewMemberAdmin ? (
    <MemberAdmin selectedTeam={props.selectedTeam!} />
  ) : (
    NO_PERMISSION_ELEMENT
  );

  // TODO add canViewFieldCoreProcesses
  const fieldCoreProcessesPermission = true;
  // const fieldCoreProcessesPermission = permissionFlags.canViewFieldCoreProcesses;
  const fieldCoreProcessesElement = fieldCoreProcessesPermission ? <FieldCoreProcesses /> : NO_PERMISSION_ELEMENT;

  const fieldCoreAssignmentsElement = permissionFlags.canViewFcpAssignments ? (
    <FieldCoreAssignments
      // No exclamation point because there is no hasSelectedTeam check for permissionFlags.canViewGlobalCoreAssignments
      selectedTeam={props.selectedTeam}
      canViewGlobalCoreAssignments={permissionFlags.canViewGlobalCoreAssignments}
      canViewTeamCoreAssignments={permissionFlags.canViewTeamCoreAssignments}
    />
  ) : (
    NO_PERMISSION_ELEMENT
  );

  const smrAssignmentsElement = permissionFlags.canViewSmrAssignments ? (
    <SmrResponsibilities selectedTeam={props.selectedTeam!} />
  ) : (
    NO_PERMISSION_ELEMENT
  );

  return (
    <Routes>
      <Route path="/" element={dashboardElement} />
      <Route path="all-assignments" element={allAssignmentsElement} />
      <Route path="member-scores" element={memberScoresElement} />
      <Route path="team-tasks" element={teamTasks} />
      <Route path="team-scores" element={teamScoresElement} />
      <Route path="member-admin" element={memberAdminElement} />
      <Route path="field-core-assignments" element={fieldCoreAssignmentsElement} />
      <Route path="field-core-process-record/at" element={fieldCoreProcessesElement} />
      <Route path="field-core-process-record/fh" element={fieldCoreProcessesElement} />
      <Route path="field-core-process-record/substeps/at" element={fieldCoreProcessesElement} />
      <Route path="field-core-process-record/substeps/fh" element={fieldCoreProcessesElement} />
      <Route path="field-core-process-record/substep-lookup" element={fieldCoreProcessesElement} />
      <Route path="field-core-process-record/substep-stats" element={fieldCoreProcessesElement} />
      <Route path="smr-responsibilities" element={smrAssignmentsElement} />
    </Routes>
  );
};

export default AllRoutes;
