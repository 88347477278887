import axios, { AxiosRequestConfig } from 'axios';
import { Col, Container, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { MEMBERS_SCORE_HISTORY_URL } from '../../constants/urls';
import TeamType from '../../domain/team';
import React, { useState } from 'react';
import MembersScoreBreakdownBarChart from '../../components/scores/TeamScoreHistoryGrid/MembersScoreBreakdownBarChart/MembersScoreBreakdownBarChart';
import MembersScoreHistoryGrid from '../../components/scores/TeamScoreHistoryGrid/MembersScoreHistoryGrid/MembersScoreHistoryGrid';
import SelectedCallsignContext from '../../contexts/SelectedCallsignContext';

type TeamScoresProps = {
  selectedTeam: TeamType;
};

type MembersScoreHistoryMonthScore = {
  // 2020-07-21
  month: string;
  score: number;
};

type MembersScoreHistoryMember = {
  id: string;
  name: string;
  callSign: string;
  scores: MembersScoreHistoryMonthScore[];
};

const TeamScores = (props: TeamScoresProps) => {
  const [selectedCallsign, setSelectedCallsign] = useState(null);
  const requestConfig = {
    params: {
      team: props.selectedTeam.id,
    },
  } as AxiosRequestConfig;

  const { isLoading, error, data } = useQuery(['getMembersScoreHistoryForTeam', requestConfig], () => {
    return axios.get<MembersScoreHistoryMember[]>(MEMBERS_SCORE_HISTORY_URL, requestConfig);
  });
  if (isLoading) return <p>Loading...</p>;
  if (error || data === undefined) return <p>Error!</p>;

  const allTeamMembersScores = data.data;

  return (
    // @ts-ignore
    <SelectedCallsignContext.Provider value={{ selectedCallsign, setSelectedCallsign }}>
      <Container fluid>
        <Row>
          <Col>
            <h4 className="text-center font-weight-bold">Team Scores</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Container fluid>
              <div style={{ width: '100%', height: '30%' }}>
                <MembersScoreBreakdownBarChart
                  selectedTeam={props.selectedTeam}
                  allTeamMembersScores={allTeamMembersScores}
                />
              </div>
            </Container>
          </Col>
        </Row>
        <Row>
          <Col>
            <Container fluid>
              <div style={{ width: '100%', height: '30%' }}>
                <MembersScoreHistoryGrid
                  selectedTeam={props.selectedTeam}
                  allTeamMembersScores={allTeamMembersScores}
                />
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
    </SelectedCallsignContext.Provider>
  );
};

export type { MembersScoreHistoryMember };

export default TeamScores;
