import { AgGridReact } from 'ag-grid-react';
import { Col, Container, Row } from 'react-bootstrap';
import { getDefaultColDef } from '../../../../helpers/GridComponentHelpers';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { IsGroupOpenByDefaultParams } from 'ag-grid-community';

import axios from 'axios';
import FcpSubstepType from '../../../../domain/fcpSubstep';
import InstallationRenderer from './renderers/installationRenderer';
import TeamType from '../../../../domain/team';
import { AssigneeWithColor, getAssigneesWithColor } from '../../../../helpers/FieldCoreProcessHelpers';

import '../../../../styles.css';
import ToggleSwitch from '../../../../helpers/ToggleSwitch/ToggleSwitch';
import AccountType from '../../../../domain/account';
import HeaderWithButton from '../FcpRecordGrid/renderers/HeaderWithButton';
import ResetSubstepsDialog from '../ResetSubstepsDialog/ResetSubstepsDialog';

type SubstepRecordGridProps = {
  department: string;
  departmentFcpSubsteps: FcpSubstepType[];
  departmentTeams: TeamType[];
  headerHeight: number;
  refetchSubsteps: Function;
  canEditSubsteps: boolean;
  leadPeople: AccountType[];
};

const getColumns = (teams: TeamType[], assignees: AssigneeWithColor[], canEditSubs: boolean) => {
  var cols = [
    {
      field: 'fcpName',
      width: 160,
      rowGroup: true,
      hide: true,
      minWidth: 150,
      maxWidth: 500,
      menuTabs: [],
      lockPosition: true,
      pinned: true,
      suppressColumnsToolPanel: true,
    },
    // {
    //  THIS IS MOVED TO autoGroupColumnDef
    //   field: 'name',
    // },
    ...teams.map((team) => ({
      headerName: team.country,
      width: 165,
      resizable: true,
      filter: false,
      sortable: false,
      menuTabs: [],
      cellStyle: { display: 'flex' },
      cellClass: team.isPaused ? 'paused-fcp' : '',
      cellRenderer: InstallationRenderer,
      cellRendererParams: {
        team: team,
        assignees: assignees,
        canEditSubs: canEditSubs,
      },
    })),
  ];
  return cols;
};

const SubstepRecordGrid = (props: SubstepRecordGridProps) => {
  const title = props.department + ' Substeps';
  const assignees = getAssigneesWithColor(props.leadPeople);
  const [columnDefs] = useState(getColumns(props.departmentTeams, assignees, props.canEditSubsteps));
  const [isOpen, setIsOpen] = useState(false);
  const autoGroupColumnDef = useMemo(() => {
    return {
      headerComponent: HeaderWithButton,
      headerComponentParams: {
        title: title,
        buttonText: 'Reset Substeps',
        onClick: () => setIsOpen(true),
      },
      field: 'name',
      minWidth: 250,
      cellRendererParams: {
        suppressCount: true,
      },
      menuTabs: [],
      lockPosition: true,
      pinned: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      editable: props.canEditSubsteps,
      suppressColumnsToolPanel: true,
      valueSetter: (params: any) => {
        //Name could have letters, spaces, parentheses, and maybe numbers
        //And can't be empty.
        const re = new RegExp('^[0-9a-zA-Z ()]+$');
        if (re.test(params.newValue)) params.data.name = params.newValue.trim();
        return true;
      },
      // cellRenderer: 'nameRenderer'
    };
  }, [props.canEditSubsteps, title]);

  const [gridReady, setGridReady] = useState<boolean>(false);
  const [expand, setExpand] = useState<boolean>(false);
  const expandState = useRef<boolean>(false);
  expandState.current = expand;
  const gridApiRef = useRef<any>(null);

  //Set the API ref once Grid is ready
  const onGridReady = (params: any) => {
    params.api.closeToolPanel();
    gridApiRef.current = params.api;
  };

  //Expand or collapse row groups using button
  const onExpandCollapse = () => {
    if (gridReady) {
      if (expand) gridApiRef.current.collapseAll();
      else gridApiRef.current.expandAll();
    }
    setExpand(!expand);
  };

  //Conditionally set the css classname(s) of rows
  const rowClassRules = useMemo(() => {
    return {
      'paused-fcp': (params: any) => {
        if (!params.data) {
          /**This obscure-looking reference is actually logical:
           * every row will have a node object that gives you the data
           * about the row as opposed to the data attribute, which
           * is undefined for the group dropdown rows (they say an fcp nam
           * and all they do is expand, they don't "have" data). That node
           * has allLeafChildren which is all the substeps inside the group (I do 0th
           * as it is guaranteed), and the node returned HAS data as it is a
           * substep. Then just get fcpsPaused. Simple :)
           */
          return params.node.allLeafChildren[0].data.fcpIsPaused;
        } else {
          return params.data.fcpIsPaused;
        }
      },
    };
  }, []);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            // suppress sections of column tool panel
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            // prevents custom layout changing when columns are reordered in the grid
            //suppressSyncLayoutWithGrid: true,
            // prevents columns being reordered from the columns tool panel
            //suppressColumnMove: true,
          },
        },
      ],
      defaultToolPanel: 'columns',
    };
  }, []);

  const onCellValueChanged = (params: any) => {
    axios.put(`${'fcp-substeps/' + params.data.id + '/name'}`, { name: params.newValue });
    props.refetchSubsteps();
  };

  //Close rows by default on startup
  const isGroupOpenByDefault = useCallback((params: IsGroupOpenByDefaultParams) => {
    return expandState.current;
  }, []);

  return (
    <Container fluid className="d-flex flex-column flex-grow-1 p-0">
      <Row className="d-flex align-items-start justify-content-start pl-3">
        <Col
          className="d-flex align-items-start justify-content-start p-0"
          style={{ fontSize: '.82em' }}
          sm={4}
          md={4}
          lg={2}
          xl={2}
        >
          <span className={'pr-2'}>Expand/Collapse Rows</span>
          <ToggleSwitch callback={onExpandCollapse} />
        </Col>
      </Row>
      <Row className="d-flex flex-column flex-grow-1">
        <div className="ag-theme-balham grid-wrapper">
          <AgGridReact
            ref={gridApiRef}
            debounceVerticalScrollbar={true}
            suppressMenuHide={true}
            suppressContextMenu={true}
            // animateRows={true}
            tooltipShowDelay={450}
            autoGroupColumnDef={autoGroupColumnDef}
            groupRemoveSingleChildren={false}
            groupRemoveLowestSingleChildren={true}
            isGroupOpenByDefault={isGroupOpenByDefault}
            defaultColDef={getDefaultColDef()}
            rowClassRules={rowClassRules}
            // statusBar={getDefaultStatusBar()}
            headerHeight={props.headerHeight}
            rowHeight={30}
            rowData={props.departmentFcpSubsteps}
            columnDefs={columnDefs}
            onCellValueChanged={onCellValueChanged}
            suppressRowHoverHighlight={true}
            onGridReady={onGridReady}
            sideBar={sideBar}
            onFirstDataRendered={function (params) {
              params.columnApi.setColumnWidth('ag-Grid-AutoColumn', 350);
              setGridReady(true);
            }}
          />
          <ResetSubstepsDialog
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            fcpSubsteps={props.departmentFcpSubsteps}
            onOk={(test) => {
              return Promise.resolve();
            }}
            department={props.department}
          />
        </div>
      </Row>
    </Container>
  );
};

export default SubstepRecordGrid;
