import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import SelectedMonthContext from '../../../../contexts/SelectedMonthContext';
import TeamMemberType from '../../../../domain/teamMember';
import TeamType from '../../../../domain/team';
import CompletedTasksGrid from '../../../tasks/CompletedTaskGrid/CompletedTaskGrid';
import MemberScoreSummaryPieChart from '../MemberScoreSummaryPieChart/MemberScoreSummaryPieChart';
import CompletedGoalsGrid from '../../../goals/CompletedGoalGrid/CompletedGoalGrid';
import MonthSelect from '../../../MonthSelect/MonthSelect';
import styles from './MonthlyScores.module.scss';
import { getStartOfCurrentMonth } from '../../../../helpers/AgGridDateHelpers';
import CarryOverTasksGrid from '../../../tasks/CarryOverTasksGrid/CarryOverTasksGrid';
import { getPositionWithName } from '../../../../helpers/TeamMembershipHelpers';

type MonthlyScoresProps = {
  selectedTeam: TeamType;
  selectedTeamMember: TeamMemberType;
};

const MonthlyScores = (props: MonthlyScoresProps) => {
  const [selectedMonth, setSelectedMonth] = useState(
    getStartOfCurrentMonth(props.selectedTeam.timezone).toFormat('yyyy-MM'),
  );

  return (
    <SelectedMonthContext.Provider value={{ selectedMonth, setSelectedMonth }}>
      <Container fluid>
        <Row>
          <Col xs={2} />
          <Col xs={8}>
            <div className={styles.dateHeader}>
              Scores for {getPositionWithName(props.selectedTeamMember)} for <MonthSelect />
            </div>
          </Col>
          <Col xs={2} />
        </Row>
        <Row>
          <Col xs={3} />
          <Col xs={6}>
            <MemberScoreSummaryPieChart height={300} selectedMonth={selectedMonth} selectedTeam={props.selectedTeam} />
          </Col>
          <Col xs={3} />
        </Row>
        <Row>
          <Col>
            <CompletedTasksGrid
              selectedTeam={props.selectedTeam}
              selectedTeamMember={props.selectedTeamMember}
              selectedMonth={selectedMonth}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CarryOverTasksGrid
              selectedTeam={props.selectedTeam}
              selectedTeamMember={props.selectedTeamMember}
              selectedMonth={selectedMonth}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CompletedGoalsGrid
              selectedTeam={props.selectedTeam}
              selectedTeamMember={props.selectedTeamMember}
              selectedMonth={selectedMonth}
            />
          </Col>
        </Row>
      </Container>
    </SelectedMonthContext.Provider>
  );
};

export default MonthlyScores;
