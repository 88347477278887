import React from 'react';
import ReactDOM from 'react-dom';

// CSS Imports
import './index.css';
// Do I need this? If so, should it be in a script tag?
// import 'react-bootstrap@next/dist/react-bootstrap.min.js'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-datepicker/dist/react-datepicker.css';
// TODO move imports/ change to sass so we can customize?
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

// Enterprise version, make sure you have key
import 'ag-grid-enterprise';
import App, { AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_AUDIENCE_ID } from './components/App/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import CustomQueryClientProvider from './components/CustomQueryClientProvider/CustomQueryClientProvider';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { isStaging, isProduction } from './helpers/EnvironmentHelpers';

if (isStaging()) {
  Sentry.init({
    dsn: 'https://b1d6890b420e4ffa9f3d48b76de05cb0@o1356815.ingest.sentry.io/4504109855801344',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1,
  });
} else if (isProduction()) {
  Sentry.init({
    dsn: 'https://b27f886cd8384473a22b7806b10af479@o1356815.ingest.sentry.io/4504109783384064',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={AUTH0_AUDIENCE_ID}
      // All the auth0 scopes that the app needs should be listed here (currently none).
      // Scopes are all the permissions that ALL users could want (from auth0) in the application.
      // This returns with the scopes that the CURRENT user has access to.
      // We do NOT use Auth0 for our own app's Role Based Access Control, but we use a similar approach in django
      // We can use the django-admin UI to make groups and add users to them
      // If we did want to use Auth0 RBAC instead of in django, full explanation is below
      // https://auth0.com/docs/configure/apis/scopes/api-scopes#example-an-api-called-by-a-first-party-application
      scope=""
      cacheLocation="localstorage"
    >
      {/* TODO Consider moving this up a level and using Auth0ProviderWithHistory. Don't know the benefits yet.*/}
      <BrowserRouter>
        <CustomQueryClientProvider>
          <App />
        </CustomQueryClientProvider>
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
