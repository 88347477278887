import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ObjectWithId } from '../../domain/shared';
import { DeleteDialogState } from '../../helpers/GridComponentHelpers';

export interface GenericDeleteDialogProps<DataType extends ObjectWithId> {
  state: DeleteDialogState<DataType>;
  onOk: (idToDelete: number) => void;
  onCancel: () => void;
  objectTypeString: string;
}

const GenericDeleteDialog = (props: GenericDeleteDialogProps<ObjectWithId>) => {
  const { state, onOk, onCancel, objectTypeString } = props;

  const handleCancel = () => {
    onCancel();
  };

  const handleOk = () => {
    if (state.objectToDelete) {
      onOk(state.objectToDelete.id);
    } else {
      // Should never be reached
      // TODO Get rid of this silly if statement
      throw Error(objectTypeString + " is null when it shouldn't be");
    }
  };

  return (
    <Modal show={state.isOpen} onHide={handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Delete this {objectTypeString}?</Modal.Title>
      </Modal.Header>
      {/* <Modal.Body></Modal.Body> */}
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button id="genericDeleteOk" variant="primary" onClick={handleOk} disabled={state.isSubmitting}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GenericDeleteDialog;
