import axios, { AxiosRequestConfig } from 'axios';
import { Form, Formik } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import { useQuery } from 'react-query';
import * as Yup from 'yup';
import { GET_TAGS_URL, TEAM_MEMBERS_URL, RECURRING_TASK_SCHEDULES_URL } from '../../../../constants/urls';
import RecurringTaskScheduleType, { RecurringTaskScheduleTypeToSend } from '../../../../domain/recurringTaskSchedule';
import TagType from '../../../../domain/tag';
import TeamType from '../../../../domain/team';
import TeamMemberType from '../../../../domain/teamMember';
import FormikController from '../../../../formik/FormikSelect/FormikController';
import { getNameOfCreationUser } from '../../../../helpers/AuditFieldHelper';
import { Mode } from '../../../../state_types/mode';
import { createFeedbackMessageKey, FeedbackMessage } from '../../../FeedbackMessages/FeedbackMessages';
import React from 'react';
import { getSimpleModal, ModifyDialogState } from '../../../../helpers/GridComponentHelpers';
import { getFormikReadOnlyField } from '../../../../formik/FormikHelpers';
import { getCallSignWithPositionAndName } from '../../../../helpers/TeamMembershipHelpers';

type ModifyRecurringTaskScheduleDialogState = {
  isOpen: boolean;
  scheduleToEdit: RecurringTaskScheduleType | null;
};

export interface ModifyRecurringTaskScheduleDialogProps {
  selectedTeam: TeamType;
  state: ModifyDialogState<RecurringTaskScheduleType>;
  onOk: (updatedScheduleFields: RecurringTaskScheduleTypeToSend, idToUpdate: number | null) => Promise<void>;
  onCancel: () => void;
  onDeleteIcon: (scheduleToDelete: RecurringTaskScheduleType) => void;
}

const getMode = (state: ModifyDialogState<RecurringTaskScheduleType>) => {
  if (state.objectToModify) {
    return Mode.Change;
  }
  return Mode.Add;
};

const getModalTitle = (mode: Mode) => {
  let titleAction;
  if (mode === Mode.Add) {
    titleAction = 'Add';
  } else {
    titleAction = 'Change';
  }
  return titleAction + ' Recurring Task Schedule';
};

const FREQUENCY_OPTIONS = [
  {
    label: 'Yearly',
    value: 'yearly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Quarterly',
    value: 'quarterly',
  },
];

const DAYS_TO_COMPLETE = 30;

const ModifyRecurringTaskScheduleDialog = (props: ModifyRecurringTaskScheduleDialogProps) => {
  const { state, onOk, onCancel, onDeleteIcon } = props;

  const handleCancel = () => {
    onCancel();
  };
  // If task to edit is set, assume edit.
  // Otherwise, assume create
  const mode = getMode(state);
  // const task = state.taskToEdit;

  const teamMembersRequestConfig = {
    params: {
      team: props.selectedTeam.id,
    },
  } as AxiosRequestConfig;
  const tagsRequestConfig = {
    // No parameters
  } as AxiosRequestConfig;
  const {
    isLoading,
    error,
    data: optionsData,
    refetch: refetchOptions,
  } = useQuery(['allOptionsQuery', teamMembersRequestConfig], () => {
    // https://www.storyblok.com/tp/how-to-send-multiple-requests-using-axios
    // I wish I could put TeamMemberType[] and TagType[] on the requests
    //  However, axios.all() typings assumes all response types match first request, so using 'any'
    const teamMembersRequest = axios.get<any>(TEAM_MEMBERS_URL, teamMembersRequestConfig);
    const tagsRequest = axios.get<any>(GET_TAGS_URL, tagsRequestConfig);
    return axios.all([teamMembersRequest, tagsRequest]).then(
      axios.spread((...responses) => {
        const allOptionsResults = {
          teamMembersResponse: responses[0],
          tagsResponse: responses[1],
        };
        return allOptionsResults;
      }),
    );
  });
  if (isLoading) return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'Loading...');
  if (error) return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'Error!');
  if (optionsData === undefined) return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'No Data!');

  const teamMembers = optionsData.teamMembersResponse.data as TeamMemberType[];

  const teamMemberOptions = teamMembers.map((teamMember) => {
    const optionLabel = getCallSignWithPositionAndName(teamMember);
    return {
      label: optionLabel,
      value: teamMember.id,
    };
  });

  const tags = optionsData.tagsResponse.data as TagType[];

  const tagOptions = tags.map((tag) => {
    return {
      label: tag.name,
      // Value is the object itself. This makes it easy for Formik to pass to backend
      // TODO change to id?
      value: tag,
    };
  });

  // TODO should this be in some sort of useState hook?
  let initialStateOfEditableScheduleFields;
  let onSubmit;
  let deleteButton: JSX.Element | null;
  let createdByText: JSX.Element | null;
  if (mode === Mode.Change) {
    // Tell typescript that taskToEdit is definitely not null
    // TODO this is hacky, find a way to fix it
    const schedule = state.objectToModify!;

    // Copy only editable fields
    initialStateOfEditableScheduleFields = {
      // Not editable
      // id: task.id,
      frequency: schedule.frequency,
      startDateTime: schedule.startDateTime,
      // Not editable
      // creationUser: task.creationUser,
      // Not editable in the UI, but needed on create, so included
      // TODO now create is separated, is above comment still valid???
      team: schedule.team.id,

      templateTaskToCreate: {
        summaryTemplate: schedule.templateTaskToCreate.summaryTemplate,
        descriptionTemplate: schedule.templateTaskToCreate.descriptionTemplate,
        linkUrlTemplate: schedule.templateTaskToCreate.linkUrlTemplate,
        // Can be undefined, for example when a new FCP is cascaded
        assignedToMember: schedule.templateTaskToCreate.assignedToMember?.id,
        totalPoints: schedule.templateTaskToCreate.totalPoints,
        tags: schedule.templateTaskToCreate.tags,
        // Not editable in the UI, but needed on create, so included
        // TODO now create is separated, is above comment still valid???
        team: schedule.templateTaskToCreate.team.id,
      },
    } as RecurringTaskScheduleTypeToSend;
    const createdByName = getNameOfCreationUser(schedule);
    createdByText = getFormikReadOnlyField('Created by:', createdByName);

    deleteButton = (
      <Button
        id="deleteRecurringTask"
        className="bi-trash-fill mr-auto"
        variant="danger"
        onClick={() => {
          onDeleteIcon(schedule);
        }}
      >
        Delete
      </Button>
    );

    onSubmit = (updatedSchedule: RecurringTaskScheduleTypeToSend | null, { setSubmitting }: any) => {
      console.log('update schedule dialog ok hit', updatedSchedule);
      setTimeout(() => {
        if (updatedSchedule === null) {
          // If this ever happens, we probably need to use 'useEffect' in some capacity
          alert('updatedSchedule was null, contact developers');
          setSubmitting(false);
          return;
        }

        // update hardcoded values on the UI.
        // TODO is this the best place to put this?
        // I bet it will get lost...
        // Should it be in the backend?
        updatedSchedule.templateTaskToCreate.daysToComplete = DAYS_TO_COMPLETE;

        // TODO make this typescript cast have a validation or something
        onOk(updatedSchedule, schedule.id).then(() => {
          // In case new tags were created
          refetchOptions();
          setSubmitting(false);
        });
      }, 400);
    };
  } else {
    // Create
    initialStateOfEditableScheduleFields = {
      frequency: '',
      startDateTime: '',
      team: props.selectedTeam.id,

      templateTaskToCreate: {
        summaryTemplate: '',
        descriptionTemplate: '',
        linkUrlTemplate: '',
        assignedToMember: undefined,
        totalPoints: undefined,
        tags: [],
        team: props.selectedTeam.id,
      },
    } as RecurringTaskScheduleTypeToSend;
    onSubmit = (newSchedule: RecurringTaskScheduleTypeToSend, { setSubmitting }: any) => {
      setTimeout(() => {
        if (newSchedule === null) {
          // If this ever happens, we probably need to use 'useEffect' in some capacity
          alert('new schedule was null, contact developers');
          setSubmitting(false);
          return;
        }

        // initialize hardcoded values on the UI.
        // TODO is this the best place to put this?
        // I bet it will get lost...
        // Should it be in the backend?
        newSchedule.templateTaskToCreate.daysToComplete = DAYS_TO_COMPLETE;

        // TODO make this typescript cast have a validation or something
        onOk(newSchedule, null).then(() => {
          // In case new tags were created
          refetchOptions();
          setSubmitting(false);
        });
      }, 400);
    };
    deleteButton = null;
    createdByText = null;
  }

  const RecurringTaskScheduleFormSchema = Yup.object().shape({
    frequency: Yup.string().nullable().required('Required'),
    startDateTime: Yup.date().required('Required'),

    templateTaskToCreate: Yup.object({
      summaryTemplate: Yup.string().required('Required'),
      descriptionTemplate: Yup.string().optional(),
      linkUrlTemplate: Yup.string().optional(),
      assignedToMember: Yup.number().nullable(),
      totalPoints: Yup.number().required('Required'), // TODO make integer only
      // TODO tags?
    }),
  });

  return (
    // This component causes 'findDOMNode is deprecated in StrictMode' warning
    // Unfortunately, this workaround on SO didn't work https://stackoverflow.com/a/64325602
    // The best way to fix this is to upgrade to react-bootstrap 2.x/bootstrap 5, but that takes some work
    // TODO fix this warning
    <Modal show={state.isOpen} onHide={handleCancel} id={'modifyRecurringTaskScheduleModal'}>
      {
        <Formik
          initialValues={initialStateOfEditableScheduleFields}
          validationSchema={RecurringTaskScheduleFormSchema}
          onSubmit={onSubmit}
        >
          {(formikProps: any) => (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>{getModalTitle(mode)}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* Only show createdByText on editing */}
                {createdByText}
                <FormikController
                  testKey="frequency"
                  control="select"
                  name="frequency"
                  label="Frequency"
                  options={FREQUENCY_OPTIONS}
                />
                <FormikController
                  control="date"
                  name="startDateTime"
                  label="Start Date (Recurs on this day)"
                  timezone={props.selectedTeam.timezone}
                />
                <FormikController control="input" name="templateTaskToCreate.summaryTemplate" label="Summary" />
                <FormikController
                  control="input"
                  name="templateTaskToCreate.descriptionTemplate"
                  label="Description (optional)"
                />
                <FormikController control="input" name="templateTaskToCreate.linkUrlTemplate" label="Link (optional)" />
                <FormikController
                  control="select"
                  name="templateTaskToCreate.assignedToMember"
                  label="Assigned To"
                  options={teamMemberOptions}
                />
                <FormikController control="input" name="templateTaskToCreate.totalPoints" label="Weight" />
                {/* 'startingOptions' because we are allowing the creation of new tags */}
                <FormikController
                  control="tags"
                  name="templateTaskToCreate.tags"
                  label="Tags (optional)"
                  startingOptions={tagOptions}
                />
              </Modal.Body>
              <Modal.Footer>
                {/* Only show delete button on editing */}
                {deleteButton}
                <Button variant="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button id="recurringTaskSubmit" variant="primary" type="submit" disabled={formikProps.isSubmitting}>
                  Ok
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      }
    </Modal>
  );
};

function defaultHandleModifyRecurringTaskScheduleDialogOk(
  modifiedSchedule: RecurringTaskScheduleTypeToSend,
  idToUpdate: number | null,
  setModifyRecurringTaskScheduleDialogState: (state: any) => void,
  refetchSchedules: () => void,
  addFeedbackMessage: (feedbackMessage: FeedbackMessage) => void,
) {
  let promise;
  if (idToUpdate !== null) {
    console.log('Updating schedule ' + idToUpdate, modifiedSchedule);
    // POST is for new stuff, PUT is for replacing task (must have ALL fields)
    // This uses PATCH, which loads old task and only updates fields you passed
    // https://stackoverflow.com/a/24241955/13815107
    promise = axios.patch(RECURRING_TASK_SCHEDULES_URL + '/' + idToUpdate + '/', modifiedSchedule).then((response) => {
      setModifyRecurringTaskScheduleDialogState({
        isOpen: false,
        scheduleToEdit: null,
      });
      addFeedbackMessage({
        key: createFeedbackMessageKey('recurringTaskSchedule', 'change', idToUpdate),
        status: 'success',
        messageBody: <span>Recurring task schedule updated successfully.</span>,
      });
      refetchSchedules();
    });
  } else {
    console.log('Creating schedule', modifiedSchedule);
    if (modifiedSchedule.id) {
      console.error('Creating a schedule but sending an id. Was this meant to be a modify?');
      promise = Promise.reject('Creating a schedule but sending an id. Was this meant to be a modify?');
    } else {
      promise = axios.post(RECURRING_TASK_SCHEDULES_URL + '/', modifiedSchedule).then((response) => {
        setModifyRecurringTaskScheduleDialogState({
          isOpen: false,
          scheduleToEdit: null,
        });
        addFeedbackMessage({
          key: createFeedbackMessageKey('recurringTaskSchedule', 'create'),
          status: 'success',
          messageBody: <span>Recurring task schedule created successfully.</span>,
        });
        refetchSchedules();
      });
    }
  }
  return promise;
}

export default ModifyRecurringTaskScheduleDialog;
export { defaultHandleModifyRecurringTaskScheduleDialogOk };
export type { ModifyRecurringTaskScheduleDialogState };
