import AccountType from './account';
import EventTypeType from './eventType';
import { ObjectWithActions, ObjectWithId } from './shared';
import TeamType from './team';
import TemplateTaskType, { TemplateTaskTypeToSend } from './templateTask';

type TriggerRuleType = ObjectWithId &
  ObjectWithActions & {
    id: number;
    creationUser: AccountType;
    createOnEventType: EventTypeType;
    templateTaskToCreate: TemplateTaskType;
    team: TeamType;

    actions: string[];
  };

type TriggerRuleTypeToSend = {
  id?: number;
  creationUser?: AccountType;
  createOnEventType?: number;
  templateTaskToCreate: TemplateTaskTypeToSend;
  team?: number;

  actions?: string[];
};

function convertToTypeToSend(triggerRule: TriggerRuleType) {
  // Convert objects into ids while preserving the other fields
  const triggerRuleToSend = {
    ...triggerRule,
    createOnEventType: triggerRule.createOnEventType.id,
    team: triggerRule.team.id,
    templateTaskToCreate: {
      ...triggerRule.templateTaskToCreate,
      team: triggerRule.templateTaskToCreate.team.id,
      // Sometimes undefined/null, especially after installing a new FCP
      assignedToMember: triggerRule.templateTaskToCreate.assignedToMember?.id,
    },
  } as TriggerRuleTypeToSend;
  return triggerRuleToSend;
}

export type { TriggerRuleTypeToSend };
export default TriggerRuleType;
export { convertToTypeToSend };
