import { Col, Container, Row } from 'react-bootstrap';

import React, { useState } from 'react';

import LoaderWheel from '../../../components/LoaderWheel/LoaderWheel';
import SubstepRecordGrid from '../../../components/fcp/grids/SubstepRecordGrid/SubstepRecordGrid';

import '../../../styles.css';
import { useGetSubstepsByDepartment } from '../../../components/fcp/apis/substep_api';
import { useGetTeamsByDepartment } from '../../../components/fcp/apis/teams_api';
import { useGetLeadPeople } from '../../../components/fcp/apis/accounts_api';

type SubstepRecordPageProps = {
  type: string; //"AT" or "FH"
};

const SubstepRecordPage = (props: SubstepRecordPageProps) => {
  const [requestError] = useState<string>('');

  let department;
  if (props.type === 'AT') {
    department = 'Anti-Trafficking';
  } else {
    department = 'Family Homes';
  }

  const { error: leadPeopleError, data: leadPeople, isLoading: isLeadPeopleLoading } = useGetLeadPeople();

  const {
    error: substepError,
    data: fcpSubsteps,
    isLoading: isSubstepsLoading,
    isFetching: isSubstepsFetching,
    refetch: refetchSubsteps,
  } = useGetSubstepsByDepartment(department);

  const { error: teamsError, data: teams, isLoading: isTeamsLoading } = useGetTeamsByDepartment(department);

  const canEditSubs = !isSubstepsFetching && !isTeamsLoading && !isLeadPeopleLoading;

  if (requestError || teamsError || leadPeopleError) {
    return <p>Error!</p>;
  } else if (isSubstepsLoading || isTeamsLoading || isLeadPeopleLoading) {
    // If substeps are just re-fetching, you won't display the wheel
    return <LoaderWheel />;
  } else if (substepError) {
    return <p>Error!</p>;
  } else if (!fcpSubsteps || !teams || !leadPeople) {
    return <p>No Data!</p>;
  }

  return (
    <Container fluid className="d-flex flex-column flex-grow-1 page-container">
      <Row className="d-flex align-items-center justify-content-start">
        <Col className="d-flex flex-column flex-grow-1 align-items-end justify-content-end">
          {!canEditSubs && (
            <Col className="d-flex flex-column flex-grow-1 align-items-end justify-content-end">
              Reloading Substeps...
            </Col>
          )}
        </Col>
      </Row>
      <SubstepRecordGrid
        department={department}
        departmentFcpSubsteps={fcpSubsteps}
        leadPeople={leadPeople}
        departmentTeams={teams}
        headerHeight={25}
        refetchSubsteps={refetchSubsteps}
        canEditSubsteps={canEditSubs}
      />
    </Container>
  );
};

export default SubstepRecordPage;
