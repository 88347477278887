// import Box from '@material-ui/core/Box';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import RecurringTaskSchedulesGrid from '../../components/tasks/recurring/RecurringTaskSchedulesGrid/RecurringTaskSchedulesGrid';
import TeamType from '../../domain/team';
import { Link, useSearchParams } from 'react-router-dom';
import React from 'react';
import RecurringGoalScheduleGrid from '../../components/goals/recurring/RecurringGoalScheduleGrid/RecurringGoalScheduleGrid';

type RecurringTasksProps = {
  selectedTeam: TeamType;
};

const AllAssignments = (props: RecurringTasksProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const newTeamMemberId = searchParams.get('newTeamMember');
  const assignmentTypeAndIdToFocusOn = searchParams.get('assignmentToFocusOn');

  let recurringTaskScheduleIdToFocusOn = undefined;
  let recurringGoalScheduleIdToFocusOn = undefined;
  if (assignmentTypeAndIdToFocusOn && assignmentTypeAndIdToFocusOn.split('-').length === 2) {
    const type = assignmentTypeAndIdToFocusOn.split('-')[0];
    const id = parseInt(assignmentTypeAndIdToFocusOn.split('-')[1]);
    if (id) {
      if (type === 'recurringTask') {
        recurringTaskScheduleIdToFocusOn = id;
        console.debug('Trying to focus on recurring task ' + id);
      } else if (type === 'recurringGoal') {
        recurringGoalScheduleIdToFocusOn = id;
        console.debug('Trying to focus on recurring goal ' + id);
      } else {
        console.error('Unknown assignment type to focus on ' + type);
      }
    } else {
      console.error(
        'Unable to focus on ' + type + ' ' + assignmentTypeAndIdToFocusOn.split('-')[1] + ', id is not a number',
      );
    }
  }

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row>
        <Col>
          <h4 className="text-center font-weight-bold">All Assignments</h4>
        </Col>
      </Row>
      {newTeamMemberId && (
        <Alert variant="info">
          Almost done! Assign the new team member to recurring tasks, event triggered tasks, or goals.
          <br />
          Hit next (at the very bottom) to show the new team member's dashboard.
        </Alert>
      )}
      <div>
        <h3 style={{ textAlign: 'center' }}>Recurring Tasks</h3>
        <div style={{ height: 400 }}>
          <RecurringTaskSchedulesGrid
            selectedTeam={props.selectedTeam}
            memberQuickSelect={true}
            idToFocusOn={recurringTaskScheduleIdToFocusOn}
          />
        </div>
        <h3 style={{ textAlign: 'center' }}>Goals</h3>
        <div style={{ height: 400 }}>
          <RecurringGoalScheduleGrid
            selectedTeam={props.selectedTeam}
            memberQuickSelect={true}
            idToFocusOn={recurringGoalScheduleIdToFocusOn}
          />
        </div>
        {/*<h3 style={{ textAlign: 'center' }}>Event Triggered Tasks</h3>*/}
        {/*<div style={{ height: 400 }}>*/}
        {/*<TriggerRuleGrid*/}
        {/*  selectedTeam={props.selectedTeam}*/}
        {/*  memberQuickSelect={true}*/}
        {/*  idToFocusOn={triggerRuleIdToFocusOn}*/}
        {/*/>*/}
        {/*</div>*/}
      </div>
      <Row>
        {newTeamMemberId && (
          <Link to={'/?teamMember=' + newTeamMemberId}>
            <Button>Next</Button>
          </Link>
        )}
      </Row>
    </Container>
  );
};

export default AllAssignments;
