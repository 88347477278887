import React from 'react';

// Taken from https://codesandbox.io/s/yvvwwvl4xv?file=/helper.js:0-367
// For use in debugging only
export const DisplayFormikState = (props: { formikProps: any }) => (
  <div style={{ margin: '1rem 0' }}>
    <pre
      style={{
        background: '#f6f8fa',
        fontSize: '.65rem',
        padding: '.5rem',
      }}
    >
      <strong>props</strong> = {JSON.stringify(props, null, 2)}
    </pre>
  </div>
);
