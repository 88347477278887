import * as React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

type HeaderWithButtonProps = {
  title: string;
  buttonText: string;
  onClick: Function;
};
export const HeaderWithButton = (props: HeaderWithButtonProps) => {
  return (
    <Container className={'p-0'}>
      <Row className="m-0">
        <Col sm={8} className={'p-0 d-flex flex-column text-wrap '}>
          {props.title}
        </Col>
        <Col sm={4} className="p-0">
          <Button size="sm" variant={'outline-primary'} onClick={() => props.onClick()}>
            {props.buttonText}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default HeaderWithButton;
export type { HeaderWithButtonProps };
