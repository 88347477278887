import React from 'react';
import { AgChartsReact } from 'ag-charts-react';
import { AgChartOptions } from 'ag-grid-community';
import { CoreRecurringGoalScheduleTypeToSend } from '../../../../domain/coreRecurringGoalSchedule';
import { RecurringGoalScheduleTypeToSend } from '../../../../domain/recurringGoalSchedule';
import {
  LINEAR_FROM_CUTOFF,
  PERCENT_UOM,
} from '../../../fcp/goals/recurring/ModifyCoreRecurringGoalScheduleDialog/ModifyCoreRecurringGoalScheduleDialog';

type GoalScoringVisualLineGraphProps = {
  goalScheduleToGraph: CoreRecurringGoalScheduleTypeToSend | RecurringGoalScheduleTypeToSend;
  betterDirection: string | undefined;
  unitOfMeasure: string | undefined;
};

const getOptions = (
  goalScheduleToGraph: CoreRecurringGoalScheduleTypeToSend | RecurringGoalScheduleTypeToSend,
  betterDirection: string | undefined,
  unitOfMeasure: string | undefined,
): AgChartOptions => {
  const isLowerBetter = betterDirection?.toLowerCase() === 'lower';
  const goalValue = Number(goalScheduleToGraph.goalValue);
  const cutoffValue = Number(goalScheduleToGraph.cutoffValue);
  const isLinear = goalScheduleToGraph.scoringType === LINEAR_FROM_CUTOFF;
  const totalPoints = Number(goalScheduleToGraph.totalPoints);
  const isUomPercent = unitOfMeasure === PERCENT_UOM;

  let greenGraphData: any[];
  let yellowGraphData: any[];
  let redGraphData: any[];

  if (!goalValue || !totalPoints) {
    greenGraphData = [];
    yellowGraphData = [];
    redGraphData = [];
  } else {
    const startOfGraphPoint = {
      x: getStartOfGraphPointX(),
      y: getStartOfGraphPoints(isLowerBetter, totalPoints),
      // This is a hack that works around an AG grid bug of line graph points not moving when the graph re-scales
      // It forces the data to be different in a non-visual way which causes AG grid to re-render correctly
      endPointOfGraph: getEndOfGraphPointX(isLowerBetter, goalValue, isLinear, cutoffValue, isUomPercent),
    };
    const firstPoint = {
      x: getFirstPointX(isLowerBetter, goalValue, isLinear, cutoffValue),
      y: getStartOfGraphPoints(isLowerBetter, totalPoints),
      // This is a hack that works around an AG grid bug of line graph points not moving when the graph re-scales
      // It forces the data to be different in a non-visual way which causes AG grid to re-render correctly
      endPointOfGraph: getEndOfGraphPointX(isLowerBetter, goalValue, isLinear, cutoffValue, isUomPercent),
    };
    const secondPoint = {
      x: getSecondPointX(isLowerBetter, goalValue, isLinear, cutoffValue),
      y: getEndOfGraphPoints(isLowerBetter, totalPoints),
      // This is a hack that works around an AG grid bug of line graph points not moving when the graph re-scales
      // It forces the data to be different in a non-visual way which causes AG grid to re-render correctly
      endPointOfGraph: getEndOfGraphPointX(isLowerBetter, goalValue, isLinear, cutoffValue, isUomPercent),
    };
    const endOfGraphPoint = {
      x: getEndOfGraphPointX(isLowerBetter, goalValue, isLinear, cutoffValue, isUomPercent),
      y: getEndOfGraphPoints(isLowerBetter, totalPoints),
      // This is a hack that works around an AG grid bug of line graph points not moving when the graph re-scales
      // It forces the data to be different in a non-visual way which causes AG grid to re-render correctly
      endPointOfGraph: getEndOfGraphPointX(isLowerBetter, goalValue, isLinear, cutoffValue, isUomPercent),
    };
    if (isLowerBetter) {
      greenGraphData = [startOfGraphPoint, firstPoint];
      redGraphData = [secondPoint, endOfGraphPoint];
    } else {
      redGraphData = [startOfGraphPoint, firstPoint];
      greenGraphData = [secondPoint, endOfGraphPoint];
    }
    if (isLinear) {
      yellowGraphData = [firstPoint, secondPoint];
    } else {
      yellowGraphData = [];
    }
  }
  const tooltipRenderer = (params: any) => {
    let notExactlyText = '';
    if (!isLinear && isLowerBetter && params.yValue === 0) {
      notExactlyText = ' higher than ';
    }
    if (!isLinear && !isLowerBetter && params.yValue === 0) {
      notExactlyText = ' lower than ';
    }
    if (notExactlyText) {
      return {
        content: 'If result is' + notExactlyText + params.xValue + ', then award ' + params.yValue + ' points.',
        // title: params.xValue, // optional, same as default
      };
    } else {
      return {
        content: 'If result is ' + params.xValue + ', then award ' + params.yValue + ' points.',
        // title: params.xValue, // optional, same as default
      };
    }
  };
  return {
    autoSize: true,
    series: [
      {
        data: greenGraphData,
        stroke: 'green',
        marker: {
          fill: 'green',
        },
        xKey: 'x',
        yKey: 'y',
        tooltip: {
          renderer: tooltipRenderer,
        },
      },
      {
        data: yellowGraphData,
        stroke: 'yellow',
        marker: {
          fill: 'yellow',
        },
        xKey: 'x',
        yKey: 'y',
        tooltip: {
          renderer: tooltipRenderer,
        },
      },
      {
        data: redGraphData,
        stroke: 'red',
        marker: {
          fill: 'red',
        },
        xKey: 'x',
        yKey: 'y',
        tooltip: {
          renderer: tooltipRenderer,
        },
      },
    ],
    axes: [
      {
        type: 'number',
        position: 'left',
      },
      {
        type: 'number',
        position: 'bottom',
      },
    ],
    padding: {
      top: 3,
      bottom: 3,
      left: 3,
      right: 3,
    },
    legend: {
      enabled: false,
    },
  };
};

const GoalScoringVisualLineGraph = (props: GoalScoringVisualLineGraphProps) => {
  const [chartOptions, setChartOptions] = React.useState<null | AgChartOptions>(
    getOptions(props.goalScheduleToGraph, props.betterDirection, props.unitOfMeasure),
  );

  React.useEffect(() => {
    setChartOptions(getOptions(props.goalScheduleToGraph, props.betterDirection, props.unitOfMeasure));
  }, [props.goalScheduleToGraph, props.betterDirection, props.unitOfMeasure]);

  return <AgChartsReact options={chartOptions} />;
};

function getStartOfGraphPointX() {
  return 0;
}

function getStartOfGraphPoints(isLowerBetter: boolean, totalPoints: number) {
  if (isLowerBetter) {
    return totalPoints;
  } else {
    return 0;
  }
}

function getFirstPointX(
  isLowerBetter: boolean,
  goalValue: number,
  isLinear: boolean,
  cutoffValue: number | null | undefined,
) {
  if (isLowerBetter) {
    return goalValue;
  } else {
    if (isLinear && cutoffValue) {
      return cutoffValue;
    }
    return goalValue;
  }
}

function getSecondPointX(
  isLowerBetter: boolean,
  goalValue: number,
  isLinear: boolean,
  cutoffValue: number | null | undefined,
) {
  if (isLinear && cutoffValue) {
    return cutoffValue;
  } else {
    return goalValue;
  }
}

function getEndOfGraphPoints(isLowerBetter: boolean, totalPoints: number) {
  if (isLowerBetter) {
    return 0;
  } else {
    return totalPoints;
  }
}

function getEndOfGraphPointX(
  isLowerBetter: boolean,
  goalValue: number,
  isLinear: boolean,
  cutoffValue: number | null | undefined,
  isUomPercent: boolean,
) {
  if (isUomPercent) {
    return 100;
  } else if (isLinear && cutoffValue) {
    return cutoffValue * 1.5;
  } else {
    return goalValue * 1.5;
  }
}

export default GoalScoringVisualLineGraph;
