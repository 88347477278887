import React, { useState } from 'react';
import { ColDef } from 'ag-grid-community';
import { useGetSubstepInstallationStats } from '../../../components/fcp/apis/substep_installation_api';
import LoaderWheel from '../../../components/LoaderWheel/LoaderWheel';
import { Col, Container, Row } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import { getDefaultColDef } from '../../../helpers/GridComponentHelpers';
import { DateTime } from 'luxon';
import { getAssigneeDropdownBgColor, getAssigneeInitials } from '../../../helpers/FieldCoreProcessHelpers';

type SubstepInstallationStatsPageProps = {};

const SubstepInstallationStatsPage = (props: SubstepInstallationStatsPageProps) => {
  const [columnDefs] = useState([
    { field: 'fullName', headerName: 'Field Officer', width: 250 },
    {
      valueGetter: (params) => {
        return getAssigneeInitials(params.data);
      },
      cellStyle: (params) => {
        return {
          backgroundColor: getAssigneeDropdownBgColor(params.data.fullName),
          color: 'white',
        };
      },
      headerName: 'Initials',
      width: 100,
    },
    { field: 'numAllTimeAssignedSubsteps', headerName: 'All Time Assigned', width: 150 },
    { field: 'numAllTimeCompletedSubsteps', headerName: 'All Time Complete', width: 150 },
    { field: 'numCompletedSubstepsInTimeframe', headerName: 'This Month Complete', width: 150 },
  ] as ColDef[]);

  const currentMonthStart = DateTime.now().startOf('month').toISODate();
  const currentMonthEnd = DateTime.now().endOf('month').toISODate();

  const { error, data: stats, isFetching } = useGetSubstepInstallationStats(currentMonthStart, currentMonthEnd);

  if (error) {
    return <p>Error!</p>;
  } else if (isFetching) {
    return <LoaderWheel />;
  } else {
    return (
      <Container fluid className="d-flex flex-column flex-grow-1 page-container">
        <Row className="d-flex align-items-center justify-content-start">
          <Col className="d-flex flex-column flex-grow-1">
            <h5 className="font-weight-bold">Installation Record</h5>
          </Col>
        </Row>
        <Row className="d-flex flex-column flex-grow-1 pl-3">
          <Col>
            <div className="ag-theme-balham grid-wrapper">
              <AgGridReact
                debounceVerticalScrollbar={true}
                rowData={stats}
                columnDefs={columnDefs}
                suppressMenuHide={true}
                defaultColDef={getDefaultColDef()}
                suppressRowVirtualisation={true}
                suppressColumnVirtualisation={true}
              />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
};

export default SubstepInstallationStatsPage;
