import { createContext } from 'react';
import TeamMemberType from '../domain/teamMember';

export type SelectedTeamMemberContextType = {
  selectedTeamMember: TeamMemberType | null;
  setSelectedTeamMember: Function;
};

const dummyTeamMemberContext = {
  selectedTeamMember: null,
  setSelectedTeamMember: (teammember: any) => {},
} as SelectedTeamMemberContextType;

export const SelectedTeamMemberContext = createContext(dummyTeamMemberContext);
