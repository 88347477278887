import { createContext } from 'react';
import TeamType from '../domain/team';

type SelectedTeamContextType = {
  selectedTeam: TeamType | null;
  setSelectedTeam: Function;
};

const dummyTeamContext = {
  selectedTeam: null,
  setSelectedTeam: (team: any) => {},
} as SelectedTeamContextType;

const SelectedTeamContext = createContext(dummyTeamContext);

export default SelectedTeamContext;
