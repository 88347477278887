// Based on https://www.antstack.io/blog/reusable-form-components-using-react-formik-yup/
// This uses Formik "Field children" pattern from https://formik.org/docs/api/field
import React, { ChangeEvent } from 'react';
import { Field, ErrorMessage, FieldProps, useFormikContext } from 'formik';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { DateTime } from 'luxon';
import CustomErrorMessage from './CustomErrorMessage';

// TODO not sure what props from formik controller are yet.
function FormikDate(props: any) {
  const { name, label, timezone, ...rest } = props;

  const { setFieldValue } = useFormikContext();

  // TODO this should probably be a prop
  // Should I do last milli of day or first?
  // 5/6/2022 - using first because recurring goal needs to allow for goal to start on same day
  const FIRST_MILLI_OF_DAY = '00:00:00.000';
  // const LAST_MILLI_OF_DAY = '23:59:59.999';

  const getDatePortionOfDatetime = (fieldProps: FieldProps) => {
    // react-bootstrap FormControl value should not be null
    if (!fieldProps.field.value) {
      return '';
    }
    // Timezones can make the date wrap to a different day, so do this to be safe.
    return DateTime.fromISO(fieldProps.field.value, { zone: timezone }).toISODate();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const newValueDatePortionOnly = event.target.value;
    if (!newValueDatePortionOnly) {
      setFieldValue(fieldName, '');
    } else {
      // Convert back to UTC at the end to send to backend (I like UTC over the wire)
      const newDateUtc = DateTime.fromISO(newValueDatePortionOnly + 'T' + FIRST_MILLI_OF_DAY, {
        zone: timezone,
      }).toUTC();
      setFieldValue(fieldName, newDateUtc);
    }
  };

  return (
    <div>
      <Field name={name} {...rest}>
        {(fieldProps: any) => {
          return (
            // These are react-bootstrap components to get look-and-feel right
            <FormGroup controlId={name}>
              <FormLabel>{label}</FormLabel>
              <FormControl
                type={'date'}
                value={getDatePortionOfDatetime(fieldProps)}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleChange(event, fieldProps.field.name);
                }}
              />
              <ErrorMessage name={name}>
                {(errorMessage) => {
                  return <CustomErrorMessage errorMessage={errorMessage} />;
                }}
              </ErrorMessage>
            </FormGroup>
          );
        }}
      </Field>
    </div>
  );
}
export default FormikDate;
