import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ObjectWithId } from '../../domain/shared';
import { RequestChangeDialogState } from '../../helpers/GridComponentHelpers';
import { Form, Formik, FormikProps } from 'formik';
import FormikController from '../../formik/FormikSelect/FormikController';
import * as Yup from 'yup';
import RequestedChangeToSend, { RequestedChangeFormikFields } from './requestedChange';
import { createFeedbackMessageKey, FeedbackMessage } from '../FeedbackMessages/FeedbackMessages';
import axios from 'axios';
import { REQUEST_CHANGE_URL } from '../../constants/urls';

export interface GenericRequestChangeDialogProps<DataType extends ObjectWithId> {
  state: RequestChangeDialogState<DataType>;
  onOk: (requestChange: RequestedChangeToSend) => Promise<void>;
  onCancel: () => void;
  objectTypeString: string;
}

const GenericRequestChangeDialog = (props: GenericRequestChangeDialogProps<ObjectWithId>) => {
  const { state, onOk, onCancel, objectTypeString } = props;

  const handleCancel = () => {
    onCancel();
  };

  const onSubmit = (requestedChangeFormik: RequestedChangeFormikFields, { setSubmitting }: any) => {
    setTimeout(() => {
      if (requestedChangeFormik === null) {
        // If this ever happens, we probably need to use 'useEffect' in some capacity
        alert('requestedChange was null, contact developers');
        setSubmitting(false);
        return;
      }

      const requestedChangeToSend = {
        objectTypeString: props.objectTypeString,
        objectId: props.state.objectToRequestChangeFor!.id,
        objectJson: props.state.objectToRequestChangeFor,
        changeDescription: requestedChangeFormik.changeDescription,
      };

      // TODO make this typescript cast have a validation or something
      onOk(requestedChangeToSend).then(() => {
        // In case new tags were created
        // refetch();
        setSubmitting(false);
      });
    }, 400);
  };

  return (
    <Modal show={state.isOpen} onHide={handleCancel}>
      <Formik
        initialValues={{ changeDescription: '' }}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          // All other fields will be added with initialState
          changeDescription: Yup.string().required('Required'),
        })}
      >
        {(formikProps: FormikProps<RequestedChangeFormikFields>) => {
          return (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>Request a change for this {objectTypeString}?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormikController control="input" name="changeDescription" label="Describe your change" />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" disabled={formikProps.isSubmitting}>
                  Ok
                </Button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

function genericHandleRequestChangeOk(
  requestedChange: RequestedChangeToSend,
  closeRequestChangeDialog: () => void,
  addFeedbackMessage: (feedbackMessage: FeedbackMessage) => void,
) {
  console.log('Sending change request for ' + requestedChange.objectTypeString, requestedChange);
  const promise = axios.post(REQUEST_CHANGE_URL + '/', requestedChange).then((response) => {
    closeRequestChangeDialog();
    addFeedbackMessage({
      key: createFeedbackMessageKey('requestChange-' + requestedChange.objectTypeString, 'create'),
      status: 'success',
      messageBody: <span>Requested change for {requestedChange.objectTypeString} successfully.</span>,
    });
    // refetchRules();
  });
  return promise;
}

export default GenericRequestChangeDialog;
export { genericHandleRequestChangeOk };
