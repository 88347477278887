import { AxiosRequestConfig } from 'axios';
import React from 'react';
import TeamMemberType from '../../../domain/teamMember';
import TeamType from '../../../domain/team';
import { getEndOfMonthFromString, isSelectedMonthTheCurrentMonth } from '../../../helpers/AgGridDateHelpers';
import { TASKS_URL } from '../../../constants/urls';
import BaseTaskGrid from '../BaseTaskGrid/BaseTaskGrid';
import { getPositionWithName } from '../../../helpers/TeamMembershipHelpers';

type CompletedTasksGridProps = {
  selectedTeam: TeamType;
  selectedTeamMember?: TeamMemberType;
  selectedMonth: string;
};

const CarryOverTasksGrid = (props: CompletedTasksGridProps) => {
  if (!props.selectedMonth) {
    throw new Error('Need to pass selectedMonth prop');
  }

  let title = 'Incomplete Tasks';
  if (props.selectedTeamMember) {
    title += ' for ' + getPositionWithName(props.selectedTeamMember);
  }
  if (props.selectedMonth) {
    title += ' during ' + props.selectedMonth;
  }

  const requestConfig = {
    params: {
      period_end: getEndOfMonthFromString(props.selectedMonth, props.selectedTeam.timezone).toISO(),
    },
  } as AxiosRequestConfig;
  if (props.selectedTeamMember) {
    requestConfig.params.assigned_to_member = props.selectedTeamMember.id;
  } else {
    requestConfig.params.team = props.selectedTeam.id;
  }

  let url = TASKS_URL + '/incomplete-for-period';

  if (
    isSelectedMonthTheCurrentMonth(props.selectedMonth, props.selectedTeam.timezone) &&
    props?.selectedTeam === null
  ) {
    return <div />;
  }

  const getAdditionalColDefs = () => {
    return [
      {
        field: 'totalPoints',
        headerName: 'Weight',
        width: 140,
      },
    ];
  };

  return (
    <BaseTaskGrid
      title={title}
      selectedTeam={props.selectedTeam}
      requestConfig={requestConfig}
      url={url}
      selectedTeamMember={props.selectedTeamMember}
      // idToFocusOn=
      hideCreateButton={true}
      height={400}
      selectedMonth={props.selectedMonth}
      getAdditionalColDefs={getAdditionalColDefs}
      showAssignedToColumn={true}
    />
  );
};

export default CarryOverTasksGrid;
