import * as React from 'react';
import TeamType from '../../../../domain/team';
import { Button, Modal } from 'react-bootstrap';
import { getSimpleModal, ModifyDialogState } from '../../../../helpers/GridComponentHelpers';
import { Form, Formik, FormikProps } from 'formik';
import FormikController from '../../../../formik/FormikSelect/FormikController';
import * as Yup from 'yup';
import { HQ_TIMEZONE } from '../../../../constants/globals';
import { DisplayFormikState } from '../../../../formik/DisplayFormikState';

type ModifyTeamDialogProps = {
  state: ModifyDialogState<TeamType>;
  // onOk: (updatedFcpFields: FieldCoreProcessTypeToSend, idToUpdate: number | null) => void;
  onOk: (updatedTeamFields: TeamType, idToUpdate?: number | null) => Promise<any>;
  onCancel: () => void;
  // onDeleteIcon: (fcpToDelete: FieldCoreProcessType) => void;

  canEditFcps: boolean;
};

const TeamFormSchema = Yup.object().shape({
  isPaused: Yup.boolean().required('Required'),
  activationDate: Yup.date().nullable().optional(),
});

export const ModifyTeamDialog = (props: ModifyTeamDialogProps) => {
  let initialStateOfEditableEventFields;
  let teamId: number | null;
  // Assumed change - no team create yet

  // Tell typescript that taskToEdit is definitely not null
  // TODO this is hacky, find a way to fix it
  const team = props.state.objectToModify!;

  if (!team) {
    return getSimpleModal(props.state.isOpen, props.onCancel, 'Modify UNKNOWN team', 'No Data');
  }

  const title = 'Modify ' + team.name;

  initialStateOfEditableEventFields = {
    name: team.name,
    isPaused: team.isPaused,
    activationDate: team.activationDate,
  } as TeamType;
  teamId = team.id;

  const onSubmit = (modifiedTeam: TeamType, { setSubmitting }: any) => {
    setTimeout(() => {
      if (modifiedTeam === null) {
        // If this ever happens, we probably need to use 'useEffect' in some capacity
        alert('new event was null, contact developers');
        setSubmitting(false);
        return;
      }
      props.onOk(modifiedTeam, teamId).then(() => {
        setSubmitting(false);
      });
    }, 400);
  };
  const isOpen = props.state.isOpen;

  // This could eventually become a whole separate component
  // Especially if we need to create teams
  // If so, we should convert this to the standard ModifyDialog pattern
  const simpleTeamModifyDialog = (
    <Modal show={isOpen} onHide={props.onCancel}>
      <Formik initialValues={initialStateOfEditableEventFields} validationSchema={TeamFormSchema} onSubmit={onSubmit}>
        {(formikProps: FormikProps<TeamType>) => {
          if (!team) {
            return <Modal.Body>No team selected. Are you tring to add a new one?</Modal.Body>;
          } else {
            return (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <FormikController control="checkbox" name="isPaused" label="Paused" />
                  <FormikController
                    control="date"
                    name="activationDate"
                    label="Greenlight Date"
                    timezone={HQ_TIMEZONE}
                  />
                  {/*<Row>*/}
                  {/*  <Col>*/}
                  {/*    <FormLabel className={'pr-2'}>Greenlight Date:</FormLabel>*/}
                  {/*    <span style={inputBackgroundColorStyle}>*/}
                  {/*      <DatePicker*/}
                  {/*        dateFormat="yyyy-MM-dd"*/}
                  {/*        selected={selectedTeamActivationJsDate}*/}
                  {/*        disabled={isTeamFormDisabled}*/}
                  {/*        isClearable*/}
                  {/*        onChange={(date: Date) => {*/}
                  {/*          let newActivationDate = null;*/}
                  {/*          if (date) {*/}
                  {/*            newActivationDate = jsToIsoDate(date, HQ_TIMEZONE);*/}
                  {/*            setTeamGreenlightDate(newActivationDate);*/}
                  {/*            console.log('set date', newActivationDate);*/}
                  {/*          } else {*/}
                  {/*            setTeamGreenlightDate(null);*/}
                  {/*            setTeamGreenlightDateInvalid(true);*/}
                  {/*            console.log('set date to null');*/}
                  {/*          }*/}
                  {/*        }}*/}
                  {/*        onBlur={() => submitGreenlightDate(selectedTeam, teamGreenlightDate)}*/}
                  {/*      />*/}
                  {/*      {teamGreenlightDateInvalid && <p>Invalid Date</p>}*/}
                  {/*    </span>*/}
                  {/*  </Col>*/}
                  {/*</Row>*/}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={props.onCancel}>
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit" disabled={!props.canEditFcps || formikProps.isSubmitting}>
                    Ok
                  </Button>
                </Modal.Footer>
                <DisplayFormikState formikProps={formikProps} />
              </Form>
            );
          }
        }}
      </Formik>
    </Modal>
  );

  return simpleTeamModifyDialog;
};

export default ModifyTeamDialog;
export type { ModifyTeamDialogProps };
