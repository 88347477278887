import { ICellRendererParams } from 'ag-grid-community';
import { ReactNode } from 'react';
import { OverlayTrigger, Popover, PopoverContent } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/Overlay';

interface DefaultTooltipProps {
  id: string;
  tooltipText: string;
  children: React.ReactElement;
  placement?: Placement;
}

function replaceNewlinesWithBreaks(valueWithNewlines: string): ReactNode {
  // https://stackoverflow.com/questions/44311109/how-do-i-generate-a-line-break-inside-a-loop-in-react
  return valueWithNewlines.split(/\r?\n/).map((lineOfValue: string, index: number) => {
    return index === 0 ? lineOfValue : [<br />, lineOfValue];
  });
}

/* DefaultTooltip component (react-bootstrap Tooltip/Popover) caused scrollbars to appear sometimes 
    overflow: hidden; on body on index.css would fix it,
    but it breaks every page that DOES have a scrollbar. 
    https://stackoverflow.com/a/19308332/13815107 */
const DefaultTooltip = (props: DefaultTooltipProps) => {
  const placement = props.placement ? props.placement : 'bottom';
  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 100, hide: 250 }}
      overlay={(overlayProps) => (
        // White popover matches our UI more than the black tooltip
        // <Tooltip id={props.id} {...overlayProps}>{replaceNewlinesWithBreaks(props.tooltipText)}</Tooltip>
        <Popover id={props.id} {...overlayProps}>
          <PopoverContent
            style={{
              fontSize: 12,
            }}
          >
            {replaceNewlinesWithBreaks(props.tooltipText)}
          </PopoverContent>
        </Popover>
      )}
    >
      {props.children}
    </OverlayTrigger>
  );
};

function getCellId(params: ICellRendererParams) {
  return 'row-' + params.rowIndex + '-' + params.colDef!.field;
}

export default DefaultTooltip;
export { getCellId };
export type { DefaultTooltipProps };
