// Based on Profile in guide https://auth0.com/docs/quickstart/spa/react/01-login
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from '../LoginButton/LoginButton';
import styles from './Profile.module.scss';
import { NavDropdown } from 'react-bootstrap';
import axios from 'axios';

const Profile = () => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (isAuthenticated && user) {
    const imageElement = <img className={styles.profileImage} src={user.picture} alt={user.name} />;

    return (
      // align="end"
      <NavDropdown alignRight title={imageElement} id="navbarScrollingDropdown" className={styles.profileDropdown}>
        <NavDropdown.ItemText>{user.name}</NavDropdown.ItemText>
        <NavDropdown.ItemText>{user.email}</NavDropdown.ItemText>
        <NavDropdown.Divider />
        <NavDropdown.Item onClick={() => axios.get('/auth0/send-me-password-reset-email')}>
          Send password reset email
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item onClick={() => logout({ returnTo: window.location.origin })}>Logout</NavDropdown.Item>
      </NavDropdown>
    );
  } else {
    return <LoginButton />;
  }
};

export default Profile;
