import { Col, Container, Row } from 'react-bootstrap';
import TeamType from '../../domain/team';
import { getStartOfCurrentMonth } from '../../helpers/AgGridDateHelpers';
import CompletedTaskGrid from '../../components/tasks/CompletedTaskGrid/CompletedTaskGrid';
import OverdueTasksGrid from '../../components/tasks/OverdueTasksGrid/OverdueTasksGrid';
import UnassignedOverdueTasksGrid from '../../components/tasks/UnassignedOverdueTasksGrid/UnassignedOverdueTasksGrid';

type TeamTasksProps = {
  selectedTeam: TeamType;
};

const TeamTasks = (props: TeamTasksProps) => {
  const currentMonth = getStartOfCurrentMonth(props.selectedTeam.timezone).toFormat('yyyy-MM');

  return (
    <Container fluid>
      <Row>
        <Col>
          <h4 className="text-center font-weight-bold">
            Completed Tasks for {props.selectedTeam.name} in {currentMonth}
          </h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <CompletedTaskGrid
            selectedTeam={props.selectedTeam}
            // TODO use picker for month?
            selectedMonth={currentMonth}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h4 className="text-center font-weight-bold">
            Overdue Tasks for {props.selectedTeam.name} in {currentMonth}
          </h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <OverdueTasksGrid
            selectedTeam={props.selectedTeam}
            // TODO use picker for month?
            selectedMonth={currentMonth}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h4 className="text-center font-weight-bold">
            Unassigned and Overdue Tasks for {props.selectedTeam.name} in {currentMonth}
          </h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <UnassignedOverdueTasksGrid
            selectedTeam={props.selectedTeam}
            // TODO use picker for month?
            selectedMonth={currentMonth}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default TeamTasks;
