import { AgGridReact } from 'ag-grid-react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { defaultOnGridReady, getDefaultColDef, useGridComponentStates } from '../../../helpers/GridComponentHelpers';
import SmrCategoryResponsibilityType, { convertToTypeToSend } from '../../../domain/smrResponsibility';
import TeamMemberType from '../../../domain/teamMember';
import { SMR_CATEGORY_RESPONSIBILITY_URL } from '../../../constants/urls';
import { createFeedbackMessageKey } from '../../FeedbackMessages/FeedbackMessages';
import TeamType from '../../../domain/team';
import { MemberSelect } from '../../members/MemberSelect/MemberSelect';

type SmrResponsibilityGridProps = {
  selectedTeam: TeamType;
};

const getColumns = (team: TeamType, addFeedbackMessage: any, refetch: any) => {
  return [
    {
      field: 'smrCategory.name',
      headerName: 'SMR Category',
      // Use flex instead of width to fill up the rest of the space
      // width: null,
      flex: 1,
    },
    {
      headerName: 'Assigned To',
      width: 200,
      field: 'assignedToMember',
      cellRenderer: 'assignedToRenderer',
      cellRendererParams: {
        selectedTeam: team,
        onChange: (newTeamMember: TeamMemberType | undefined, oldSmrResponsibility: SmrCategoryResponsibilityType) => {
          // I would love to use this, but grid API isn't available at the start
          // if(gridApi) {
          //   gridApi.showLoadingOverlay()
          // }
          const modifiedSmrResponsibilityToSend = convertToTypeToSend(oldSmrResponsibility);
          modifiedSmrResponsibilityToSend.assignedToMember = newTeamMember?.id;
          // We could also wrap this in react-query's useMutation, but that seems overkill
          const response = axios
            .patch(
              SMR_CATEGORY_RESPONSIBILITY_URL + '/' + modifiedSmrResponsibilityToSend.id + '/',
              modifiedSmrResponsibilityToSend,
            )
            .then(() => {
              // TODO handle errors
              addFeedbackMessage({
                key: createFeedbackMessageKey('smrCategoryResponsibility', 'changeTeamMember', oldSmrResponsibility.id),
                status: 'success',
                messageBody: <span>Team member changed successfully.</span>,
              });
              refetch();
            });
          return response;
        },
      },
      cellStyle: { overflow: 'visible' },
    },
  ];
};

const SmrResponsibilityGrid = (props: SmrResponsibilityGridProps) => {
  let gridComponentStates = useGridComponentStates<SmrCategoryResponsibilityType>();

  const smrResponsibilityRequestConfig = {
    params: {
      team: props.selectedTeam.id,
    },
  } as AxiosRequestConfig;

  const {
    isRefetching: isRefetchingResponsibilities,
    error: responsibilitiesError,
    data: responsibilitiesData,
    refetch: refetchResponsibilities,
  } = useQuery(
    ['getSmrResponsibilityForFcp', smrResponsibilityRequestConfig],
    () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      if (gridComponentStates.gridApi) {
        gridComponentStates.gridApi.showLoadingOverlay();
      }
      return axios.get<SmrCategoryResponsibilityType[]>(SMR_CATEGORY_RESPONSIBILITY_URL, smrResponsibilityRequestConfig);
    },
    // Simpler than getDefaultUseQueryConfig, because no edit dialog
    {
      staleTime: 100000, // Cache for this long, in ms
      onSuccess: (response: AxiosResponse<SmrCategoryResponsibilityType[]>) => {
        // If the grid isn't ready, this won't work,
        //  but the data will be picked up on onGridReady
        if (gridComponentStates.gridApi) {
          gridComponentStates.gridApi.setRowData(response.data);
        }
      },
    },
  );

  const [columnDefs] = React.useState(
    getColumns(props.selectedTeam, gridComponentStates.addFeedbackMessage, refetchResponsibilities),
  );

  // const getCoreRecurringGoalSchedulePermissionsRequestConfig = {
  //   params: {
  //     // No parameters
  //   }
  // } as AxiosRequestConfig
  // const { error: schedulePermissionsError,
  //   data: schedulePermissionsData,
  //   isLoading: isPermissionsLoading
  // } = useQuery(['getCoreRecurringGoalSchedulePermissions', getCoreRecurringGoalSchedulePermissionsRequestConfig], () => {
  //     return axios.get<PermissionType[]>(CORE_RECURRING_GOAL_SCHEDULE_PERMISSIONS_URL, getCoreRecurringGoalSchedulePermissionsRequestConfig)
  //   }
  // );

  if (responsibilitiesError) return <p>Error!</p>;

  const smrResponsibility = responsibilitiesData?.data;
  // const coreRecurringGoalSchedulePermissions = schedulePermissionsData?.data;
  //
  // if (!isPermissionsLoading && (!coreRecurringGoalSchedulePermissions || coreRecurringGoalSchedulePermissions.length === 0)) {
  //   return <p>No permission for core recurring goal responsibilities.</p>
  // }

  return (
    <Container
      fluid
      className={
        //styles.container +
        ' d-flex flex-column flex-grow-1'
      }
    >
      <Row className="d-flex flex-column flex-grow-1">
        <Col>
          <div
            className="ag-theme-balham"
            style={{
              height: '400px',
              width: '100%',
            }}
          >
            <AgGridReact
              onGridReady={(params) => {
                defaultOnGridReady(params, smrResponsibility, isRefetchingResponsibilities, gridComponentStates.setGridApi);
              }}
              frameworkComponents={{
                assignedToRenderer: MemberSelect,
              }}
              columnDefs={columnDefs}
              // 2022-18-07 Kirk, Austin, Brad - filter is too buried, make the button always visible
              suppressMenuHide={true}
              // rowData={DO_NOT_USE}
              tooltipShowDelay={450}
              defaultColDef={getDefaultColDef()}
            ></AgGridReact>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SmrResponsibilityGrid;
