import axios, { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import { GOAL_SOURCE_FIELDS_URL } from '../../../constants/urls';
import GoalSourceFieldType from '../../../domain/goalSourceField';

// TODO should these be by department? We will need to add department to the model
function useGetImpactMetrics(enabled: boolean = true) {
  const queryOptions = {
    params: {},
  } as AxiosRequestConfig;
  return useQuery(
    ['useGetImpactMetrics', queryOptions],
    async () => {
      const response = await axios.get<GoalSourceFieldType[]>(GOAL_SOURCE_FIELDS_URL, queryOptions);
      return response.data;
    },
    {
      enabled: enabled,
    },
  );
}

export { useGetImpactMetrics };
