import { RowClassParams } from 'ag-grid-community';
import { AgGridColumn, AgGridColumnProps, AgGridReact } from 'ag-grid-react';
import { DateTime } from 'luxon';
import React from 'react';
import TeamType from '../../../../domain/team';
import { MembersScoreHistoryMember } from '../../../../pages/TeamScores/TeamScores';
import styles from './MembersScoreHistoryGrid.module.scss';
import { getDefaultColDef } from '../../../../helpers/GridComponentHelpers';
import { getHeatMapClass } from '../../../../helpers/ColorHelper';

type MembersScoreHistoryGridProps = {
  selectedTeam: TeamType;
  allTeamMembersScores: MembersScoreHistoryMember[];
};

// Equivalent of a Python dictionary. To keep typescript happy
// https://stackoverflow.com/a/44441178/13815107
interface MemberWithMonthFields {
  [key: string]: any;
}

const bottomRowData = (firstTeamMembersMonthlyScores: any, gridData: any) => {
  const monthMeanMap: any = {};
  firstTeamMembersMonthlyScores.forEach((oneMonthScore: any) => {
    gridData.forEach((callSignScores: any) => {
      monthMeanMap[oneMonthScore.month]
        ? (monthMeanMap[oneMonthScore.month] += parseInt(callSignScores[oneMonthScore.month]))
        : (monthMeanMap[oneMonthScore.month] = parseInt(callSignScores[oneMonthScore.month]));
    });
  });
  const bottomRowData: any[] = [];
  Object.keys(monthMeanMap).forEach((monthMeanMapKey) => {
    monthMeanMap[monthMeanMapKey] = (monthMeanMap[monthMeanMapKey] / gridData.length).toFixed(2);
  });
  monthMeanMap.name = 'Avg. Score';
  bottomRowData.push(monthMeanMap);
  return bottomRowData;
};

const MembersScoreHistoryGrid = (props: MembersScoreHistoryGridProps) => {
  const allTeamMembersScores = props.allTeamMembersScores;

  const MEMBERS_SCORE_HISTORY_STATIC_COLUMNS: AgGridColumnProps[] = [
    {
      field: 'callSign',
      headerName: 'Call Sign',
      cellRenderer: (params: any) => {
        /* eslint-disable react/jsx-no-target-blank */
        return (
          <a href={'member-scores?teamMember=' + params.data.id} target="_blank">
            {params.value}
          </a>
        );
      },
    },
    {
      field: 'name',
      headerName: 'Name',
    },
  ];

  // Get the names of the months for use as the column names
  const firstTeamMembersMonthlyScores = allTeamMembersScores[0].scores;
  const dateColumns = firstTeamMembersMonthlyScores.map((oneMonthScore) => {
    const dateObjectOfMonth = DateTime.fromISO(oneMonthScore.month);
    const humanReadable = dateObjectOfMonth.toFormat('LLL y');
    const newDateColumn: any = {
      field: oneMonthScore.month,
      headerName: humanReadable,
      comparator: (valueA: string, valueB: string) => parseInt(valueA) - parseInt(valueB),
      cellClass: (params: any) => {
        const { value } = params;
        return value && value.replace ? getHeatMapClass(value.replace('%', '') / 100) : undefined;
      },
    };
    return newDateColumn;
  });

  const allColumns = MEMBERS_SCORE_HISTORY_STATIC_COLUMNS.concat(dateColumns);

  const agGridData = allTeamMembersScores.map((teamMember) => {
    const agGridRow: MemberWithMonthFields = {
      callSign: teamMember.callSign.toUpperCase(),
      name: teamMember.name,
      id: teamMember.id,
    };
    teamMember.scores.forEach((oneMonthScore) => {
      // @ts-ignore
      agGridRow[oneMonthScore.month] = (oneMonthScore.score * 100).toFixed(0) + '%';
    });
    return agGridRow;
  });

  // @ts-ignore
  return (
    <AgGridReact
      className="ag-theme-balham"
      rowData={agGridData}
      domLayout="autoHeight"
      defaultColDef={getDefaultColDef()}
      pinnedBottomRowData={bottomRowData(firstTeamMembersMonthlyScores, agGridData)}
      getRowStyle={(params) => {
        if (params.node.rowPinned) {
          return { 'font-weight': 'bold' };
        }
      }}
      getRowClass={(params: RowClassParams) => {
        if (params.data.isPastDue) {
          return styles.tasksGridWarning;
        } else {
          // No classes
          return;
        }
      }}
    >
      {allColumns.map((column) => (
        <AgGridColumn {...column} key={column.field} />
      ))}
    </AgGridReact>
  );
};

export default MembersScoreHistoryGrid;
