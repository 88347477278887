import axios, { AxiosRequestConfig } from 'axios';
import { Form, Formik } from 'formik';
import { Alert, Button, Modal } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { GET_TAGS_URL, TEAM_MEMBERS_URL } from '../../../constants/urls';
import PermissionType from '../../../domain/permission';
import TagType from '../../../domain/tag';
import TaskType, { TaskTypeToSend } from '../../../domain/task';
import TeamType from '../../../domain/team';
import TeamMemberType from '../../../domain/teamMember';
import FormikController from '../../../formik/FormikSelect/FormikController';
import { getNameOfCreationUser } from '../../../helpers/AuditFieldHelper';
import { hasPermission } from '../../../helpers/PermissionHelper';
import { Mode } from '../../../state_types/mode';
import { getSimpleModal, ModifyDialogState } from '../../../helpers/GridComponentHelpers';
import { getFormikReadOnlyField } from '../../../formik/FormikHelpers';
import { getCallSignWithPositionAndName } from '../../../helpers/TeamMembershipHelpers';

export interface ModifyTaskDialogProps {
  selectedTeam: TeamType;
  selectedTeamMember?: TeamMemberType;
  state: ModifyDialogState<TaskType>;
  onOk: (updatedTaskFields: TaskTypeToSend, idToUpdate: number | null) => Promise<void>;
  onCancel: () => void;
  onDeleteIcon: (taskToDelete: TaskType) => void;
  taskPermissions: PermissionType[];
  loggedInTeamMember: TeamMemberType | null;
}

const getMode = (state: ModifyDialogState<TaskType>) => {
  if (state.objectToModify) {
    return Mode.Change;
  }
  return Mode.Add;
};

const getModalTitle = (mode: Mode) => {
  let titleAction;
  if (mode === Mode.Add) {
    titleAction = 'Add';
  } else {
    titleAction = 'Change';
  }
  return titleAction + ' Task';
};

const ADD_TASK_FOR_ANY_MEMBER_PERMISSION = 'add_task';
const CHANGE_TASK_FOR_ANY_MEMBER_PERMISSION = 'change_task';

function isAssignedToLoggedInUser(
  initialStateOfEditableTaskFields: TaskTypeToSend | TaskType,
  loggedInTeamMember: TeamMemberType | null,
) {
  if (!loggedInTeamMember || !initialStateOfEditableTaskFields.assignedToMember) {
    return false;
  }
  return loggedInTeamMember.id === initialStateOfEditableTaskFields.assignedToMember;
}

const ModifyTaskDialog = (props: ModifyTaskDialogProps) => {
  const { state, onOk, onCancel, onDeleteIcon } = props;
  // If task to edit is set, assume edit.
  // Otherwise, assume create
  const mode = getMode(state);

  const handleCancel = () => {
    onCancel();
  };
  // const task = state.taskToEdit;

  const teamMembersRequestConfig = {
    params: {
      team: props.selectedTeam.id,
    },
  } as AxiosRequestConfig;
  const tagsRequestConfig = {
    // No parameters
  } as AxiosRequestConfig;
  const {
    isLoading,
    error,
    data: optionsData,
    refetch: refetchOptions,
  } = useQuery(['allOptionsQuery', teamMembersRequestConfig], () => {
    // https://www.storyblok.com/tp/how-to-send-multiple-requests-using-axios
    // I wish I could put TeamMemberType[] and TagType[] on the requests
    //  However, axios.all() typings assumes all response types match first request, so using 'any'
    const teamMembersRequest = axios.get<any>(TEAM_MEMBERS_URL, teamMembersRequestConfig);
    const tagsRequest = axios.get<any>(GET_TAGS_URL, tagsRequestConfig);
    return axios.all([teamMembersRequest, tagsRequest]).then(
      axios.spread((...responses) => {
        const allOptionsResults = {
          teamMembersResponse: responses[0],
          tagsResponse: responses[1],
        };
        return allOptionsResults;
      }),
    );
  });
  if (isLoading) return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'Loading...');
  if (error) return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'Error!');
  if (optionsData === undefined) return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'No Data!');

  const canAddForAnyMember = hasPermission(props.taskPermissions, ADD_TASK_FOR_ANY_MEMBER_PERMISSION);
  const canChangeForAnyMember = hasPermission(props.taskPermissions, CHANGE_TASK_FOR_ANY_MEMBER_PERMISSION);
  const hasAnyMemberPermission =
    (mode === Mode.Add && canAddForAnyMember) || (mode === Mode.Change && canChangeForAnyMember);

  if (!props.loggedInTeamMember || !props.loggedInTeamMember.account) {
    if (!hasAnyMemberPermission) {
      return (
        <Modal show={state.isOpen} onHide={handleCancel}>
          <Modal.Header closeButton>
            <Modal.Title>{getModalTitle(mode)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              You are not set up as a team member for this team (you don't have a tab at the top), so you cannot create
              self-assigned tasks.
              <br />
              <br />
              You are also not setup as a manager, so you cannot assign tasks to others.
              <br />
              <br />
              Please have a manager set you up on the Admin tab or consult LJI tech team.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCancel}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  }

  const teamMembers = optionsData.teamMembersResponse.data as TeamMemberType[];

  const teamMemberOptions = teamMembers.map((teamMember) => {
    const optionLabel = getCallSignWithPositionAndName(teamMember);
    return {
      label: optionLabel,
      value: teamMember.id,
    };
  });

  const tags = optionsData.tagsResponse.data as TagType[];

  const tagOptions = tags.map((tag) => {
    return {
      label: tag.name,
      // Value is the object itself. This makes it easy for Formik to pass to backend
      value: tag,
    };
  });

  // TODO should this be in some sort of useState hook?
  let initialStateOfEditableTaskFields;
  let onSubmit;
  let deleteButton: JSX.Element | null;
  let createdByText: JSX.Element | null;
  let alertText: JSX.Element | null;
  let assignedToMemberElement: JSX.Element;

  function getReadonlyAssignedToElement(teamMember: TeamMemberType) {
    // Can only change own tasks, can't reassign it to someone else
    const assignedToMemberString = getCallSignWithPositionAndName(teamMember);
    assignedToMemberElement = getFormikReadOnlyField('Assigned To:', assignedToMemberString);
  }

  if (mode === Mode.Change) {
    // Tell typescript that taskToEdit is definitely not null
    // TODO this is hacky, find a way to fix it
    const task = state.objectToModify!;

    // Copy only editable fields
    initialStateOfEditableTaskFields = {
      // Not editable
      // id: task.id,
      summary: task.summary,
      description: task.description,
      linkUrl: task.linkUrl,
      // Not editable
      // isComplete: task.isComplete,
      // Not editable
      // creationUser: task.creationUser,
      assignedToMember: task.assignedToMember.id,
      // Not editable, derived field
      // isOverdue: task.isOverdue,
      dueDateTime: task.dueDateTime,
      // Not editable, derived field
      // actions: task.actions,
      totalPoints: task.totalPoints,
      // Not editable, derived field
      // currentPossiblePoints: task.currentPossiblePoints,

      // Not editable in the UI, but needed on create, so included
      // TODO now create is separated, is above comment still valid???
      team: task.team.id,

      tags: task.tags,
    };

    if (task.creationType === 'triggeredEvent') {
      alertText = (
        <Alert variant="danger">
          This is a task was created by an event that matched a trigger rule. Would you also like to{' '}
          <Link to={'/event-triggered-tasks?edit=' + task.createdByTriggerRule}>edit the rule?</Link>
        </Alert>
      );
    } else if (task.creationType === 'recurring') {
      alertText = (
        <Alert variant="danger">
          This is a recurring task. Would you also like to{' '}
          <Link to={'/all-assignments?assignmentToFocusOn=recurringTask-' + task.createdByRecurringTaskSchedule}>
            edit the schedule?
          </Link>
        </Alert>
      );
    } else {
      alertText = null;
    }

    const createdByName = getNameOfCreationUser(task);
    createdByText = getFormikReadOnlyField('Assigned (created) by:', createdByName);

    deleteButton = (
      <Button
        id={'deleteModifyTask'}
        className="bi-trash-fill mr-auto"
        variant="danger"
        onClick={() => {
          onDeleteIcon(task);
        }}
      >
        Delete
      </Button>
    );

    // TODO figure out the types of these inputs
    onSubmit = (updatedTask: any, { setSubmitting }: any) => {
      console.log('update dialog ok hit', updatedTask);
      setTimeout(() => {
        if (updatedTask === null) {
          // If this ever happens, we probably need to use 'useEffect' in some capacity
          alert('updatedTask was null, contact developers');
          setSubmitting(false);
          return;
        }

        // TODO make this typescript cast have a validation or something
        onOk(updatedTask as TaskTypeToSend, task.id).then(() => {
          // In case new tags were created
          refetchOptions();
          setSubmitting(false);
        });
      }, 400);
    };

    if (hasPermission(props.taskPermissions, CHANGE_TASK_FOR_ANY_MEMBER_PERMISSION)) {
      // can change who it is assigned to, defaults to the tab you picked
      assignedToMemberElement = (
        <FormikController control="select" name="assignedToMember" label="Assigned To" options={teamMemberOptions} />
      );
    } else {
      getReadonlyAssignedToElement(task.assignedToMember);
    }
  } else {
    // For now, this defaults to the tab you have selected
    // unless you don't have permission... then it defaults to you
    // TODO This could also always default to logged in user or to blank...
    let assignedToMemberId;
    if (hasPermission(props.taskPermissions, ADD_TASK_FOR_ANY_MEMBER_PERMISSION) && props.selectedTeamMember) {
      assignedToMemberId = props.selectedTeamMember.id;
    } else if (props.loggedInTeamMember) {
      // should only have add_my_task
      assignedToMemberId = props.loggedInTeamMember.id;
    } else {
      // Technically this code does nothing, but better for clarity
      assignedToMemberId = undefined;
    }
    initialStateOfEditableTaskFields = {
      summary: undefined,
      description: '',
      linkUrl: '',
      assignedToMember: assignedToMemberId,
      dueDateTime: '',
      totalPoints: undefined,
      team: props.selectedTeam.id,
      tags: [],
    };
    // Create
    onSubmit = (newTask: TaskTypeToSend, { setSubmitting }: any) => {
      newTask.creationType = 'manual';
      setTimeout(() => {
        if (newTask === null) {
          // If this ever happens, we probably need to use 'useEffect' in some capacity
          alert('new task was null, contact developers');
          setSubmitting(false);
          return;
        }
        // TODO make this typescript cast have a validation or something
        onOk(newTask, null).then(() => {
          // In case new tags were created
          refetchOptions();
          setSubmitting(false);
        });
      }, 400);
    };
    deleteButton = null;
    alertText = null;
    createdByText = null;

    if (hasPermission(props.taskPermissions, ADD_TASK_FOR_ANY_MEMBER_PERMISSION)) {
      // can change who it is assigned to, defaults to the tab you picked
      assignedToMemberElement = (
        <FormikController control="select" name="assignedToMember" label="Assigned To" options={teamMemberOptions} />
      );
    } else {
      // should only have add_my_task. Read only, defaults to logged in team member
      // Typescript was angry that loggedInTeamMember might be null, but I can't find where that would be
      getReadonlyAssignedToElement(props.loggedInTeamMember!);
    }
  }

  let totalPointsSchema;
  if (isAssignedToLoggedInUser(initialStateOfEditableTaskFields, props.loggedInTeamMember)) {
    totalPointsSchema = Yup.number()
      .required('Required')
      .min(0, 'Min value 0.')
      .max(3, 'Tasks assigned to yourself cannot have a weight higher than 3.');
  } else {
    totalPointsSchema = Yup.number().required('Required').min(0, 'Min value 0.');
  }

  const TaskFormSchema = Yup.object().shape({
    assignedToMember: Yup.number().nullable().required('Required'),
    summary: Yup.string().required('Required'),
    description: Yup.string().optional(),
    linkUrl: Yup.string().optional(),
    dueDateTime: Yup.date().required('Required'),
    totalPoints: totalPointsSchema,
  });

  return (
    // This component causes 'findDOMNode is deprecated in StrictMode' warning
    // Unfortunately, this workaround on SO didn't work https://stackoverflow.com/a/64325602
    // The best way to fix this is to upgrade to react-bootstrap 2.x/bootstrap 5, but that takes some work
    // TODO fix this warning
    <Modal show={state.isOpen} onHide={handleCancel}>
      {
        <Formik initialValues={initialStateOfEditableTaskFields} validationSchema={TaskFormSchema} onSubmit={onSubmit}>
          {(formikProps: any) => (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>{mode === Mode.Add ? 'Add' : 'Change'} Task</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {alertText}
                {/* Only show createdByText on editing */}
                {createdByText}
                <FormikController control="input" name="summary" label="Summary" />
                <FormikController control="input" name="description" label="Detailed Description (optional)" />
                <FormikController control="input" name="linkUrl" label="Link (optional)" />
                {assignedToMemberElement}
                <FormikController
                  control="date"
                  name="dueDateTime"
                  label="Due Date"
                  timezone={props.selectedTeam.timezone}
                />
                <FormikController control="input" name="totalPoints" label="Weight" />
                {/* 'startingOptions' because we are allowing the creation of new tags */}
                <FormikController control="tags" name="tags" label="Tags (optional)" startingOptions={tagOptions} />
              </Modal.Body>
              <Modal.Footer>
                {/* Only show delete button on editing */}
                {deleteButton}
                <Button variant="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button id="submitModifyTask" variant="primary" type="submit" disabled={formikProps.isSubmitting}>
                  Ok
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      }
    </Modal>
  );
};

export default ModifyTaskDialog;
