import { DateTime } from 'luxon';
import { useContext } from 'react';
import Select from 'react-select';
import SelectedMonthContext from '../../contexts/SelectedMonthContext';
import styles from './MonthSelect.module.scss';
import { getStartOfCurrentMonth } from '../../helpers/AgGridDateHelpers';

const EARLIEST_MONTH = '2022-01';

const MonthSelect = () => {
  const selectedMonthContext = useContext(SelectedMonthContext);

  // TODO Timezone issues?
  const nowMonthDateTime = getStartOfCurrentMonth();
  const earliestMonthDateTime = DateTime.fromFormat(EARLIEST_MONTH, 'yyyy-MM');
  const defaultValue = {
    value: nowMonthDateTime.toFormat('yyyy-MM'),
    label: nowMonthDateTime.toFormat('LLL yyyy'),
  };

  const monthOptions = [] as any;
  for (
    var monthDate = nowMonthDateTime;
    monthDate.startOf('month') >= earliestMonthDateTime.startOf('month');
    monthDate = monthDate.minus({ months: 1 })
  ) {
    const monthOption = {
      value: monthDate.toFormat('yyyy-MM'),
      label: monthDate.toFormat('LLL yyyy'),
    };
    monthOptions.push(monthOption);
  }

  const onMonthChange = (newMonth: any) => {
    selectedMonthContext.setSelectedMonth(newMonth.value);
  };

  return (
    <span className={styles.monthSelectWrapper}>
      <Select
        // value={selectedMonthContext.selectedMonth}
        onChange={onMonthChange}
        options={monthOptions}
        defaultValue={defaultValue}
      />
    </span>
  );
};

export default MonthSelect;
