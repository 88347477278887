// Based on LoginButton in guide https://auth0.com/docs/quickstart/spa/react/01-login
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'react-bootstrap';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button id="logInButton" onClick={() => loginWithRedirect()}>
      Log In
    </Button>
  );
};

export default LoginButton;
