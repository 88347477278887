import styles from './ColorHelper.module.scss';

type RgbColor = {
  red: number;
  green: number;
  blue: number;
};

// This is so we can put black text on white/yellow colors, but white on black/blue.
function isColorDark(hexColor: string): boolean {
  const LUMA_THRESHOLD = 0.5;
  const colorRgb = hexToRgb(hexColor);
  if (colorRgb !== null) {
    // How light or dark the eye 'percieves' the color.
    // https://css-tricks.com/switch-font-color-for-different-backgrounds-with-css/#introducing-perceived-lightness
    const lumaOfBackgroundColor = (colorRgb.red * 0.2126 + colorRgb.green * 0.7152 + colorRgb.blue * 0.0722) / 255;
    if (lumaOfBackgroundColor < LUMA_THRESHOLD) {
      return true;
    }
  } else {
    console.warn('Color from backend is not hex value: ' + hexColor);
  }
  return false;
}

// https://stackoverflow.com/a/5624139/13815107
function hexToRgb(hex: string) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? ({
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16),
      } as RgbColor)
    : null;
}

/**
 * Converts integer to a hexidecimal code, prepad's single
 * digit hex codes with 0 to always return a two digit code.
 *
 * @param {Integer} i Integer to convert
 * @returns {String} The hexidecimal code
 */
function intToHex(i: number) {
  var hex = i.toString(16);
  return hex.length < 2 ? '0' + hex : hex;
}

function rgbToHex(rgb: RgbColor) {
  return '#' + intToHex(rgb.red) + intToHex(rgb.green) + intToHex(rgb.blue);
}

/**
 * Return red, yellow, or green color from scalar *value*.
 * Low values look more red, 0.5 looks yellow, high values look more green.
 * I stole some function from SO to make the colors look better to the human eye.
 * // https://stackoverflow.com/a/11828426
 *
 * @param {float} scalarValue Scalar value between 0 and 1
 * @return {String} color - Hex color in format #FFFFFF
 */
function getIndicatorColor(scalarValue: number): string {
  if (scalarValue < 0 || scalarValue > 1) {
    throw Error('');
  }

  const positionOnRedGreenScale = scalarValue * 510;

  let redValue;
  let greenValue;
  if (positionOnRedGreenScale < 255) {
    redValue = 255;
    greenValue = Math.sqrt(positionOnRedGreenScale) * 16;
    greenValue = Math.round(greenValue);
  } else {
    greenValue = 255;
    const something = positionOnRedGreenScale - 255;
    redValue = 255 - (something * something) / 255;
    redValue = Math.round(redValue);
  }
  return rgbToHex({
    red: redValue,
    green: greenValue,
    blue: 0,
  });
}

const getHeatMapClass = (pointsPercent: number): string => {
  let colorClass;
  if (pointsPercent >= 1) {
    colorClass = styles.goalSuccessColor;
  } else if (pointsPercent > 0.75) {
    colorClass = styles.goal75PlusColor;
  } else if (pointsPercent > 0.5) {
    colorClass = styles.goal50PlusColor;
  } else if (pointsPercent > 0.25) {
    colorClass = styles.goal25PlusColor;
  } else if (pointsPercent > 0) {
    colorClass = styles.goal0PlusColor;
  } else {
    colorClass = styles.goalFailColor;
  }
  return colorClass;
};

export { isColorDark, getIndicatorColor, getHeatMapClass };
