import { SelectedTeamMemberContextType } from '../contexts/SelectedTeamMemberContext';
import TeamType from '../domain/team';

function isTeamMemberSelectedAndIsOnThisTeam(
  selectedTeamMemberContext: SelectedTeamMemberContextType,
  selectedTeam: TeamType,
): boolean {
  return (
    selectedTeamMemberContext?.selectedTeamMember !== null &&
    selectedTeamMemberContext.selectedTeamMember.team === selectedTeam.id
  );
}

export { isTeamMemberSelectedAndIsOnThisTeam };
