import { ICellRendererParams } from 'ag-grid-community';
import GoalType from '../../../domain/goal';
import DefaultTooltip, { getCellId } from '../../DefaultTooltip/DefaultTooltip';
import {
  ALL_OR_NOTHING,
  LINEAR_FROM_CUTOFF,
  PERCENT_UOM,
} from '../../fcp/goals/recurring/ModifyCoreRecurringGoalScheduleDialog/ModifyCoreRecurringGoalScheduleDialog';
import { roundTo2Decimals } from '../../../helpers/RoundingHelper';
import React from 'react';
import { getHeatMapClass } from '../../../helpers/ColorHelper';

type GoalGridPointsProps = ICellRendererParams & {
  // Populated by AG grid based on 'field':'actions' in column configuration
  value: string[];
  // Populated by AG grid because this is one row of data
  data: GoalType;
  // Passed in with cellRendererParams
  // onHover: (goal: GoalType) => void;
};

function isUomPercent(goal: GoalType) {
  return goal.createdByRecurringGoalSchedule.unitOfMeasure === PERCENT_UOM;
}

function getValueString(goal: GoalType, value: number | undefined | null): string {
  if (value === undefined || value === null) {
    return '';
  }
  if (isUomPercent(goal)) {
    return roundTo2Decimals(value * 100).toString() + '%';
  } else {
    return value.toString();
  }
}

function getGoalValueString(goal: GoalType) {
  return getValueString(goal, goal.goalValue);
}

function getCurrentValueString(goal: GoalType) {
  return getValueString(goal, goal.currentValue);
}

function getResultValueString(goal: GoalType) {
  return getValueString(goal, goal.resultValue);
}

function getCutoffValueString(goal: GoalType) {
  return getValueString(goal, goal.createdByRecurringGoalSchedule.cutoffValue);
}

function canGetBetter(goal: GoalType) {
  if (
    isUomPercent(goal) &&
    goal.createdByRecurringGoalSchedule.betterDirection.toLowerCase() === 'higher' &&
    goal.goalValue === 1
  ) {
    return false;
  } else if (
    isUomPercent(goal) &&
    goal.createdByRecurringGoalSchedule.betterDirection.toLowerCase() === 'lower' &&
    goal.goalValue === 0
  ) {
    return false;
  }
  return true;
}

function canGetWorse(goal: GoalType) {
  if (
    isUomPercent(goal) &&
    getWorseDirection(goal.createdByRecurringGoalSchedule.betterDirection)?.toLowerCase() === 'higher' &&
    goal.goalValue === 1
  ) {
    return false;
  } else if (
    isUomPercent(goal) &&
    getWorseDirection(goal.createdByRecurringGoalSchedule.betterDirection)?.toLowerCase() === 'lower' &&
    goal.goalValue === 0
  ) {
    return false;
  }
  return true;
}

function getGoalInfoTooltipText(goal: GoalType) {
  let tooltipDescription;
  if (goal.createdByRecurringGoalSchedule && goal.createdByRecurringGoalSchedule.scoringType) {
    const scoringType = goal.createdByRecurringGoalSchedule.scoringType;
    let goalValueString = getGoalValueString(goal);

    tooltipDescription = 'Full points: When end of month result is ' + goalValueString;
    if (canGetBetter(goal)) {
      tooltipDescription += ' or ' + goal.createdByRecurringGoalSchedule.betterDirection;
    }

    if (scoringType === LINEAR_FROM_CUTOFF) {
      tooltipDescription += '\n0 points: When end of month result is ' + getCutoffValueString(goal);
      if (canGetWorse(goal)) {
        tooltipDescription += ' or ' + getWorseDirection(goal.createdByRecurringGoalSchedule.betterDirection);
      }
      tooltipDescription += '\n';
    } else if (scoringType === ALL_OR_NOTHING) {
      tooltipDescription +=
        '\n0 points: Anything ' +
        getWorseDirection(goal.createdByRecurringGoalSchedule.betterDirection) +
        ' than ' +
        goalValueString +
        '\n';
    } else {
      tooltipDescription = 'Unknown scoring formula for scoring type ' + scoringType;
    }
  } else {
    tooltipDescription = 'Unknown scoring formula';
  }

  if (goal.resultValue || goal.resultValue === 0) {
    tooltipDescription += 'Result: ' + getResultValueString(goal) + '\n';
  } else if (goal.currentValue || goal.currentValue === 0) {
    tooltipDescription += 'Result of last 30 days: ' + getCurrentValueString(goal) + '\n';
  } else {
    tooltipDescription += 'Result of last 30 days: UNKNOWN';
  }
  return tooltipDescription;
}

const getWorseDirection = (betterDirection: string | null) => {
  if (!betterDirection) {
    return null;
  } else if (betterDirection.toLowerCase() === 'higher') {
    return 'LOWER';
  } else if (betterDirection.toLowerCase() === 'lower') {
    return 'HIGHER';
  } else {
    return 'UNKNOWN WORSE DIRECTION';
  }
};

const GoalGridPoints = (props: GoalGridPointsProps) => {
  const goal = props.data as GoalType;
  // TODO should I pass in which field I want to look at instead of defaulting to earned then current?
  let points;
  if (goal.pointsEarned || goal.pointsEarned === 0) {
    points = goal.pointsEarned;
  } else {
    points = goal.currentPoints;
  }
  let pointsPercent;
  if (goal.pointsEarnedPercent || goal.pointsEarnedPercent === 0) {
    pointsPercent = goal.pointsEarnedPercent;
  } else {
    pointsPercent = goal.currentPointsPercent;
  }

  let pointsString;
  if (points || points === 0) {
    pointsString = roundTo2Decimals(points).toString();
  } else {
    pointsString = '???';
  }
  const totalPointsString = roundTo2Decimals(goal.totalPoints).toString();

  return (
    <span className={getHeatMapClass(pointsPercent)}>
      <span>
        {pointsString} / {totalPointsString} pts
      </span>
      <DefaultTooltip id={getCellId(props) + '-creation-icon'} tooltipText={getGoalInfoTooltipText(goal)}>
        <i className="bi bi-question-circle" />
      </DefaultTooltip>
    </span>
  );
};

export default GoalGridPoints;
export { getValueString, getWorseDirection };
export type { GoalGridPointsProps };
