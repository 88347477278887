import { ICellRendererParams } from 'ag-grid-community';
import GoalType from '../../../domain/goal';
import styles from './GoalGridSummary.module.scss';
import React from 'react';
import CoreRecurringGoalScheduleType from '../../../domain/coreRecurringGoalSchedule';
import RecurringGoalScheduleType from '../../../domain/recurringGoalSchedule';
import DefaultTooltip, { getCellId } from '../../DefaultTooltip/DefaultTooltip';

type GoalGridSummaryProps = ICellRendererParams & {
  // Populated by AG grid based on 'field':'actions' in column configuration
  value: string[];
  // Populated by AG grid because this is one row of data
  data: GoalType;
};

function getTooltipText(value: string, goal: GoalType | RecurringGoalScheduleType | CoreRecurringGoalScheduleType) {
  if ('description' in goal && goal.description) {
    return value + '\n\nDescription: ' + goal.description;
  } else if ('descriptionTemplate' in goal && goal.descriptionTemplate) {
    return value + '\n\nDescription Template: ' + goal.descriptionTemplate;
  } else {
    return value;
  }
}

const GoalGridSummary = (props: GoalGridSummaryProps) => {
  const goal = props.data as GoalType | RecurringGoalScheduleType | CoreRecurringGoalScheduleType;

  let linkUrl;
  if ('linkUrl' in goal && goal.linkUrl) {
    linkUrl = goal.linkUrl;
  } else if ('linkUrlTemplate' in goal && goal.linkUrlTemplate) {
    linkUrl = goal.linkUrlTemplate;
  }

  let textElem;
  if (linkUrl) {
    textElem = (
      <a href={linkUrl} target="_blank" rel="noreferrer" className={styles.taskSummaryText}>
        {props.value}
      </a>
    );
  } else {
    textElem = <span className={styles.taskSummaryText}>{props.value}</span>;
  }
  return (
    <span style={{ display: 'flex' }}>
      {textElem}
      <DefaultTooltip id={getCellId(props) + '-info-tooltip'} tooltipText={getTooltipText(props.value, goal)}>
        <i className={styles.taskSummaryIcon + ' bi bi-info-circle'} />
      </DefaultTooltip>
    </span>
  );
};

export default GoalGridSummary;

export type { GoalGridSummaryProps };
