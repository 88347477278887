import { Button, Col, Container, Row } from 'react-bootstrap';
import TeamMemberType from '../../../domain/teamMember';
import { doActionsContain } from '../../../helpers/PermissionHelper';
import React from 'react';

type MemberGridActionsProps = {
  // Populated by AG grid because this is one row of data
  data: TeamMemberType;
  // Passed in with cellRendererParams
  onEditClick: (member: TeamMemberType) => void;
};

const MemberGridActions = (props: MemberGridActionsProps) => {
  const member = props.data as TeamMemberType;
  return (
    <Container>
      <Row>
        <Col>
          {doActionsContain(member, 'change') && (
            <Button
              onClick={() => {
                props.onEditClick(member);
              }}
              variant="outline"
              size="sm"
            >
              <span className="bi-pencil-fill" />
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default MemberGridActions;

export type { MemberGridActionsProps };
