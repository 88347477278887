import axios, { AxiosRequestConfig } from 'axios';
import { Form, Formik } from 'formik';
import { Alert, Button, Modal } from 'react-bootstrap';
import { useQuery } from 'react-query';
import * as Yup from 'yup';
import { ACCOUNTS_URL } from '../../../constants/urls';
import PermissionType from '../../../domain/permission';
import TeamMemberType, { TeamMemberTypeToSend } from '../../../domain/teamMember';
import TeamType from '../../../domain/team';
import FormikController from '../../../formik/FormikSelect/FormikController';
import { Mode } from '../../../state_types/mode';
import AccountType from '../../../domain/account';
import { getAccountString } from '../../../helpers/AccountHelpers';
import React from 'react';
import { getSimpleModal } from '../../../helpers/GridComponentHelpers';

type ModifyMemberDialogState = {
  isOpen: boolean;
  memberToEdit: TeamMemberType | null;
};

export interface ModifyMemberDialogProps {
  selectedTeam: TeamType;
  state: ModifyMemberDialogState;
  onOk: (updatedMemberFields: TeamMemberTypeToSend, idToUpdate: number | null) => Promise<void>;
  onCancel: () => void;
  onDeleteIcon: (memberToDelete: TeamMemberType, selectedTeam: TeamType) => void;
  memberPermissions: PermissionType[];
}

const getMode = (state: ModifyMemberDialogState) => {
  if (state.memberToEdit) {
    return Mode.Change;
  }
  return Mode.Add;
};

const getModalTitle = (mode: Mode) => {
  let titleAction;
  if (mode === Mode.Add) {
    titleAction = 'Add';
  } else {
    titleAction = 'Change';
  }
  return titleAction + ' Member';
};

const ModifyMemberDialog = (props: ModifyMemberDialogProps) => {
  const { state, onOk, onCancel, onDeleteIcon } = props;
  // If member to edit is set, assume edit.
  // Otherwise, assume create
  const mode = getMode(state);

  const handleCancel = () => {
    onCancel();
  };
  // const member = state.memberToEdit;

  const accountRequestConfig = {
    // No params, ALL ACCOUNTS for ALL departments
  } as AxiosRequestConfig;
  const { isLoading, error, data, refetch } = useQuery(['getAccounts', accountRequestConfig], () => {
    // https://www.storyblok.com/tp/how-to-send-multiple-requests-using-axios
    // I wish I could put TeamTeamMemberType[] and TagType[] on the requests
    //  However, axios.all() typings assumes all response types match first request, so using 'any'
    return axios.get<AccountType[]>(ACCOUNTS_URL, accountRequestConfig);
  });
  if (isLoading) return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'Loading...');
  if (error) return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'Error!');
  if (data === undefined) return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'No Data!');

  const accounts = data.data as AccountType[];

  const accountOptions = accounts.map((account) => {
    const optionLabel = getAccountString(account);
    return {
      label: optionLabel,
      // Value is the id. We GET sub-objects from the frontend but only POST/PUT/PATCH the ids of sub-objects
      value: account.id,
    };
  });

  // TODO should this be in some sort of useState hook?
  let initialStateOfEditableMemberFields;
  let onSubmit;
  let deleteButton: JSX.Element | null;
  if (mode === Mode.Change) {
    // Tell typescript that memberToEdit is definitely not null
    // TODO this is hacky, find a way to fix it
    const member = state.memberToEdit!;

    // Copy only editable fields
    initialStateOfEditableMemberFields = {
      // Not editable
      // id: member.id,
      callSign: member.callSign,
      account: member.account?.id,
      position: member.position,
      // Not editable, derived field
      // actions: member.actions,

      manager: member.manager?.id,

      // Not editable in the UI, but needed on create, so included
      // TODO now create is separated, is above comment still valid???
      team: member.team,
    };

    deleteButton = (
      <Button
        className="bi-trash-fill mr-auto"
        variant="danger"
        onClick={() => {
          onDeleteIcon(member, props.selectedTeam);
        }}
      >
        Delete
      </Button>
    );

    // TODO figure out the types of these inputs
    onSubmit = (updatedMember: any, { setSubmitting }: any) => {
      console.log('update member dialog ok hit', updatedMember);
      setTimeout(() => {
        if (updatedMember === null) {
          // If this ever happens, we probably need to use 'useEffect' in some capacity
          alert('updatedMember was null, contact developers');
          setSubmitting(false);
          return;
        }

        // TODO make this typescript cast have a validation or something
        onOk(updatedMember as TeamMemberTypeToSend, member.id).then(() => {
          // In case new tags were created
          refetch();
          setSubmitting(false);
        });
      }, 400);
    };
  } else {
    initialStateOfEditableMemberFields = {
      callSign: '',
      position: '',
      account: undefined,
      team: props.selectedTeam.id,
    } as TeamMemberTypeToSend;
    // Create
    onSubmit = (newMember: TeamMemberTypeToSend, { setSubmitting }: any) => {
      setTimeout(() => {
        if (newMember === null) {
          // If this ever happens, we probably need to use 'useEffect' in some capacity
          alert('new member was null, contact developers');
          setSubmitting(false);
          return;
        }

        // update hardcoded values on the UI.
        // TODO is this the best place to put this?
        // I bet it will get lost...
        // Should it be in the backend?
        newMember.maxSmrImprovementTasks = 10;

        // TODO make this typescript cast have a validation or something
        onOk(newMember, null).then(() => {
          // In case new tags were created
          refetch();
          setSubmitting(false);
        });
      }, 400);
    };
  }

  const MemberFormSchema = Yup.object().shape({
    callSign: Yup.string().required('Required'),
    position: Yup.string().required('Required'),
    account: Yup.number().nullable().required('Required'),
    manager: Yup.number().nullable().optional(),
    team: Yup.number().nullable().required('Required'),
  });

  return (
    // This component causes 'findDOMNode is deprecated in StrictMode' warning
    // Unfortunately, this workaround on SO didn't work https://stackoverflow.com/a/64325602
    // The best way to fix this is to upgrade to react-bootstrap 2.x/bootstrap 5, but that takes some work
    // TODO fix this warning
    <Modal show={state.isOpen} onHide={handleCancel}>
      {
        <Formik
          initialValues={initialStateOfEditableMemberFields}
          validationSchema={MemberFormSchema}
          onSubmit={onSubmit}
        >
          {(formikProps: any) => (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>{getModalTitle(mode)}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {mode === Mode.Add && (
                  <Alert key="add-user-alert" variant="info">
                    Please select the new team member and press OK to start onboarding process.
                    <br />
                    If the user's account isn't showing up, make sure they are in LJI single sign on already.
                  </Alert>
                )}
                <FormikController control="input" name="callSign" label="Call Sign (abbreviation)" />
                <FormikController control="input" name="position" label="Position" />
                <FormikController control="select" name="account" label="Account" options={accountOptions} />
                <FormikController control="select" name="manager" label="Manager" options={accountOptions} />
              </Modal.Body>
              <Modal.Footer>
                {/* Only show delete button on editing */}
                {deleteButton}
                <Button variant="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" disabled={formikProps.isSubmitting}>
                  Ok
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      }
    </Modal>
  );
};

export default ModifyMemberDialog;
export type { ModifyMemberDialogState };
