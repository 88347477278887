import React, { useState } from 'react';
import _ from 'lodash';

const getFormikReadOnlyField = (label: string, value: string) => {
  return (
    <div>
      {label} <br />
      <i>{value}</i> <br /> <br />{' '}
    </div>
  );
};

const useDebouncedInput = (defaultText = '', callbackFunction: (event: any) => void = () => {}, debounceTime = 450) => {
  const [text, setText] = useState(defaultText);
  // Stop react from creating a new debouce function every time this is called
  //  it breaks the debounce
  const debouncedCallback = React.useRef(_.debounce(callbackFunction, debounceTime)).current;

  const setTextDebounced = (event: any) => {
    setText(event.target.value);
    debouncedCallback(event);
  };

  return {
    text,
    setTextDebounced,
    flush: debouncedCallback.flush,
  };
};

export { getFormikReadOnlyField, useDebouncedInput };
