import { createContext } from 'react';
import { FeedbackMessage } from '../components/FeedbackMessages/FeedbackMessages';

type FeedbackMessagesContextType = {
  feedbackMessages: FeedbackMessage[];
  addFeedbackMessage: (feedbackMessage: FeedbackMessage) => void;
};

const dummyFeedbackContext = {
  feedbackMessages: [],
  addFeedbackMessage: (feedbackMessage: any) => {},
} as FeedbackMessagesContextType;

const FeedbackMessagesContext = createContext(dummyFeedbackContext);

export default FeedbackMessagesContext;
