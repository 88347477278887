import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import styles from './TaskGridSummary.module.scss';
import TaskType from '../../../domain/task';
import DefaultTooltip, { getCellId } from '../../DefaultTooltip/DefaultTooltip';
import RecurringTaskScheduleType from '../../../domain/recurringTaskSchedule';
import TriggerRuleType from '../../../domain/triggerRule';
import CoreRecurringTaskScheduleType from '../../../domain/coreRecurringTaskSchedule';
import CoreTriggerRuleType from '../../../domain/coreTriggerRule';

function getTooltipText(params: ICellRendererParams) {
  const task = params.data as
    | TaskType
    | RecurringTaskScheduleType
    | TriggerRuleType
    | CoreRecurringTaskScheduleType
    | CoreTriggerRuleType;
  if ('description' in task && task.description) {
    return params.value + '\n\nDescription: ' + task.description;
  } else if ('descriptionTemplate' in task && task.descriptionTemplate) {
    return params.value + '\n\nDescription Template: ' + task.descriptionTemplate;
  } else if (
    'templateTaskToCreate' in task &&
    task.templateTaskToCreate &&
    task.templateTaskToCreate.descriptionTemplate
  ) {
    return params.value + '\n\nDescription Template: ' + task.templateTaskToCreate.descriptionTemplate;
  } else {
    return params.value;
  }
}

const TaskGridSummary = (params: ICellRendererParams) => {
  const task = params.data as
    | TaskType
    | RecurringTaskScheduleType
    | TriggerRuleType
    | CoreRecurringTaskScheduleType
    | CoreTriggerRuleType;
  let textElem;

  let linkUrl;
  if ('linkUrl' in task && task.linkUrl) {
    linkUrl = task.linkUrl;
  } else if ('linkUrlTemplate' in task && task.linkUrlTemplate) {
    linkUrl = task.linkUrlTemplate;
  } else if ('templateTaskToCreate' in task && task.templateTaskToCreate && task.templateTaskToCreate.linkUrlTemplate) {
    linkUrl = task.templateTaskToCreate.linkUrlTemplate;
  }

  if (linkUrl) {
    textElem = (
      <a href={linkUrl} target="_blank" rel="noreferrer" className={styles.taskSummaryText}>
        {params.value}
      </a>
    );
  } else {
    textElem = <span className={styles.taskSummaryText}>{params.value}</span>;
  }
  return (
    <span style={{ display: 'flex' }}>
      {'isOverdue' in task && task.isOverdue && (
        <DefaultTooltip id={getCellId(params) + '-overdue-tooltip'} tooltipText="This task is overdue">
          <i className="bi bi-exclamation-triangle-fill" style={{ paddingRight: '2px' }} />
        </DefaultTooltip>
      )}
      {textElem}
      <DefaultTooltip id={getCellId(params) + '-info-tooltip'} tooltipText={getTooltipText(params)}>
        <i className={styles.taskSummaryIcon + ' bi bi-info-circle'} />
      </DefaultTooltip>
    </span>
  );
};

export default TaskGridSummary;
