import axios, { AxiosRequestConfig } from 'axios';
import { Col, Container, Row } from 'react-bootstrap';
import { GET_ALL_TEAMS_URL, FIELD_CORE_PROCESS_LEADS_URL, GOAL_SOURCE_FIELDS_URL } from '../constants/urls';
import AccountType from '../domain/account';
import TeamType from '../domain/team';
import GoalSourceFieldType from '../domain/goalSourceField';
import FieldCoreProcessType from '../domain/fieldCoreProcess';
import '../styles.css';
import { moveForward } from './MultipanelDialogue/MultipanelDialogue';
import { FcpSubstepTypePatch } from '../domain/fcpSubstep';

const noParams: AxiosRequestConfig = { params: {} };

export const FCP_TRIGGER_TYPES = {
  GREENLIGHT: 'Greenlight',
  FCP_INST: 'FCP Installation',
  IM: 'Impact Metric',
  NONE: 'None',
};

/**Set all teams, sorted by department and in the order they appear in Sheets.
 *
 * @returns teams and any error message
 */
export async function getSortedTeams(
  setAtTeams: Function,
  setFhTeams: Function,
  setRequestError: Function,
  setCount: Function,
) {
  let responseAtTeams: TeamType[] = [];
  let responseFhTeams: TeamType[] = [];
  let error: string = '';

  axios
    .get(`${GET_ALL_TEAMS_URL}`, noParams)
    .then((response) => {
      //Sort teams by department
      response.data.forEach((team: TeamType) =>
        team.name.includes('Anti-Trafficking') ? responseAtTeams.push(team) : responseFhTeams.push(team),
      );
    })
    .catch((err) => {
      error = err.response.data.detail;
    })
    .finally(() => {
      localStorage.setItem('teams', JSON.stringify(responseAtTeams.concat(responseFhTeams)));
      setAtTeams(responseAtTeams);
      setFhTeams(responseFhTeams);
      setCount(responseAtTeams.concat(responseFhTeams).length);
      if (error) setRequestError(error);
    });
}

/**Set all accounts who can be assigned as
 * a lead, implementation manager, or substep assignee.
 *
 * @returns list of accounts and any error message
 */
export async function getPossibleAssignees(setAssignees: Function, setRequestError: Function, setCount: Function) {
  let assignees: AccountType[] = [];
  let error: string = '';

  axios
    .get(`${FIELD_CORE_PROCESS_LEADS_URL}`, noParams)
    .then((response) => {
      assignees = [];
      response.data.forEach((p: any) => {
        assignees.push({
          id: p.id,
          firstName: p.first_name,
          lastName: p.last_name,
          fullName: p.full_name,
          email: p.email,
          auth0Id: p.auth0_id,
        });
      });
      assignees = assignees as AccountType[];
    })
    .catch((err) => {
      error = err.message;
    })
    .finally(() => {
      localStorage.setItem('people', JSON.stringify(assignees));
      setAssignees(assignees);
      setCount(assignees.length);
      if (error) setRequestError(error);
    });
}

/**Set Goal Source Fields (or impact metrics)
 * for use with FCP triggers.
 *
 * @returns all IM's and any error message
 */
export async function getImpactMetrics(setIms: Function, setRequestError: Function, setCount: Function) {
  let error: string = '';
  let metrics: GoalSourceFieldType[] = [];

  axios
    .get(`${GOAL_SOURCE_FIELDS_URL}`, noParams)
    .then((response) => {
      metrics = response.data as GoalSourceFieldType[];
    })
    .catch((err) => {
      error = err.response.data.detail;
    })
    .finally(() => {
      localStorage.setItem('ims', JSON.stringify(metrics));
      setIms(metrics);
      setCount(metrics.length);
      if (error) setRequestError(error);
    });
}

/**Set a trigger for an FCP.
 *
 * @param fcpId the FCP id
 * @param params the trigger params (see /trigger under the fcp views)
 */
export function setFcpTrigger(fcpId: number, params: Object) {
  axios.put(`${'field-core-processes/' + fcpId + '/trigger'}`, params);
}

/**Used by many different select dropdowns to make JSX for
 * the associated list of objects.
 *
 * @param list the list of objects that the select dropdown will use
 * @returns JSX to be put *inside* a select tag set.
 */
export function makeSelectJsx(list: any[]) {
  const jsx = [];

  for (let i = 0; i < list.length; i++) {
    jsx.push(
      <option key={i} value={parseInt(list[i].id)}>
        {list[i].name.length > 50 ? list[i].name.substring(0, 49).concat('...') : list[i].name}
      </option>,
    );
  }

  return jsx;
}

/**Used by many different select dropdowns to make JSX for
 * the associated list of objects.
 *
 * @param list the list of objects that the select dropdown will use
 * @returns JSX to be put *inside* a select tag set.
 */
export function makeSelectJsxWithEmptyStart(list: any[]) {
  const jsx = [<option key={-1} value={-1}></option>];

  for (let i = 0; i < list.length; i++) {
    jsx.push(
      <option key={i} value={parseInt(list[i].id)}>
        {list[i].name.length > 50 ? list[i].name.substring(0, 49).concat('...') : list[i].name}
      </option>,
    );
  }

  return jsx;
}

/**A special dropdown for categories
 *
 * @param list the list of cats that the select dropdown will use
 * @returns JSX
 */
export function makeCatJSX(list: string[]) {
  let jsx: JSX.Element[] = [];
  list.forEach((item, index) =>
    jsx.push(
      <option key={index} value={item}>
        {item}
      </option>,
    ),
  );
  return jsx;
}

/**Get the name property of an object with a corresponding id
 * from a list of objects with both id and name properties.
 * This is used a lot in trigger code and new fcp code.
 *
 * @param id the id
 * @param list the list of objects
 * @returns the name as a string
 */
export function findNameById(id: number, list: any[]) {
  for (let i = 0; i < list.length; i++) if (list[i].id === id) return list[i].name;

  return 'Error!';
}

/**Get the name of an assignee, which uses first and last name properties.
 *
 * @param id the id
 * @param list the list of assignees
 * @returns the name as a string
 */
export function findAssigneeNameById(id: number, list: AccountType[]) {
  for (let i = 0; i < list.length; i++) if (list[i].id === id) return list[i].firstName + ' ' + list[i].lastName ?? '';

  return 'Error!';
}

/**Set a name if it is valid. To be valid (for fcp, substep and category) it must be:
 * 1. Non-empty
 * 2. Made of only numbers, letters and parenthetical marks
 * 3. New against a list of existing names (except for category)
 *
 * @param name the suggested name
 * @param list the list of FCP's to check for duplicate names
 * @param setName the setState function callback for components to set their fcp name
 */
export function setNameIfValid(name: string, list: any[], mustBeNew: boolean, setName: Function) {
  const nameRe = new RegExp('^[0-9a-zA-Z ()]*$');
  let alreadyExists = false;

  if (mustBeNew) for (let i = 0; i < list.length; i++) if (list[i].name === name) alreadyExists = true;

  if (nameRe.test(name) && !alreadyExists) setName(name);
}

// type TriggerParams = {
//   trigger: any;
//   type: string;
//   numDays?: number;
//   department: string;
//   firingFcpOrMetricId: number | undefined;
//   thresh?: number;
// };

/**Make params for a trigger request and make a trigger object for state
 *
 * @param triggerParams params
 * @returns the request params
 */
// export function makeTriggerAndParams(triggerParams: TriggerParams) {
//   if (!triggerParams.firingFcpOrMetricId) {
//     throw Error('No firingFcpOrMetricId');
//   }
//   let intNumDays = triggerParams.numDays ?? 0;
//   let intThresh = triggerParams.thresh ?? 0;
//
//   let params = {};
//   let newTrigger = triggerParams.trigger ?? {};
//
//   newTrigger.type = triggerParams.type;
//
//   //Greenlight's are when the team is marked active
//   if (triggerParams.type === triggerTypes.GREENLIGHT) {
//     params = {
//       type: triggerParams.type,
//       num_days: intNumDays,
//     };
//     newTrigger.name = triggerParams.department;
//     newTrigger.numDaysBeforeDue = intNumDays;
//   }
//   //The installation of another FCP (of same team) is the trigger
//   else if (triggerParams.type === triggerTypes.FCP_INST) {
//     params = {
//       type: triggerParams.type,
//       firing_fcp: triggerParams.firingFcpOrMetricId,
//       num_days: intNumDays,
//     };
//     newTrigger.name = findNameById(triggerParams.firingFcpOrMetricId, triggerParams.fcps);
//     newTrigger.prereqFcp = triggerParams.firingFcpOrMetricId;
//     newTrigger.numDaysBeforeDue = intNumDays;
//   }
//   //An IM reaching a certain thresh is the trigger
//   else if (triggerParams.type === triggerTypes.IM) {
//     params = {
//       type: triggerParams.type,
//       impact_metric: triggerParams.firingFcpOrMetricId,
//       im_thresh: intThresh,
//       num_days: intNumDays,
//     };
//     newTrigger.name = findNameById(triggerParams.firingFcpOrMetricId, triggerParams.fcps);
//     newTrigger.impactMetric = triggerParams.firingFcpOrMetricId;
//     newTrigger.impactMetricThreshold = intThresh;
//     newTrigger.numDaysBeforeDue = intNumDays;
//   }
//   //No trigger
//   else {
//     params = {
//       type: triggerParams.type,
//     };
//     newTrigger = {};
//   }
//
//   return { newTrigger, params };
// }

/**Return the grammatically correct word for a certain
 * number of days.
 *
 * @param number the number of days
 * @returns day if 1 else days
 */
export function dayOrDays(number: number) {
  return number !== 1 ? ' days ' : ' day ';
}

/**Open/Close the modal window for new fcp and trigger components.
 * Set section to 1, the start number for whatever section system used.
 *
 * @param isModalOpen true if open
 * @param startPage the desired page to start at again
 * @param setIsModalOpen setState to open/close
 * @param setSection setState for page Modal is on
 */
export function openCloseModal(
  isModalOpen: boolean,
  startPage: number,
  setIsModalOpen: Function,
  setSection: Function,
) {
  setIsModalOpen(!isModalOpen);
  setSection(startPage);
}

/**The back button logic for modal windows.
 * History is an array where numbers corresponding to pages of
 * the modal are inserted based on when you visited. The first
 * page visited is the first number added, and last added is most recent.
 * Moving backward is a matter of moving to the latest number (which is
 * the very last page before the current page), and popping it.
 *
 * @param history the history number array
 * @param setSection setSate of modal's current page
 * @param setHistory setState for history (minus one number)
 */
export function moveBack(history: number[], setSection: Function, setHistory: Function) {
  setSection(history[history.length - 1]);
  setHistory(history.slice(0, history.length - 1));
}

/**Called on choosing a trigger type to set the type.
 *
 * @param type new type
 * @param list for checking FCP and IM id's if applicable
 * @param setTriggeringObjectId setState
 * @param setNewTriggerType setState
 */
export function onChooseTriggerType(
  type: string,
  list: any[],
  setTriggeringObjectId: Function,
  setNewTriggerType: Function,
) {
  if (type === FCP_TRIGGER_TYPES.FCP_INST) setTriggeringObjectId(list[0].id);
  if (type === FCP_TRIGGER_TYPES.IM) setTriggeringObjectId(list[0].id);

  setNewTriggerType(type);
}

/*******
 * JSX *
 ******/
//Done page for many Modals
export const sectionDone = (
  <Container fluid className="d-flex flex-column">
    <Row className="d-flex align-items-start justify-content-center modal-section-header">Done!</Row>
    <Row className="d-flex align-items-start justify-content-center p-3">You can close this window now.</Row>
  </Container>
);

/**Return JSX for a choose number of days option for triggers.
 *
 * @param numDays number in state
 * @param setNewTriggerNumDays useState
 * @param history history of visited pages of Modal
 * @param currSection current page on Modal
 * @param newSection page to move to in Modal
 * @param setSection useState
 * @param setHistory useState
 * @returns JSX
 */
export function getSectionChooseNumDays(
  numDays: number | undefined,
  setNewTriggerNumDays: Function,
  history: number[],
  currSection: number,
  newSection: number,
  setSection: Function,
  setHistory: Function,
) {
  return (
    <Container fluid className="d-flex flex-column">
      <Row className="d-flex align-items-start justify-content-center modal-section-header">
        Choose the number of days after the trigger fires to be the due date:
      </Row>

      <Row className="d-flex align-items-start justify-content-center p-3">
        <Col className="d-flex align-items-center justify-content-center">
          {/* This is lazy to orient them. TODO @Josh use the react grid scheme */}
        </Col>
        <Col className="d-flex align-items-center justify-content-center m-auto">
          <input
            type="text"
            pattern="[0-9]*"
            value={numDays}
            onInput={(e) => {
              setNewTriggerNumDays(parseInt((e.target as HTMLTextAreaElement).value));
            }}
          />
        </Col>
        <Col className="d-flex align-items-start justify-content-start m-auto">
          <button
            onClick={() => moveForward(history, currSection, newSection, setSection, setHistory)}
            className="blue-button"
          >
            Continue
          </button>
        </Col>
      </Row>
    </Container>
  );
}

//Page system for Modal windows
export const pages = {
  chooseOrMakeFcp: 1,
  chooseSubsteps: 2,
  chooseLeadImp: 3,
  chooseTriggerType: 4,
  chooseTriggerFcpOrIm: 5,
  chooseTriggerNumDays: 6,
  summary: 7,
  done: 8,
};

/**Returns JSX for a choose type option for triggers
 *
 * @param setTriggeringObjectId the id of the object firing a trigger
 * @param setNewTriggerType trigger type
 * @param history history of visited pages of Modal
 * @param section current Modal page
 * @param setSection useState
 * @param setHistory useState
 * @param fcps fcp list
 * @param ims ims list
 * @returns JSX
 */
export function getSectionChooseTriggerType(
  setTriggeringObjectId: Function,
  setNewTriggerType: Function,
  history: number[],
  section: number,
  setSection: Function,
  setHistory: Function,
  fcps: FieldCoreProcessType[],
  ims: GoalSourceFieldType[],
) {
  return (
    <Container fluid className="d-flex flex-column">
      <Row className="d-flex align-items-start justify-content-center modal-section-header">Select a trigger type</Row>
      <Row className="d-flex align-items-start justify-content-center p-3">
        <Col className="d-flex align-items-center justify-content-center">
          <button
            onClick={() => {
              onChooseTriggerType(FCP_TRIGGER_TYPES.GREENLIGHT, [], setTriggeringObjectId, setNewTriggerType);
              moveForward(history, section, pages.chooseTriggerNumDays, setSection, setHistory);
            }}
            className="blue-button"
          >
            Greenlight
          </button>
        </Col>
        <Col className="d-flex align-items-center justify-content-center">
          <button
            onClick={() => {
              onChooseTriggerType(FCP_TRIGGER_TYPES.FCP_INST, fcps, setTriggeringObjectId, setNewTriggerType);
              moveForward(history, section, pages.chooseTriggerFcpOrIm, setSection, setHistory);
            }}
            className="blue-button"
          >
            FCP Installation
          </button>
        </Col>
        <Col className="d-flex align-items-center justify-content-center">
          <button
            onClick={() => {
              onChooseTriggerType(FCP_TRIGGER_TYPES.IM, ims, setTriggeringObjectId, setNewTriggerType);
              moveForward(history, section, pages.chooseTriggerFcpOrIm, setSection, setHistory);
            }}
            className="blue-button"
          >
            Impact Metric
          </button>
        </Col>
        <Col className="d-flex align-items-center justify-content-center">
          <button
            onClick={() => {
              onChooseTriggerType(FCP_TRIGGER_TYPES.NONE, [], setTriggeringObjectId, setNewTriggerType);
              moveForward(history, section, pages.summary, setSection, setHistory);
            }}
            className="blue-button"
          >
            None
          </button>
        </Col>
      </Row>
    </Container>
  );
}

/**Return JSX for a choose triggering FCP or IM option for triggers.
 *
 * @param type trigger type
 * @param history history of visited pages of Modal
 * @param section current page on Modal
 * @param fcps_jsx JSX of fcp dropdown
 * @param ims_jsx JSX of ims dropdown
 * @param triggeringObjectId id of object that fires trigger
 * @param setTriggeringObjectId useState
 * @param newTriggerThreshold thresh of an im trigger
 * @param setNewTriggerThreshold useState
 * @param setSection useState
 * @param setHistory useState
 * @returns JSX
 */
export function getSectionChooseTriggerFcpOrIm(
  type: string,
  history: number[],
  section: number,
  fcps_jsx: JSX.Element[],
  ims_jsx: JSX.Element[],
  triggeringObjectId: number | undefined,
  setTriggeringObjectId: Function,
  newTriggerThreshold: number | undefined,
  setNewTriggerThreshold: Function,
  setSection: Function,
  setHistory: Function,
) {
  let title;
  let selectOptionsJsx;
  if (type === FCP_TRIGGER_TYPES.FCP_INST) {
    title = 'Select an FCP whose installation is the trigger:';
    selectOptionsJsx = fcps_jsx;
  } else if (type === FCP_TRIGGER_TYPES.IM) {
    title = 'Select an Impact Metric:';
    selectOptionsJsx = ims_jsx;
  } else {
    // TODO this is valid but why?
    // throw Error('Invalid type in getSectionChooseTriggerFcpOrIm');
  }

  return (
    <Container fluid className="d-flex flex-column">
      <Row className="d-flex align-items-start justify-content-center modal-section-header">{title}</Row>
      <Row className="d-flex align-items-start justify-content-center p-3">
        <Col className="d-flex align-items-center justify-content-center">
          {/* This is lazy to orient them. TODO @Josh use the react grid scheme */}
        </Col>
        <Col className="d-flex align-items-center justify-content-center m-auto">
          <select value={triggeringObjectId} onChange={(e) => setTriggeringObjectId(parseInt(e.target.value))}>
            {selectOptionsJsx}
          </select>
        </Col>
        <Col className="d-flex align-items-start justify-content-start">
          <button
            onClick={() => moveForward(history, section, pages.chooseTriggerNumDays, setSection, setHistory)}
            className="blue-button"
          >
            Continue
          </button>
        </Col>
      </Row>

      {type === FCP_TRIGGER_TYPES.IM && (
        <Row className="d-flex align-items-start justify-content-center modal-section-header">
          Select a threshold the metric must pass:
        </Row>
      )}
      {type === FCP_TRIGGER_TYPES.IM && (
        <Row className="d-flex align-items-start justify-content-center p-3">
          <Col className="d-flex align-items-center justify-content-center">
            {/* This is lazy to orient them. TODO @Josh use the react grid scheme */}
          </Col>
          <Col className="d-flex align-items-center justify-content-center m-auto">
            <input
              type="text"
              pattern="[0-9]*"
              value={newTriggerThreshold}
              onInput={(e) => {
                setNewTriggerThreshold(parseInt((e.target as HTMLTextAreaElement).value) || 0);
              }}
            />
          </Col>
          <Col className="d-flex align-items-center justify-content-center">
            {/* This is lazy to orient them. TODO @Josh use the react grid scheme */}
          </Col>
        </Row>
      )}
    </Container>
  );
}

type TriggerSummaryParams = {
  type: string;
  dept: string;
  name: string;
  numDays?: number;
  thresh?: number;
  triggeringObjectId: number | undefined;
  fcps: FieldCoreProcessType[];
  addingFcp: boolean;
};

/**Get JSX of a trigger setting summary
 *
 * @param params params
 * @returns JSX
 */
export function getTriggerSummary(params: TriggerSummaryParams) {
  const intNumDays = params.numDays ?? 0;
  const intThresh = params.thresh ?? 0;
  const noTrigger = params.addingFcp ? 'None' : 'Existing trigger (if any) will be removed.';

  let triggeringName = '';
  if (params.type === FCP_TRIGGER_TYPES.FCP_INST || params.type === FCP_TRIGGER_TYPES.IM) {
    if (!params.triggeringObjectId) {
      throw Error('No triggeringObjectId');
    } else {
      triggeringName = findNameById(params.triggeringObjectId, params.fcps);
    }
  }
  return (
    <Col
      className={'d-flex align-items-start p-3' + params.addingFcp ? 'justify-content-start' : 'justify-content-center'}
      style={{ textAlign: params.addingFcp ? 'left' : 'center' }}
    >
      {params.type === FCP_TRIGGER_TYPES.GREENLIGHT &&
        'Trigger will be the ' +
          params.type +
          ' of the ' +
          params.dept +
          ' department with a due date of ' +
          intNumDays +
          dayOrDays(intNumDays) +
          'after the trigger fires.'}
      {params.type === FCP_TRIGGER_TYPES.FCP_INST &&
        'Trigger will be an ' +
          params.type +
          '. ' +
          triggeringName +
          "'s installation will trigger " +
          params.name +
          ' with a due date of ' +
          intNumDays +
          dayOrDays(intNumDays) +
          'after the trigger fires.'}
      {params.type === FCP_TRIGGER_TYPES.IM &&
        'Trigger will be an ' +
          params.type +
          '. When ' +
          triggeringName +
          ' reaches ' +
          intThresh +
          ' it will set off the trigger with a due date of ' +
          intNumDays +
          dayOrDays(intNumDays) +
          'after the trigger fires.'}
      {params.type === FCP_TRIGGER_TYPES.NONE && noTrigger}
    </Col>
  );
}

//JSX for no substeps (used in Modals that display a substeps configure section)
export const noSubstepsJsx = (
  <Row className="d-flex align-items-start justify-content-center">
    <Col className="d-flex align-items-center justify-content-end">Substeps:</Col>
    <Col className="d-flex align-items-center justify-content-start">None</Col>
  </Row>
);

/**Get a summary JSX view of substeps (used for configuring substeps sections)
 *
 * @param name name of substep
 * @param order order of substep
 * @returns JSX
 */
export const getSummarySubstepListingJsx = (name: string, order: number) => {
  return (
    <Row className="d-flex align-items-start justify-content-center">
      <Col className="d-flex align-items-center justify-content-end">{order === 1 && 'Substeps:'}</Col>
      <Col className="d-flex align-items-center justify-content-start">
        {order}. {name.length > 40 ? name.substring(0, 39).concat('...') : name}
      </Col>
    </Row>
  );
};

/*****************
 * SUBSTEPS CODE *
 ****************/

/**Actions when user adds a substep to a list of substeps
 * Used in addSubstep and addFcp use cases
 *
 * @param currSubsteps current substeps (of an fcp)
 * @param selectedBrandNewSubstep a selected new substep
 * @param setNewSubsteps useState
 * @param setNewSubstepsJsx useState
 * @param setNewSubstepsSummaryJsx useState
 * @param deletable show trash can button or not (deleting unsupported for existing fcp's substeps)
 */
export const onChooseSubstep = (
  currSubsteps: FcpSubstepTypePatch[],
  selectedBrandNewSubstep: string,
  setNewSubsteps: Function,
  setNewSubstepsJsx: Function,
  setNewSubstepsSummaryJsx: Function,
  deletable: boolean,
) => {
  //Get the name and ID and add to list
  let subId = 0; //value never used but shuts up TS without making uglier code
  let newSubName;
  //order can be derived like so because it is inserted at the bottom
  //and orders are guaranteed to increment by 1 for each new sub.
  const order = currSubsteps.length + 1;

  let notAlreadyIn = true;

  newSubName = selectedBrandNewSubstep;
  //No ids exist for these, yet. Stary by making the first one -1,
  //Then decrement for each new one. Look out for duplicates
  //at this time, otherwise you can make the same substep with
  //another id.
  let lowestId = Infinity;
  for (let i = 0; i < currSubsteps.length; i++) {
    if (currSubsteps[i].name === newSubName) notAlreadyIn = false;
    if (currSubsteps[i].id < lowestId) lowestId = currSubsteps[i].id;
  }

  if (lowestId > -1) subId = -1;
  else subId = lowestId - 1;

  //Prevent duplicates
  for (let i = 0; i < currSubsteps.length; i++) if (currSubsteps[i].id === subId) notAlreadyIn = false;

  if (notAlreadyIn) {
    currSubsteps.push({ name: newSubName, id: subId, order: order });
    setNewSubsteps(currSubsteps);
    resetSubstepsDisplay(currSubsteps, setNewSubstepsJsx, setNewSubstepsSummaryJsx, deletable);
  }
};

/**Used by addSubstep and addFcp to show the substeps configure section and handle changes.
 *
 * @param currSubsteps current substeps (of fcp)
 * @param setNewSubstepsJsx JSX for configure section
 * @param setNewSubstepsSummaryJsx JSX for summary view
 * @param deletable show trash can button or not
 */
export const resetSubstepsDisplay = (
  currSubsteps: FcpSubstepTypePatch[],
  setNewSubstepsJsx: Function,
  setNewSubstepsSummaryJsx: Function,
  deletable: boolean,
) => {
  let editingSubsJsx: JSX.Element[] = [];
  let summarySubJsx: JSX.Element[] = [];
  currSubsteps.forEach((sub, index) => {
    editingSubsJsx.push(
      getEditingSubstepListingJsx(
        currSubsteps,
        sub.name,
        sub.id,
        index + 1,
        setNewSubstepsJsx,
        setNewSubstepsSummaryJsx,
        deletable,
      ),
    );
  });
  currSubsteps.forEach((sub) => {
    summarySubJsx.push(getSummarySubstepListingJsx(sub.name, sub.order));
  });
  setNewSubstepsJsx(editingSubsJsx);
  setNewSubstepsSummaryJsx(summarySubJsx);
};

/**Get JSX for a specific substep in configure sections
 *
 * @param currentSubsteps current substeps
 * @param name name of substep
 * @param id id of substep
 * @param order order of substep
 * @param setNewSubstepsJsx useState
 * @param setNewSubstepsSummaryJsx useState
 * @param deletable show trash can button or not
 * @returns JSX
 */
export const getEditingSubstepListingJsx = (
  currentSubsteps: FcpSubstepTypePatch[],
  name: string,
  id: number,
  order: number,
  setNewSubstepsJsx: Function,
  setNewSubstepsSummaryJsx: Function,
  deletable: boolean,
) => {
  return (
    <Row
      className="d-flex align-items-end justify-content-center p-3"
      style={{ paddingTop: order === 1 ? '10px' : '' }}
    >
      <Col className="d-flex" lg={3}></Col>
      <Col className="d-flex align-items-start justify-content-start" lg={3}>
        {order}. {name.length > 40 ? name.substring(0, 39).concat('...') : name}
      </Col>
      <Col className="d-flex align-items-start justify-content-start" lg={1.1}>
        <Row className="d-flex align-items-start justify-content-start">
          <Col className="d-flex pr-1">
            <button
              onClick={() => {
                moveSubstep(currentSubsteps, id, 'Up', setNewSubstepsJsx, setNewSubstepsSummaryJsx, deletable);
              }}
              className="blue-button"
            >
              <div>↑</div>
            </button>
          </Col>
          <Col className="d-flex pr-1">
            <button
              onClick={() => {
                moveSubstep(currentSubsteps, id, 'Down', setNewSubstepsJsx, setNewSubstepsSummaryJsx, deletable);
              }}
              className="blue-button"
            >
              <div>↓</div>
            </button>
          </Col>
          {deletable && (
            <Col className="d-flex pr-1">
              <button
                onClick={() => {
                  moveSubstep(currentSubsteps, id, 'Out', setNewSubstepsJsx, setNewSubstepsSummaryJsx, deletable);
                }}
                className="red-button"
              >
                <div className="icon-trash float-start">
                  <div className="trash-lid"></div>
                  <div className="trash-container"></div>
                  <div className="trash-line-1"></div>
                  <div className="trash-line-2"></div>
                  <div className="trash-line-3"></div>
                </div>
              </button>
            </Col>
          )}
        </Row>
      </Col>
      <Col className="d-flex" lg={3}></Col>
    </Row>
  );
};

/**Handle moving a substep in configure section
 *
 * @param currSubsteps current substeps
 * @param id id of substep
 * @param dir directon: up, down, or out (if allowed)
 * @param setNewSubstepsJsx useState
 * @param setNewSubstepsSummaryJsx useState
 * @param deletable show trash can button or not
 */
export function moveSubstep(
  currSubsteps: FcpSubstepTypePatch[],
  id: number,
  dir: string,
  setNewSubstepsJsx: Function,
  setNewSubstepsSummaryJsx: Function,
  deletable: boolean,
) {
  let location = -1; //Never used, here to make TS shut up

  for (let i = 0; i < currSubsteps.length; i++)
    if (currSubsteps[i].id === id) {
      location = i;
    }

  //Can't shift up if it's at the top
  if (dir === 'Up' && location > 0) {
    //Substep above increases in order now
    currSubsteps[location - 1].order += 1;
    //Curr substep needs to decrease order
    currSubsteps[location].order -= 1;

    //Swap substeps
    const tmp = currSubsteps[location - 1];
    currSubsteps[location - 1] = currSubsteps[location];
    currSubsteps[location] = tmp;
  }

  //Can't shift down if it's at the bottom
  if (dir === 'Down' && location < currSubsteps.length - 1) {
    //Substep below decreases in order now
    currSubsteps[location + 1].order -= 1;
    //Curr substep needs to increase order
    currSubsteps[location].order += 1;

    //Swap substeps
    const tmp = currSubsteps[location + 1];
    currSubsteps[location + 1] = currSubsteps[location];
    currSubsteps[location] = tmp;
  }

  if (dir === 'Out') {
    //Starting with the one removed, replace location with the
    //Sub after it. Then delete the substep at the end.
    //Orders are handled in the request code for add fcp
    //(the only use case with permission to remove a substep from the list).
    for (let i = location; i < currSubsteps.length - 1; i++) {
      currSubsteps[i] = currSubsteps[i + 1];
    }
    currSubsteps.pop();
  }

  resetSubstepsDisplay(currSubsteps, setNewSubstepsJsx, setNewSubstepsSummaryJsx, deletable);
}

/**Get the mammoth JSX that is the Modal page for choosing substeps.
 *
 * @param newSubsteps current substeps
 * @param history page history of Modal
 * @param section current page of Modal
 * @param nextSection the page to go to when done
 * @param newSubstepsJsx JSX for configure section
 * @param subs_jsx subs JSX for dropdown
 * @param selectedBrandNewSubstep id of selected new substep
 * @param deletable show trash can icon or not
 * @param setSection useState
 * @param setHistory useState
 * @param setNewSubsteps useState
 * @param setNewSubstepsJsx useState
 * @param setNewSubstepsSummaryJsx useState
 * @param setSelectedBrandNewSubstep useState
 * @returns JSX
 */
export function getSectionChooseSubsteps(
  newSubsteps: FcpSubstepTypePatch[],
  history: number[],
  section: number,
  nextSection: number,
  newSubstepsJsx: JSX.Element | undefined,
  selectedBrandNewSubstep: string,
  deletable: boolean,
  setSection: Function,
  setHistory: Function,
  setNewSubsteps: Function,
  setNewSubstepsJsx: Function,
  setNewSubstepsSummaryJsx: Function,
  setSelectedBrandNewSubstep: Function,
) {
  return (
    <Container fluid className="d-flex flex-column">
      <Row className="d-flex align-items-start justify-content-center modal-section-header">Add substeps</Row>
      <Row className="d-flex align-items-center justify-content-center">
        <Col className="d-flex align-items-center justify-content-end">
          <input
            type="text"
            value={selectedBrandNewSubstep}
            onInput={(e) => {
              setNameIfValid((e.target as HTMLTextAreaElement).value, [], false, setSelectedBrandNewSubstep);
            }}
          />
        </Col>
        <Col className="d-flex align-items-center justify-content-start">
          <button
            onClick={() =>
              onChooseSubstep(
                newSubsteps,
                selectedBrandNewSubstep,
                setNewSubsteps,
                setNewSubstepsJsx,
                setNewSubstepsSummaryJsx,
                deletable,
              )
            }
            className={selectedBrandNewSubstep ? 'blue-button' : 'gray-button disabled-button'}
          >
            Add
          </button>
        </Col>
      </Row>
      <Row className="d-flex align-items-center justify-content-center p-3 black-bottom-border">
        <Col className="d-flex align-items-center justify-content-center">
          <button
            onClick={() => moveForward(history, section, nextSection, setSection, setHistory)}
            className={newSubsteps.length ? 'blue-button' : 'gray-button disabled-button'}
          >
            Continue
          </button>
        </Col>
      </Row>
      {newSubstepsJsx}
    </Container>
  );
}

/****************
 * GRID HELPERS *
 ***************/

/**Substep installations: reformat date from MM/DD/YYYY to
 * YYYY-MM-DD to transform a date string into an acceptable
 * value for the date input field.
 *
 * @param dateStr incoming date string
 * @returns formatted string
 */
export const reformatDate = (dateStr: string) => {
  if (dateStr) {
    const dateArray = dateStr.split('/'); // ex input: "01/10/2023"
    let month = dateArray[0];
    let day = dateArray[1];
    let year = dateArray[2];

    // Add missing digits so that each month has 2 digits
    // and each day has 2 digits for the date picker
    if (month.length === 1) month = '0' + month;
    if (day.length === 1) day = '0' + day;

    return year + '-' + month + '-' + day; // ex output: "2010-01-18"
  }
  return '';
};

export type AssigneeWithColor = AccountType & {
  color: string;
};

/**Substep installations:
 * This function determines the background color of the assignee dropdown based
 * on the value of the assignee state.
 *
 * @returns color (unique assignee color, yellow, or white)
 */
export const getAssigneeBackgroundColor = (
  assigneeId: number,
  installationDate: string,
  assignees: AssigneeWithColor[],
) => {
  if (assigneeId && assigneeId !== -1) {
    if (installationDate) {
      // If substep is assigned and completed, show unique color for assignee.
      return getAssigneeDropdownBgColor(
        assignees.find((assignee) => {
          return assignee.id === assigneeId;
        })?.fullName!,
      );
    }
    // If substep is assigned but not completed, the color is set to yellow.
    return 'yellow';
  }
  // If substep is not assigned yet, set background color to white
  return 'white';
};

/**Substep installations: This function determines the text color
 * of the assignee dropdown based on the value of the assignee state.
 *
 * @returns color (white or black)
 */
export const getAssigneeTextColor = (assigneeId: number, installationDate: string) => {
  if (assigneeId && assigneeId !== -1 && installationDate) {
    // If substep is assigned and completed, set text color to white.
    return 'white';
  }
  // If substep is not assigned or it is assigned but not completed,
  // set text color to black.
  return 'black';
};

/**Substep installations assignee dropdown:
 * This function generates a unique color using the character codes
 * of the first and last initial of a user's name.
 *
 * @param assigneeName a user's full name, ex: "Brad Wells"
 * @returns a color hex code
 */
export const getAssigneeDropdownBgColor = (assigneeName: string) => {
  // Get number representation of assigneeName
  const nameArr = assigneeName.split(' ');
  const firstLetterCode = nameArr[0].charCodeAt(0).toString();
  const secondLetterCode = nameArr[1]?.charCodeAt(0)?.toString() ?? 'u'; //No last name
  const strNum =
    firstLetterCode + firstLetterCode + firstLetterCode + secondLetterCode + secondLetterCode + secondLetterCode;

  // Calculate hex value from strNum
  const colorValue = (parseInt(strNum) * 0xfffff * 0xaabbcc).toString(16);

  // Get first 6 numbers of colorValue
  return '#' + colorValue.slice(2, 8);
};

export const getAssigneesWithColor = (incomingAssignees: AccountType[]) => {
  let assignees: AssigneeWithColor[] = [];

  incomingAssignees.forEach((assignee) => {
    assignees.push({
      fullName: assignee.fullName,
      firstName: assignee.firstName,
      lastName: assignee.lastName,
      auth0Id: assignee.auth0Id,
      id: assignee.id,
      email: assignee.email,
      color: getAssigneeDropdownBgColor(assignee.fullName),
    });
  });

  return assignees;
};

export const getAssigneeInitials = (assignee: AccountType) => {
  return assignee.firstName.substring(0, 1) + assignee.lastName?.substring(0, 1) ?? '';
};

/**For substep installations, setting the assignee with dropdown
 *
 * @param assignees The list of assignees
 * @returns Dropdown JSX
 */
export const getAssigneeDropdownJsx = (assignees: AccountType[]) => {
  const assigneeJsx = [<option key={-1} value={-1}></option>];
  // Add initials for each assignee as options to the assignee dropdown
  assignees.forEach((assignee, index) => {
    assigneeJsx.push(
      <option key={index} value={assignee.id}>
        {' '}
        {getAssigneeInitials(assignee)}{' '}
      </option>,
    );
  });
  return assigneeJsx;
};

/**Get a dropdown with fullnames instead of initials
 *
 * @param assignees The list of assignees
 * @returns Dropdown JSX
 */
export const getAssigneeFullNameDropdownJsx = (assignees: AccountType[]) => {
  const assigneeJsx = [<option key={-1} value={-1}></option>];
  // Add initials for each assignee as options to the assignee dropdown
  assignees.forEach((assignee, index) => {
    // TODO ??"" in the next line is weird
    assigneeJsx.push(
      <option key={index} value={assignee.id}>
        {' '}
        {assignee.firstName + ' ' + assignee.lastName ?? ''}{' '}
      </option>,
    );
  });
  return assigneeJsx;
};
