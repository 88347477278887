import axios, { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import { FIELD_CORE_PROCESS_CATEGORIES_URL } from '../../../constants/urls';

type FcpCategory = {
  name: string;
};

// TODO should these be by department? We will need to add department to the model
function useGetFcpCategories(enabled: boolean = true) {
  const queryOptions = {
    params: {},
  } as AxiosRequestConfig;
  return useQuery(
    ['useGetFcpCategories', queryOptions],
    async () => {
      const response = await axios.get<FcpCategory[]>(FIELD_CORE_PROCESS_CATEGORIES_URL, queryOptions);
      return response.data;
    },
    {
      enabled: enabled,
    },
  );
}

export { useGetFcpCategories };
