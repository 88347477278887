import { useContext } from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import MemberTabs from '../../components/members/MemberTabs/MemberTabs';
import MonthlyScores from '../../components/scores/TeamScoreHistoryGrid/MonthlyScores/MonthlyScores';
import LoggedInTeamMemberContext from '../../contexts/LoggedInTeamMemberContext';
import { SelectedTeamMemberContext } from '../../contexts/SelectedTeamMemberContext';
import TeamType from '../../domain/team';
import { isTeamMemberSelectedAndIsOnThisTeam } from '../../helpers/PageHelper';

type MemberScoresProps = {
  selectedTeam: TeamType;
};

const MemberScores = (props: MemberScoresProps) => {
  const selectedTeamMemberContext = useContext(SelectedTeamMemberContext);

  return (
    <Container fluid>
      <Row>
        <Col>
          <h4 className="text-center font-weight-bold">Member Scores</h4>
        </Col>
      </Row>
      <Row>
        <Col className="px-0">
          <LoggedInTeamMemberContext.Consumer>
            {(loggedInTeamMember) => (
              <MemberTabs selectedTeam={props.selectedTeam} loggedInTeamMember={loggedInTeamMember} />
            )}
          </LoggedInTeamMemberContext.Consumer>
        </Col>
      </Row>
      {/* If selectedTeamMember is not null, render this */}
      {!isTeamMemberSelectedAndIsOnThisTeam(selectedTeamMemberContext, props.selectedTeam) && (
        <Row>
          <Col>
            <Alert variant="light">Please select a team member</Alert>
          </Col>
        </Row>
      )}
      {/* If selectedTeamMember is not null, render the below */}
      {selectedTeamMemberContext?.selectedTeamMember !== null &&
        isTeamMemberSelectedAndIsOnThisTeam(selectedTeamMemberContext, props.selectedTeam) && (
          <MonthlyScores
            selectedTeamMember={selectedTeamMemberContext?.selectedTeamMember}
            selectedTeam={props.selectedTeam}
          />
        )}
    </Container>
  );
};

export default MemberScores;
