import axios, { AxiosRequestConfig } from 'axios';
import { Form, Formik } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import { useQuery } from 'react-query';
import * as Yup from 'yup';
import { EVENT_TYPES_URL, GET_TAGS_URL, TEAM_MEMBERS_URL, TRIGGER_RULE_URL } from '../../../constants/urls';
import TriggerRuleType, { TriggerRuleTypeToSend } from '../../../domain/triggerRule';
import TagType from '../../../domain/tag';
import TeamType from '../../../domain/team';
import TeamMemberType from '../../../domain/teamMember';
import FormikController from '../../../formik/FormikSelect/FormikController';
import { Mode } from '../../../state_types/mode';
import EventTypeType from '../../../domain/eventType';
import { getNameOfCreationUser } from '../../../helpers/AuditFieldHelper';
import { createFeedbackMessageKey, FeedbackMessage } from '../../FeedbackMessages/FeedbackMessages';
import { getSimpleModal, ModifyDialogState } from '../../../helpers/GridComponentHelpers';
import { getFormikReadOnlyField } from '../../../formik/FormikHelpers';
import { getCallSignWithPositionAndName } from '../../../helpers/TeamMembershipHelpers';

export interface ModifyTriggerRuleDialogProps {
  selectedTeam: TeamType;
  state: ModifyDialogState<TriggerRuleType>;
  onOk: (updatedRuleFields: TriggerRuleTypeToSend, idToUpdate: number | null) => Promise<void>;
  onCancel: () => void;
  onDeleteIcon: (ruleToDelete: TriggerRuleType) => void;
}

const getMode = (state: ModifyDialogState<TriggerRuleType>) => {
  if (state.objectToModify) {
    return Mode.Change;
  }
  return Mode.Add;
};

const getModalTitle = (mode: Mode) => {
  let titleAction;
  if (mode === Mode.Add) {
    titleAction = 'Add';
  } else {
    titleAction = 'Change';
  }
  return titleAction + ' Trigger Rule';
};

const ModifyTriggerRuleDialog = (props: ModifyTriggerRuleDialogProps) => {
  const { state, onOk, onCancel, onDeleteIcon } = props;
  // If task to edit is set, assume edit.
  // Otherwise, assume create
  const mode = getMode(state);

  const handleCancel = () => {
    onCancel();
  };
  // const task = state.taskToEdit;

  const teamMembersRequestConfig = {
    params: {
      team: props.selectedTeam.id,
    },
  } as AxiosRequestConfig;
  const tagsRequestConfig = {
    // No parameters
  } as AxiosRequestConfig;
  const eventTypeRequestConfig = {
    // No parameters
  } as AxiosRequestConfig;
  const {
    isLoading,
    error,
    data: optionsData,
    refetch: refetchOptions,
  } = useQuery(['allTriggerRuleOptionsQuery', teamMembersRequestConfig], () => {
    // https://www.storyblok.com/tp/how-to-send-multiple-requests-using-axios
    // I wish I could put TeamMemberType[] and TagType[] on the requests
    //  However, axios.all() typings assumes all response types match first request, so using 'any'
    const teamMembersRequest = axios.get<any>(TEAM_MEMBERS_URL, teamMembersRequestConfig);
    const tagsRequest = axios.get<any>(GET_TAGS_URL, tagsRequestConfig);
    const eventTypeRequest = axios.get<any>(EVENT_TYPES_URL, eventTypeRequestConfig);
    return axios.all([teamMembersRequest, tagsRequest, eventTypeRequest]).then(
      axios.spread((...responses) => {
        const allOptionsResults = {
          teamMembersResponse: responses[0],
          tagsResponse: responses[1],
          eventTypeResponse: responses[2],
        };
        return allOptionsResults;
      }),
    );
  });
  if (isLoading) return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'Loading...');
  if (error) return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'Error!');
  if (optionsData === undefined) return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'No Data!');

  const teamMembers = optionsData.teamMembersResponse.data as TeamMemberType[];

  const teamMemberOptions = teamMembers.map((teamMember) => {
    const optionLabel = getCallSignWithPositionAndName(teamMember);
    return {
      label: optionLabel,
      value: teamMember.id,
    };
  });

  const tags = optionsData.tagsResponse.data as TagType[];

  const tagOptions = tags.map((tag) => {
    return {
      label: tag.name,
      // Value is the object itself. This makes it easy for Formik to pass to backend
      // TODO change to id?
      value: tag,
    };
  });

  const eventTypes = optionsData.eventTypeResponse.data as EventTypeType[];
  const eventTypeOptions = eventTypes.map((eventType) => {
    return {
      label: eventType.name,
      value: eventType.id,
    };
  });

  // TODO should this be in some sort of useState hook?
  var initialStateOfEditableRuleFields;
  let onSubmit;
  let deleteButton: JSX.Element | null;
  let createdByText: JSX.Element | null;
  if (mode === Mode.Change) {
    // Tell typescript that taskToEdit is definitely not null
    // TODO this is hacky, find a way to fix it
    const rule = state.objectToModify!;

    // Copy only editable fields
    initialStateOfEditableRuleFields = {
      // Not editable
      // id: task.id,
      createOnEventType: rule.createOnEventType.id,
      // Not editable
      // creationUser: task.creationUser,
      // Not editable in the UI, but needed on create, so included
      // TODO now create is separated, is above comment still valid???
      team: rule.team.id,

      templateTaskToCreate: {
        daysToComplete: rule.templateTaskToCreate.daysToComplete,
        summaryTemplate: rule.templateTaskToCreate.summaryTemplate,
        descriptionTemplate: rule.templateTaskToCreate.descriptionTemplate,
        linkUrlTemplate: rule.templateTaskToCreate.linkUrlTemplate,
        // Can be undefined, for example when a new FCP is cascaded
        assignedToMember: rule.templateTaskToCreate.assignedToMember?.id,
        totalPoints: rule.templateTaskToCreate.totalPoints,
        tags: rule.templateTaskToCreate.tags,
        // Not editable in the UI, but needed on create, so included
        // TODO now create is separated, is above comment still valid???
        team: rule.templateTaskToCreate.team.id,
      },
    } as TriggerRuleTypeToSend;

    const createdByName = getNameOfCreationUser(rule);
    createdByText = getFormikReadOnlyField('Created by:', createdByName);

    deleteButton = (
      <Button
        className="bi-trash-fill mr-auto"
        variant="danger"
        onClick={() => {
          onDeleteIcon(rule);
        }}
      >
        Delete
      </Button>
    );

    onSubmit = (updatedRule: TriggerRuleTypeToSend | null, { setSubmitting }: any) => {
      console.log('update rule dialog ok hit', updatedRule);
      setTimeout(() => {
        if (updatedRule === null) {
          // If this ever happens, we probably need to use 'useEffect' in some capacity
          alert('updatedRule was null, contact developers');
          setSubmitting(false);
          return;
        }

        // TODO make this typescript cast have a validation or something
        onOk(updatedRule, rule.id).then(() => {
          // In case new tags were created
          refetchOptions();
          setSubmitting(false);
        });
      }, 400);
    };
  } else {
    // Create
    initialStateOfEditableRuleFields = {
      createOnEventType: undefined,
      team: props.selectedTeam.id,

      templateTaskToCreate: {
        daysToComplete: undefined,
        summaryTemplate: '',
        descriptionTemplate: '',
        linkUrlTemplate: '',
        assignedToMember: undefined,
        totalPoints: undefined,
        tags: [],
        team: props.selectedTeam.id,
      },
      // Template task
    } as TriggerRuleTypeToSend;
    onSubmit = (newRule: TriggerRuleTypeToSend, { setSubmitting }: any) => {
      setTimeout(() => {
        if (newRule === null) {
          // If this ever happens, we probably need to use 'useEffect' in some capacity
          alert('new rule was null, contact developers');
          setSubmitting(false);
          return;
        }
        // TODO make this typescript cast have a validation or something
        onOk(newRule, null).then(() => {
          // In case new tags were created
          refetchOptions();
          setSubmitting(false);
        });
      }, 400);
    };
    deleteButton = null;
    createdByText = null;
  }

  const TriggerRuleFormSchema = Yup.object().shape(
    {
      createOnEventType: Yup.number().nullable().required('Required'),
      templateTaskToCreate: Yup.object({
        daysToComplete: Yup.number().required('Required'), // TODO make integer only
        summaryTemplate: Yup.string().required('Required'),
        descriptionTemplate: Yup.string().optional(),
        linkUrlTemplate: Yup.string().optional(),
        assignedToMember: Yup.number().nullable().required('Required'),
        totalPoints: Yup.number().required('Required'), // TODO make integer only
        // TODO tags?
      }),
    },
    // This prevents 'Cyclic dependency', not sure why. https://stackoverflow.com/a/59783840
    [['createOnEventType', 'createOnSmrTab']],
  );

  return (
    // This component causes 'findDOMNode is deprecated in StrictMode' warning
    // Unfortunately, this workaround on SO didn't work https://stackoverflow.com/a/64325602
    // The best way to fix this is to upgrade to react-bootstrap 2.x/bootstrap 5, but that takes some work
    // TODO fix this warning
    <Modal show={state.isOpen} onHide={handleCancel}>
      {
        <Formik
          initialValues={initialStateOfEditableRuleFields}
          validationSchema={TriggerRuleFormSchema}
          onSubmit={onSubmit}
        >
          {(formikProps: any) => (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>{getModalTitle(mode)}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* Only show createdByText on editing */}
                {createdByText}
                <FormikController
                  control="select"
                  name="createOnEventType"
                  label="Event Type"
                  options={eventTypeOptions}
                />

                <FormikController control="input" name="templateTaskToCreate.daysToComplete" label="Days To Complete" />
                <FormikController control="input" name="templateTaskToCreate.summaryTemplate" label="Summary" />
                <FormikController
                  control="input"
                  name="templateTaskToCreate.descriptionTemplate"
                  label="Description (optional)"
                />
                <FormikController control="input" name="templateTaskToCreate.linkUrlTemplate" label="Link (optional)" />
                <FormikController
                  control="select"
                  name="templateTaskToCreate.assignedToMember"
                  label="Assigned To"
                  options={teamMemberOptions}
                />
                <FormikController control="input" name="templateTaskToCreate.totalPoints" label="Weight" />
                {/* 'startingOptions' because we are allowing the creation of new tags */}
                <FormikController
                  control="tags"
                  name="templateTaskToCreate.tags"
                  label="Tags (optional)"
                  startingOptions={tagOptions}
                />
              </Modal.Body>
              <Modal.Footer>
                {/* Only show delete button on editing */}
                {deleteButton}
                <Button variant="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" disabled={formikProps.isSubmitting}>
                  Ok
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      }
    </Modal>
  );
};

function defaultHandleModifyTriggerRuleDialogOk(
  modifiedRule: TriggerRuleTypeToSend,
  idToUpdate: number | null,
  closeModifyDialog: () => void,
  refetchRules: () => void,
  addFeedbackMessage: (feedbackMessage: FeedbackMessage) => void,
) {
  let promise;
  if (idToUpdate !== null) {
    console.log('Updating rule ' + idToUpdate, modifiedRule);
    // POST is for new stuff, PUT is for replacing task (must have ALL fields)
    // This uses PATCH, which loads old task and only updates fields you passed
    // https://stackoverflow.com/a/24241955/13815107
    promise = axios.patch(TRIGGER_RULE_URL + '/' + idToUpdate + '/', modifiedRule).then((response) => {
      closeModifyDialog();
      addFeedbackMessage({
        key: createFeedbackMessageKey('triggerRule', 'change', idToUpdate),
        status: 'success',
        messageBody: <span>Trigger rule updated successfully.</span>,
      });
      refetchRules();
    });
  } else {
    console.log('Creating rule', modifiedRule);
    if (modifiedRule.id) {
      console.error('Creating a rule but sending an id. Was this meant to be a modify?');
      promise = Promise.reject('Creating a rule but sending an id. Was this meant to be a modify?');
    } else {
      promise = axios.post(TRIGGER_RULE_URL + '/', modifiedRule).then((response) => {
        closeModifyDialog();
        addFeedbackMessage({
          key: createFeedbackMessageKey('triggerRule', 'create'),
          status: 'success',
          messageBody: <span>Trigger rule created successfully.</span>,
        });
        refetchRules();
      });
    }
  }
  return promise;
}

export default ModifyTriggerRuleDialog;
export { defaultHandleModifyTriggerRuleDialogOk };
