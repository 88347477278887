import TagType from '../../../domain/tag';
import TaskType from '../../../domain/task';
import { isColorDark } from '../../../helpers/ColorHelper';
import styles from './TaskGridTags.module.scss';

type TaskGridTagsProps = {
  // Populated by AG grid based on 'field':'actions' in column configuration
  value: TagType[];
  // Populated by AG grid because this is one row of data
  data: TaskType;
};

const TaskGridTags = (props: TaskGridTagsProps) => {
  // Is the below undefined?
  // Check that the backend is returning tags array and the field name is right
  const sortedTags = props.value.sort(compareTagNames);
  return (
    <div>
      {sortedTags.map((tag) => {
        let fontColor = 'black';
        if (isColorDark(tag.color)) {
          fontColor = 'white';
        }
        const styleToColorTag = {
          backgroundColor: tag.color,
          color: fontColor,
        };
        return (
          <span key={tag.name} className={styles.bubble} style={styleToColorTag}>
            {tag.name}
          </span>
        );
      })}
    </div>
  );
};

// https://stackoverflow.com/a/1129270/13815107
function compareTagNames(a: TagType, b: TagType) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

export default TaskGridTags;

export type { TaskGridTagsProps };
