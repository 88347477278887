import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import styles from './HeaderDotsMenu.module.scss';
import TeamType from '../../domain/team';
import { usePermissions } from '../../effects/usePermissions';

type HeaderDotsMenuProps = {
  selectedTeam: TeamType | null;
};

// Should be authenticated to display this
const HeaderDotsMenu = (props: HeaderDotsMenuProps) => {
  const permissionResponse = usePermissions(props.selectedTeam);
  if (permissionResponse.isLoading) return <p>Loading...</p>;
  if (permissionResponse.error || !permissionResponse.permissionFlags) return <p>Error!</p>;

  const permissionFlags = permissionResponse.permissionFlags;

  return (
    <>
      {permissionFlags.canViewDots && (
        <NavDropdown
          alignRight
          title={<i className="bi bi-list" />}
          id="dots-nav-dropdown"
          className={styles.dotsDropdown}
        >
          {permissionFlags.canViewSmrAssignments && (
            <LinkContainer to="smr-responsibilities" className={styles.linkContainer}>
              <NavDropdown.Item>SMR Responsibilities</NavDropdown.Item>
            </LinkContainer>
          )}
          {permissionFlags.canViewMemberAdmin && (
            <LinkContainer to="member-admin" className={styles.linkContainer}>
              <NavDropdown.Item>Manage Team Members</NavDropdown.Item>
            </LinkContainer>
          )}
          {permissionFlags.canViewTeamScores && (
            <LinkContainer to="team-scores" className={styles.linkContainer}>
              <NavDropdown.Item>Team Scores</NavDropdown.Item>
            </LinkContainer>
          )}
          {/* Adding users to SSO (do this before messing with permissions in Django) */}
          {/* <Nav.Link href="https://manage.auth0.com/dashboard/us/dev-oiz87mbf/users">Manage Auth0 users <i className="bi-box-arrow-up-right"></i></Nav.Link> */}
        </NavDropdown>
      )}
    </>
  );
};

export default HeaderDotsMenu;
