import TeamMemberType from '../domain/teamMember';
import { getAccountName, getShortAccountName } from './AccountHelpers';

function getCallSignWithPositionAndName(teamMember: TeamMemberType): string {
  return teamMember.callSign + ' - ' + getPositionWithName(teamMember);
}

function getCallSignWithShortName(teamMember: TeamMemberType): string {
  let callSignWithName = teamMember.callSign;
  if (teamMember.account) {
    callSignWithName += ' - ' + getShortAccountName(teamMember.account);
  }
  return callSignWithName;
}

function getPositionWithName(teamMember: TeamMemberType): string {
  let positionWithName = teamMember.position;
  if (teamMember.account) {
    positionWithName += ' - ' + getAccountName(teamMember.account);
  }

  return positionWithName;
}

export { getPositionWithName, getCallSignWithPositionAndName, getCallSignWithShortName };
