import { ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { AxiosRequestConfig } from 'axios';
import React from 'react';
import TeamMemberType from '../../../domain/teamMember';
import TaskType from '../../../domain/task';
import TeamType from '../../../domain/team';
import {
  compareDatesForAgGridFilter,
  formatDateForAgGrid,
  getEndOfMonthFromString,
  getStartOfMonthFromString,
} from '../../../helpers/AgGridDateHelpers';
import BaseTaskGrid from '../BaseTaskGrid/BaseTaskGrid';
import { roundTo2Decimals } from '../../../helpers/RoundingHelper';
import { getPositionWithName } from '../../../helpers/TeamMembershipHelpers';

type CompletedTasksGridProps = {
  selectedTeam: TeamType;
  selectedTeamMember?: TeamMemberType;
  selectedMonth: string;
};

const CompletedTasksGrid = (props: CompletedTasksGridProps) => {
  if (!props.selectedMonth) {
    throw new Error('Need to pass selectedMonth prop');
  }

  const requestConfig = {
    params: {
      // CurrentTasksGrid and CompletedTasksGrid use the same endpoint
      team: props.selectedTeam.id,
      db_is_closed: true,
      // TODO timezone issues
      closed_date_time__gte: getStartOfMonthFromString(props.selectedMonth, props.selectedTeam.timezone).toISO(),
      closed_date_time__lte: getEndOfMonthFromString(props.selectedMonth, props.selectedTeam.timezone).toISO(),
    },
  } as AxiosRequestConfig;

  let title = 'Completed Tasks';
  if (props.selectedTeamMember) {
    title += ' for ' + getPositionWithName(props.selectedTeamMember);
    requestConfig.params.assigned_to_member = props.selectedTeamMember.id;
  }
  if (props.selectedMonth) {
    title += ' during ' + props.selectedMonth;
  }

  const getAdditionalColDefs = (team: TeamType) => {
    return [
      {
        field: 'completedDateTime',
        headerName: 'Date Compl.',
        valueFormatter: (params: ValueFormatterParams) => {
          return formatDateForAgGrid(params.value, team.timezone);
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            return compareDatesForAgGridFilter(filterLocalDateAtMidnight, cellValue, team.timezone);
          },
        },
        width: 110,
      },
      {
        field: 'pointsEarned',
        headerName: 'Weight',
        // Only use headerTooltip in colDef, not the other tooltipFields
        // Prefer to use a custom cellRenderer, which gives more control and can use bootstrap tooltips
        headerTooltip: 'Points earned / Total possible points - Percentage Earned',
        width: 140,
        // This is the function used for sorting.
        // It different and returns a number so 10 comes after 1.1
        valueGetter: (params: ValueGetterParams) => {
          const row = params.data as TaskType;
          if (row.pointsEarned === null) {
            return '';
          }
          return roundTo2Decimals(row.pointsEarned!);
        },
        // This is the function used for display
        valueFormatter: (params: ValueFormatterParams) => {
          const row = params.data as TaskType;
          if (
            row.pointsEarned === null ||
            row.pointsEarned === undefined ||
            row.pointsEarnedPercentage === null ||
            row.pointsEarnedPercentage === undefined
          ) {
            return '';
          }
          return (
            roundTo2Decimals(row.pointsEarned).toString() +
            ' / ' +
            roundTo2Decimals(row.totalPoints).toString() +
            ' - ' +
            roundTo2Decimals(row.pointsEarnedPercentage * 100).toString() +
            '%'
          );
        },
        // This is the function used for filtering
        filterValueGetter: (params: ValueGetterParams) => {
          const row = params.data as TaskType;
          if (
            row.pointsEarned === null ||
            row.pointsEarned === undefined ||
            row.pointsEarnedPercentage === null ||
            row.pointsEarnedPercentage === undefined
          ) {
            return '';
          }
          return (
            roundTo2Decimals(row.pointsEarned).toString() +
            ' / ' +
            roundTo2Decimals(row.totalPoints).toString() +
            ' - ' +
            roundTo2Decimals(row.pointsEarnedPercentage * 100).toString() +
            '%'
          );
        },
      },
    ];
  };

  return (
    <BaseTaskGrid
      title={title}
      selectedTeam={props.selectedTeam}
      requestConfig={requestConfig}
      selectedTeamMember={props.selectedTeamMember}
      // idToFocusOn=
      hideCreateButton={true}
      height={400}
      selectedMonth={props.selectedMonth}
      getAdditionalColDefs={getAdditionalColDefs}
    />
  );
};

export default CompletedTasksGrid;
