import React from 'react';
import TeamType from '../../../domain/team';
import TeamMemberType from '../../../domain/teamMember';
import BaseTaskGrid from '../BaseTaskGrid/BaseTaskGrid';
import { ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import TaskType from '../../../domain/task';
import { roundTo2Decimals } from '../../../helpers/RoundingHelper';
import { AxiosRequestConfig } from 'axios';
import { DateTime } from 'luxon';
import { getPositionWithName } from '../../../helpers/TeamMembershipHelpers';

type CurrentTaskGridProps = {
  selectedTeam: TeamType;
  selectedTeamMember?: TeamMemberType;
  idToFocusOn?: number;
  height?: number;
  hideCreateButton?: boolean;
  selectedMonth?: string;
};

function getTaskRequestConfig(props: CurrentTaskGridProps) {
  const tasksRequestConfig = {
    params: {
      // CurrentTasksGrid and CompletedTasksGrid use the same endpoint
      // db_is_closed: false is the same thing as is_expired: false + is_complete: false
      // The db_ prefix is a workaround for django's failure to
      // make @property(s) and queryset annotations play nicely together
      // db_is_closed: false,
    },
  } as AxiosRequestConfig;

  if (props.selectedTeamMember) {
    tasksRequestConfig.params.db_is_closed = false;
    tasksRequestConfig.params.assigned_to_member = props.selectedTeamMember.id;
  } else {
    tasksRequestConfig.params.team = props.selectedTeam.id;
    tasksRequestConfig.params.completed_date_time__isnull = true;
    tasksRequestConfig.params.expired_date_time__isnull = true;
    tasksRequestConfig.params.due_date_time__lt = DateTime.now().minus({ days: 1 }).toISO();
  }
  return tasksRequestConfig;
}

const CurrentTasksGrid = (props: CurrentTaskGridProps) => {
  const tasksRequestConfig = getTaskRequestConfig(props);
  let title = 'Current Tasks';
  if (props.selectedTeamMember) {
    title += ' for ' + getPositionWithName(props.selectedTeamMember);
  }

  const getAdditionalColDefs = () => {
    return [
      {
        field: 'currentPossiblePoints',
        headerName: 'Weight',
        // Only use headerTooltip in colDef, not the other tooltipFields
        // Prefer to use a custom cellRenderer, which gives more control and can use bootstrap tooltips
        headerTooltip: 'Points possible now / Total possible points',
        width: 100,
        // This is the function used for sorting.
        // It different and returns a number so 10 comes after 1.1
        valueGetter: (params: ValueGetterParams) => {
          const row = params.data as TaskType;
          return roundTo2Decimals(row.currentPossiblePoints);
        },
        // This is the function used for display
        valueFormatter: (params: ValueFormatterParams) => {
          const row = params.data as TaskType;
          return (
            roundTo2Decimals(row.currentPossiblePoints).toString() +
            ' / ' +
            roundTo2Decimals(row.totalPoints).toString()
          );
        },
        // This is the function used for filtering
        filterValueGetter: (params: ValueGetterParams) => {
          const row = params.data as TaskType;
          return (
            roundTo2Decimals(row.currentPossiblePoints).toString() +
            ' / ' +
            roundTo2Decimals(row.totalPoints).toString()
          );
        },
      },
    ];
  };

  return (
    <BaseTaskGrid
      title={title}
      selectedTeam={props.selectedTeam}
      selectedMonth={props.selectedMonth}
      requestConfig={tasksRequestConfig}
      idToFocusOn={props.idToFocusOn}
      selectedTeamMember={props.selectedTeamMember}
      height={props.height}
      getAdditionalColDefs={getAdditionalColDefs}
      hideCreateButton={props.hideCreateButton}
      showAssignedToColumn={true}
    />
  );
};

export default CurrentTasksGrid;
