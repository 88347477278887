import { createContext } from 'react';

type SelectedCallsignContextType = {
  selectedCallsign: string | null;
};

const dummyCallsignContext = {
  selectedCallsign: null,
  setSelectedCallsign: (job: any) => {},
} as SelectedCallsignContextType;

const SelectedCallsignContext = createContext(dummyCallsignContext);

export default SelectedCallsignContext;
