import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import FieldCoreProcessType, { FieldCoreProcessTypeToSend } from '../../../domain/fieldCoreProcess';
import { FIELD_CORE_PROCESS_URL } from '../../../constants/urls';

function useGetFcpsWithInstallationsByDepartment(department: string) {
  const getFcpsByDepartment = {
    params: {
      department: department,
    },
  } as AxiosRequestConfig;
  return useQuery(['useGetFcpsWithInstallationsByDepartment', getFcpsByDepartment], async () => {
    const response = await axios.get<FieldCoreProcessType[]>(
      `${FIELD_CORE_PROCESS_URL}/get-with-installations`,
      getFcpsByDepartment,
    );
    return response.data;
  });
}

function useGetFcpsByDepartment(department: string, enabled: boolean = true) {
  const getFcpsByDepartment = {
    params: {
      department: department,
    },
  } as AxiosRequestConfig;
  return useQuery(
    ['useGetFcpsByDepartment', getFcpsByDepartment],
    async () => {
      const response = await axios.get<FieldCoreProcessType[]>(FIELD_CORE_PROCESS_URL, getFcpsByDepartment);
      return response.data;
    },
    {
      enabled: enabled,
    },
  );
}

function createFcpWithSubsteps(fieldCoreProcess: FieldCoreProcessTypeToSend) {
  return axios.post<FieldCoreProcessTypeToSend, AxiosResponse<FieldCoreProcessType>>(
    `${'field-core-processes/'}`,
    fieldCoreProcess,
  );
}

function updateFcpWithSubsteps(fieldCoreProcess: FieldCoreProcessTypeToSend, idToUpdate: number) {
  return axios.patch<FieldCoreProcessTypeToSend, AxiosResponse<FieldCoreProcessType>>(
    `field-core-processes/${idToUpdate}/`,
    fieldCoreProcess,
  );
}

export {
  useGetFcpsWithInstallationsByDepartment,
  useGetFcpsByDepartment,
  createFcpWithSubsteps,
  updateFcpWithSubsteps,
};
