import { ObjectWithId } from './shared';
import TeamMemberType from './teamMember';
import TeamType from './team';

type SmrCategory = ObjectWithId & {
  name: string;
};

type SmrCategoryResponsibilityType = ObjectWithId & {
  smrCategory: SmrCategory;
  assignedToMember: TeamMemberType;
  team: TeamType;
};

type SmrCategoryResponsibilityTypeToSend = {
  id?: number;
  smrCategory?: number;
  assignedToMember?: number;
  team?: number;
};

function convertToTypeToSend(smrResponsibility: SmrCategoryResponsibilityType): SmrCategoryResponsibilityTypeToSend {
  // Convert objects into ids while preserving the other fields
  const smrResponsibilityToSend = {
    ...smrResponsibility,
    assignedToMember: smrResponsibility.assignedToMember?.id,
    team: smrResponsibility.team?.id,
    smrCategory: smrResponsibility.smrCategory?.id,
  } as SmrCategoryResponsibilityTypeToSend;
  return smrResponsibilityToSend;
}

export default SmrCategoryResponsibilityType;
export type { SmrCategoryResponsibilityTypeToSend };
export { convertToTypeToSend };
