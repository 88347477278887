import { Col, Container, Row } from 'react-bootstrap';
import MemberGrid from '../../components/members/MemberGrid/MemberGrid';
import TeamType from '../../domain/team';

type MemberAdminProps = {
  selectedTeam: TeamType;
};

const MemberAdmin = (props: MemberAdminProps) => {
  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row>
        <Col>
          <h4 className="text-center font-weight-bold">Member Administration</h4>
        </Col>
      </Row>
      {props.selectedTeam && (
        <Row className="flex-grow-1 pb-1">
          <Col className="shadow-sm border d-flex">
            <MemberGrid selectedTeam={props.selectedTeam} />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default MemberAdmin;
