import { Col, Container, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { getDefaultColDef } from '../../../helpers/GridComponentHelpers';
import LoaderWheel from '../../../components/LoaderWheel/LoaderWheel';

import '../../../styles.css';
import { useGetSubstepInstallationsByAssignee } from '../../../components/fcp/apis/substep_installation_api';
import { ColDef } from 'ag-grid-community';
import { formatDateForAgGridWithYear } from '../../../helpers/AgGridDateHelpers';
import { HQ_TIMEZONE } from '../../../constants/globals';
import { useGetLeadPeople } from '../../../components/fcp/apis/accounts_api';

type SubstepLookupPageProps = {};

const SubstepLookupPage = (props: SubstepLookupPageProps) => {
  const [currAssignee, setCurrAssignee] = useState<number | undefined>(undefined);
  const [assigneeJsx, setAssigneeJsx] = useState<JSX.Element[]>([<option key={-1} value={undefined}></option>]);

  // const [rowData, setRowData] = useState<SubstepLookupGridResult[]>([]);

  const [columnDefs] = useState([
    { field: 'fcpName', headerName: 'FCP', width: 250 },
    { field: 'substepName', headerName: 'Substep', width: 250 },
    { field: 'teamName', headerName: 'Team', width: 250 },
    {
      field: 'completionDateTime',
      headerName: 'Date Completed',
      width: 150,
      valueFormatter: (params) => {
        if (!params.value) {
          return '';
        }
        // Assuming our staff are all around South Africa
        // TODO do it based on assignee team country?
        return formatDateForAgGridWithYear(params.value, HQ_TIMEZONE);
      },
    },
  ] as ColDef[]);

  /** Finds all of the substep installations that the selected user is assigned to.
   *
   * @param assignee
   * @returns list of substep installations that the user is assigned to
   */
  // const findSubstepInsts = (assignee: number) => {
  //   let results: SubstepLookupGridResult[] = [];
  //
  //   fullSubs.forEach((substep) => {
  //     substep.subInsts.forEach((inst) => {
  //       if (inst.assignee !== null && inst.assignee === assignee) {
  //         results.push({
  //           fcp: substep._fcpName,
  //           substep: substep.name,
  //           team: findNameById(inst.team, atTeams.concat(fhTeams)),
  //           compDate: inst.completionDateTime ? DateTime.fromISO(inst.completionDateTime).toLocaleString() : '',
  //         });
  //       }
  //     });
  //   });
  //
  //   return results;
  // };

  const { error, data: subInstalls, isFetching } = useGetSubstepInstallationsByAssignee(currAssignee);
  const { error: leadPeopleError, data: leadPeople, isLoading: isLoadingLeadPeople } = useGetLeadPeople();

  /** Called when an assignee is selected in the dropdown to
   *  find row data.
   *
   * @param assigneeId id of selected assignee in dropdown
   */
  const onAssigneeChange = (assigneeId: number) => {
    setCurrAssignee(assigneeId);
    // setRowData(findSubstepInsts(assigneeId));
  };

  useEffect(() => {
    const newAssigneeJsx = [];
    newAssigneeJsx.push(<option key={-1} value={undefined}></option>);
    if (leadPeople) {
      for (let i = 0; i < leadPeople.length; i++) {
        newAssigneeJsx.push(
          <option key={i} value={leadPeople[i].id}>
            {' '}
            {leadPeople[i].firstName}{' '}
          </option>,
        );
      }
    }
    setAssigneeJsx(newAssigneeJsx);
  }, [leadPeople]);

  if (error || leadPeopleError) {
    return <p>Error!</p>;
  } else if (isFetching || isLoadingLeadPeople) {
    return <LoaderWheel />;
  } else {
    return (
      <Container fluid className="d-flex flex-column flex-grow-1 page-container">
        <Row className="d-flex align-items-center justify-content-start">
          <Col className="d-flex flex-column flex-grow-1" sm={2} md={2} lg={2} xl={2}>
            <h5 className="font-weight-bold">Substep Lookup</h5>
          </Col>
        </Row>
        <Row className="d-flex align-items-center justify-content-start">
          <Col className="d-flex flex-column flex-grow-1 pb-3" sm={2} md={2} lg={2} xl={2}>
            <select
              value={currAssignee}
              onChange={(e: any) => {
                let assigneeId = parseInt(e.target.value);
                return onAssigneeChange(assigneeId);
              }}
            >
              {assigneeJsx}
            </select>
          </Col>
        </Row>
        <Row className="d-flex flex-column flex-grow-1 pl-3">
          <Col sm={8} md={8} lg={8} xl={8}>
            <div className="ag-theme-balham grid-wrapper">
              <AgGridReact
                debounceVerticalScrollbar={true}
                rowData={subInstalls}
                columnDefs={columnDefs}
                suppressMenuHide={true}
                defaultColDef={getDefaultColDef()}
                suppressRowVirtualisation={true}
                suppressColumnVirtualisation={true}
              />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
};

export default SubstepLookupPage;
