import React, { useContext } from 'react';
import { AgChartsReact } from 'ag-charts-react';
import { AgChartOptions } from 'ag-grid-community';
import { useQuery } from 'react-query';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  GET_MEMBER_CURRENT_SCORE_SUMMARY_URL,
  GET_MEMBER_SCORE_SUMMARY_FOR_PERIOD_URL,
} from '../../../../constants/urls';
import { TeamMembershipScoreSummary } from '../../../../domain/teamMembershipScoreSummary';
import { roundTo2Decimals } from '../../../../helpers/RoundingHelper';
import {
  getEndOfMonthFromString,
  getStartOfMonthFromString,
  isSelectedMonthTheCurrentMonth,
} from '../../../../helpers/AgGridDateHelpers';
import styles from './MemberScoreSummaryPieChart.module.scss';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import RefetchSubjectContext from '../../../../contexts/RefetchSubjectContext';
import { GET_MEMBER_SCORE_SUMMARY } from '../../../../constants/globals';
import { SelectedTeamMemberContext } from '../../../../contexts/SelectedTeamMemberContext';
import TeamType from '../../../../domain/team';

type MemberScoreSummaryPieChartProps = {
  selectedTeam: TeamType;
  selectedMonth?: string;
  height: number;
};

const getOptions = (
  summary: TeamMembershipScoreSummary | null,
  reactRouterNavigate: NavigateFunction,
  isCurrentMonth: boolean,
): AgChartOptions => {
  let data: any[];
  if (!summary) {
    data = [];
  } else {
    let goalMissedTitle = 'Goal Missed (' + percentToHumanReadable(summary.goals_penalty_percentage) + ')';
    let goalEarnedTitle = 'Goal Earned (' + percentToHumanReadable(summary.goals_points_earned_percentage) + ')';
    if (isCurrentMonth) {
      goalMissedTitle = 'Est. Goal Missed (' + percentToHumanReadable(summary.goals_penalty_percentage) + ')';
      goalEarnedTitle = 'Est. Goal Earned (' + percentToHumanReadable(summary.goals_points_earned_percentage) + ')';
    }
    data = [
      {
        label: 'Complete Tasks (' + percentToHumanReadable(summary.tasks_points_earned_percentage) + ')',
        points: summary.tasks_points_earned,
        percent: summary.tasks_points_earned_percentage,
        count: summary.completed_task_count,
      },
      {
        label: 'Not Yet Complete Tasks (' + percentToHumanReadable(summary.incomplete_task_percentage) + ')',
        points: summary.incomplete_task_points,
        percent: summary.incomplete_task_percentage,
        count: summary.incomplete_task_count,
      },
      {
        label: 'Late Points Missed (' + percentToHumanReadable(summary.tasks_penalty_percentage) + ')',
        points: summary.tasks_penalty_points,
        percent: summary.tasks_penalty_percentage,
      },
      {
        label: 'Expired Tasks (' + percentToHumanReadable(summary.expired_task_percentage) + ')',
        points: summary.expired_task_points,
        percent: summary.expired_task_percentage,
        count: summary.expired_task_count,
      },
      {
        label: goalMissedTitle,
        points: summary.goals_penalty_points,
        percent: summary.goals_penalty_percentage,
      },
      {
        label: 'Goal Unknown (' + percentToHumanReadable(summary.goals_unknown_percentage) + ')',
        points: summary.goals_unknown_points,
        percent: summary.goals_unknown_percentage,
      },
      {
        label: goalEarnedTitle,
        points: summary.goals_points_earned,
        percent: summary.goals_points_earned_percentage,
      },
    ];
  }
  return {
    autoSize: true,
    series: [
      {
        type: 'pie',
        highlightStyle: {
          item: {
            // fill: '#00eeff',
            fill: undefined,
            strokeWidth: 4,
            stroke: '#0000000',
          },
        },
        data: data,
        labelKey: 'label',
        angleKey: 'points',
        label: {
          minAngle: 0,
        },
        callout: {
          strokeWidth: 2,
        },
        fills: ['#6ab04c', '#d3d3d3', '#eb4c4b', '#eb4c4b', '#f0932b', '#e6e6e6', '#badc58'],
        strokes: ['#808080', '#808080', '#808080', '#808080', '#808080', '#808080', '#808080'],
        listeners: {
          nodeClick: (event) => {
            const datum = event.datum;
            if (datum.label === 'Complete Tasks') {
              reactRouterNavigate('/member-scores', { replace: true });
            } else {
              // console.log('pie chart: clicked ' + datum.label + ' for ' + selectedTeamMember.id);
            }
          },
        },
      },
    ],
    // Helps get more text on right and left sides AND stops top/bottom text from getting clipped
    padding: {
      top: 20,
      bottom: 30,
      left: 20,
      right: 20,
    },
    legend: {
      enabled: false,
    },
  };
};

const percentToHumanReadable = (percent: number) => {
  return '' + roundTo2Decimals(percent * 100) + '%';
};

const MemberScoreSummaryPieChart = (props: MemberScoreSummaryPieChartProps) => {
  const selectedTeamMemberContext = useContext(SelectedTeamMemberContext);
  const refetchSubjectContext = useContext(RefetchSubjectContext);

  const reactRouterNavigate = useNavigate();
  const isCurrentMonth =
    !props.selectedMonth || isSelectedMonthTheCurrentMonth(props.selectedMonth, props.selectedTeam.timezone);
  const [chartOptions, setChartOptions] = React.useState<null | AgChartOptions>(
    getOptions(null, reactRouterNavigate, isCurrentMonth),
  );

  let memberScoreSummaryRequestConfig: AxiosRequestConfig;
  // technically don't have to have !props.selectedMonth here but Typescript hates when i don't have it
  if (!props.selectedMonth || isCurrentMonth) {
    memberScoreSummaryRequestConfig = {
      params: {
        team_membership: selectedTeamMemberContext?.selectedTeamMember?.id,
      },
    } as AxiosRequestConfig;
  } else {
    memberScoreSummaryRequestConfig = {
      params: {
        team_membership: selectedTeamMemberContext?.selectedTeamMember?.id,
        period_start: getStartOfMonthFromString(props.selectedMonth, props.selectedTeam.timezone).toISO(),
        period_end: getEndOfMonthFromString(props.selectedMonth, props.selectedTeam.timezone).toISO(),
      },
    } as AxiosRequestConfig;
  }

  const useQueryConfig = {
    staleTime: 100000, // Cache for this long, in ms
    onSuccess: (response: AxiosResponse<TeamMembershipScoreSummary>) => {
      // Nothing special here
      // Stop loading indicator?
    },
  };

  const {
    error,
    data,
    isLoading,
    refetch: refetchMemberScoreSummary,
  } = useQuery(
    [GET_MEMBER_SCORE_SUMMARY, memberScoreSummaryRequestConfig],
    () => {
      // Loading indicator?
      let url;
      if (isCurrentMonth) {
        url = GET_MEMBER_CURRENT_SCORE_SUMMARY_URL;
      } else {
        url = GET_MEMBER_SCORE_SUMMARY_FOR_PERIOD_URL;
      }
      return axios.get<TeamMembershipScoreSummary[]>(url, memberScoreSummaryRequestConfig);
    },
    useQueryConfig,
  );

  refetchSubjectContext.subscribe({
    next: (refetchType) => {
      if (refetchType === GET_MEMBER_SCORE_SUMMARY) {
        refetchMemberScoreSummary();
      }
    },
  });

  React.useEffect(() => {
    if (data?.data) {
      const isCurrentMonth =
        !props.selectedMonth || isSelectedMonthTheCurrentMonth(props.selectedMonth, props.selectedTeam.timezone);
      console.log(data.data);
      setChartOptions(getOptions(data.data, reactRouterNavigate, isCurrentMonth));
    }
  }, [data, data?.data, reactRouterNavigate, props, selectedTeamMemberContext?.selectedTeamMember]);

  if (isLoading) return <p className={styles.centered}>Loading...</p>;
  if (error || data === undefined || !data?.data) return <p className={styles.centered}>Error!</p>;

  const summary: TeamMembershipScoreSummary = data.data;
  if (summary && summary.total_points === 0) {
    return <p className={styles.centered}>Nothing assigned!</p>;
  }

  // This was originally just the AgChartsReact element.
  // However, you didn't have full control over the html and styling of the title.
  // Since the title is on top I just wrapped it in a div.

  const estPointsEarned = summary.estimated_total_points_earned;
  const estimatedOverallEarnedPercentage = summary.estimated_total_points_earned_percentage;
  const scoreValueText = roundTo2Decimals(estimatedOverallEarnedPercentage * 100) + '%';
  const earnedOutOfTotalText =
    '(' + roundTo2Decimals(estPointsEarned) + '/' + roundTo2Decimals(summary.total_points) + ')';
  return (
    <div style={{ height: props.height }}>
      <span className={styles.scoreTitle}>
        {isCurrentMonth && <span className={styles.scoreLabel}>Potential Score:</span>}
        {!isCurrentMonth && <span className={styles.scoreLabel}>Score:</span>}
        <span className={styles.scoreValue}>{scoreValueText}</span>
        <span className={styles.earnedOutOfTotal}>{earnedOutOfTotalText}</span>
      </span>
      {/* TODO this is hacky */}
      <div style={{ height: props.height - 36 }}>
        <AgChartsReact options={chartOptions} />
      </div>
    </div>
  );
};

export default MemberScoreSummaryPieChart;
