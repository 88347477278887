import React, { useMemo, useState } from 'react';

import { FcpRecordGrid } from '../../../components/fcp/grids/FcpRecordGrid/FcpRecordGrid';
import LoaderWheel from '../../../components/LoaderWheel/LoaderWheel';

import '../../../styles.css';
import { useGetFcpsWithInstallationsByDepartment } from '../../../components/fcp/apis/fcp_api';
import { useGetTeamsByDepartment } from '../../../components/fcp/apis/teams_api';
import { useGetImpactMetrics } from '../../../components/fcp/apis/impact_metric_api';
import { useGetLeadPeople } from '../../../components/fcp/apis/accounts_api';

type FCPRecordPageProps = {
  type: string; //"AT" or "FH"
};

const FCPRecordPage = (props: FCPRecordPageProps) => {
  const [requestError] = useState<string>('');

  let department = useMemo(() => (props.type === 'AT' ? 'Anti-Trafficking' : 'Family Homes'), [props.type]);

  const {
    error: teamsError,
    data: departmentTeams,
    isLoading: isTeamsLoading,
    isFetching: isTeamsFetching,
    refetch: refetchTeams,
  } = useGetTeamsByDepartment(department);
  const {
    error: fcpError,
    data: departmentFcpData,
    isLoading: isFcpsLoading,
    isFetching: isFcpsFetching,
    refetch: refetchFcps,
  } = useGetFcpsWithInstallationsByDepartment(department);

  const { error: imError, data: impactMetrics, isLoading: isImLoading } = useGetImpactMetrics();
  const { error: leadPeopleError, data: leadPeople, isLoading: isLoadingLeadPeople } = useGetLeadPeople();

  const canEditFcps = !isFcpsFetching && !isTeamsFetching;

  if (requestError || teamsError || fcpError || imError || leadPeopleError) {
    return <p>Error!</p>;
  } else if (isFcpsLoading || isTeamsLoading || isImLoading || isLoadingLeadPeople) {
    return <LoaderWheel />;
  } else if (!departmentFcpData || !departmentTeams || !impactMetrics || !leadPeople) {
    return <p>No Data!</p>;
  } else {
    return (
      <>
        {!canEditFcps && <div>Reloading FCPs...</div>}
        <FcpRecordGrid
          department={department}
          departmentTeams={departmentTeams}
          impactMetrics={impactMetrics}
          departmentFcps={departmentFcpData}
          leadPeople={leadPeople}
          canEditFcps={canEditFcps}
          refetchFcps={refetchFcps}
          refetchTeams={refetchTeams}
        />
      </>
    );
  }
};

export default FCPRecordPage;
