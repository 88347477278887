import axios, { AxiosRequestConfig } from 'axios';
import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { GET_TEAM_SCORE_SUMMARY_URL } from '../../../../constants/urls';
import TeamType from '../../../../domain/team';
import TeamScoreSummaryType from '../../../../domain/teamScoreSummary';
import { roundTo2Decimals } from '../../../../helpers/RoundingHelper';
import styles from './TeamScoreSummary.module.scss';
import { GET_TEAM_SCORE_SUMMARY_FOR_TEAM } from '../../../../constants/globals';
import RefetchSubjectContext from '../../../../contexts/RefetchSubjectContext';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

type TeamScoreSummaryProps = {
  selectedTeam: TeamType;
};

const TeamScoreSummary = (props: TeamScoreSummaryProps) => {
  const refetchSubjectContext = useContext(RefetchSubjectContext);
  const requestConfig = {
    params: {
      team: props.selectedTeam.id,
    },
  } as AxiosRequestConfig;

  const { isLoading, error, data, refetch } = useQuery([GET_TEAM_SCORE_SUMMARY_FOR_TEAM, requestConfig], () => {
    return axios.get<TeamScoreSummaryType>(GET_TEAM_SCORE_SUMMARY_URL, requestConfig);
  });

  refetchSubjectContext.subscribe({
    next: (refetchType) => {
      if (refetchType === GET_TEAM_SCORE_SUMMARY_FOR_TEAM) {
        refetch();
      }
    },
  });

  if (isLoading) return <p>Loading...</p>;
  if (error || data === undefined) return <p>Error loading team score summary</p>;

  const teamScoreSummary = data.data;

  return (
    <span className="d-flex flex-row justify-content-start justify-content-lg-end px-0">
      <span className={styles.col}>
        <div className={styles.headers}>Last Month</div>
        <div className={styles.weightedScoreValue}>{roundTo2Decimals(teamScoreSummary.lastMonthScore * 100)}%</div>
      </span>
      <span className={styles.col}>
        <div className={styles.headers}>Weighted Score</div>
        <div className={styles.weightedScoreValue}>{roundTo2Decimals(teamScoreSummary.weightedScore * 100)}%</div>
      </span>
      <span className={styles.col} style={{ textAlign: 'center' }}>
        <div className={styles.headers}>Compl. Tasks</div>
        <LinkContainer to="team-tasks" className={styles.completedTasksValue}>
          <Nav.Link>{teamScoreSummary.completedTaskCount}</Nav.Link>
        </LinkContainer>
      </span>
      <span className={styles.col}>
        <div className={styles.headers}>Past Due</div>
        <LinkContainer to="team-tasks" className={styles.pastDueValue}>
          <Nav.Link>{teamScoreSummary.remainingPastDueTaskCount}</Nav.Link>
        </LinkContainer>
      </span>
    </span>
  );
};

export default TeamScoreSummary;
