import axios, { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import { FIELD_CORE_PROCESS_LEADS_URL } from '../../../constants/urls';
import AccountType from '../../../domain/account';

function useGetLeadPeople(enabled: boolean = true) {
  const queryOptions = {
    params: {},
  } as AxiosRequestConfig;
  return useQuery(
    ['useGetLeadPeople', queryOptions],
    async () => {
      const response = await axios.get<AccountType[]>(FIELD_CORE_PROCESS_LEADS_URL, queryOptions);
      return response.data;
    },
    {
      enabled: enabled,
    },
  );
}

export { useGetLeadPeople };
