import { Button, Col, Container, Row } from 'react-bootstrap';
import TaskType from '../../../domain/task';
import { doActionsContain } from '../../../helpers/PermissionHelper';
import React from 'react';
import { GridApi, ICellRendererParams } from 'ag-grid-community';

type CurrentTasksGridActionsProps = {
  // Populated by AG grid because this is one row of data
  data: TaskType;
  // Passed in with cellRendererParams
  onEditClick?: (task: TaskType) => void;
  onCompleteClick?: (task: TaskType, gridApi: GridApi) => void;
  onRequestChangeClick?: (task: TaskType) => void;
  onReopenClick?: (task: TaskType) => void;
} & ICellRendererParams;

const CurrentTasksGridActions = (props: CurrentTasksGridActionsProps) => {
  const task = props.data as TaskType;
  return (
    <Container>
      {(doActionsContain(task, 'change') ||
        doActionsContain(task, 'request_change') ||
        doActionsContain(task, 'complete')) && (
        <Row>
          <Col xs={3}>
            {/* && is react template equivalent to "if xx then include this" */}
            {props.onEditClick && doActionsContain(task, 'change') && (
              <Button
                onClick={() => {
                  // Exclamation point because we know it will be there
                  // because we have a check for it to load this JSX element
                  props.onEditClick!(task);
                }}
                variant="outline"
                size="sm"
              >
                <span className="bi-pencil-fill" />
              </Button>
            )}
            {props.onRequestChangeClick &&
              !doActionsContain(task, 'change') &&
              doActionsContain(task, 'request_change') && (
                <Button
                  onClick={() => {
                    // Exclamation point because we know it will be there
                    // because we have a check for it to load this JSX element
                    props.onRequestChangeClick!(task);
                  }}
                  variant="outline"
                  size="sm"
                >
                  <span className="bi-chat-dots" />
                </Button>
              )}
          </Col>
          <Col xs={9}>
            {props.onCompleteClick && doActionsContain(task, 'complete') && (
              <Button
                onClick={() => {
                  // Exclamation point because we know it will be there
                  // because we have a check for it to load this JSX element
                  props.onCompleteClick!(task, props.api);
                }}
                variant="primary"
                size="sm"
              >
                Complete
              </Button>
            )}
          </Col>
        </Row>
      )}
      {doActionsContain(task, 'reopen') && (
        <Row>
          <Col>
            {props.onReopenClick && doActionsContain(task, 'reopen') && (
              <Button
                onClick={() => {
                  // Exclamation point because we know it will be there
                  // because we have a check for it to load this JSX element
                  props.onReopenClick!(task);
                }}
                variant="primary"
                size="sm"
              >
                Reopen
              </Button>
            )}
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default CurrentTasksGridActions;

export type { CurrentTasksGridActionsProps };
