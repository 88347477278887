import { ObjectWithActions, ObjectWithId } from './shared';
import FieldCoreProcessType from './fieldCoreProcess';
import { getValueToSend } from './recurringGoalSchedule';

type CoreRecurringGoalScheduleType = ObjectWithId &
  ObjectWithActions & {
    frequency: string;
    startDateTime: string;

    summaryTemplate: string;
    linkUrlTemplate: string;
    descriptionTemplate: string;

    scoringType: string;
    totalPoints: number;
    sourceField?: number;
    goalValue: number;
    cutoffValue: number;
    betterDirection?: string;
    unitOfMeasure?: string;

    allowAutoPush: boolean;
    autoPushExceedByDeltaValue?: number;
    autoPushChangeDeltaValue?: number;
    autoPushBestExpectedValue?: number;

    fieldCoreProcess: FieldCoreProcessType;
  };

type CoreRecurringGoalScheduleTypeToSend = {
  id?: number;

  frequency?: string;
  startDateTime?: string;

  summaryTemplate?: string;
  linkUrlTemplate?: string;
  descriptionTemplate?: string;

  scoringType?: string;
  totalPoints?: number;
  sourceField?: number;
  goalValue?: number;
  cutoffValue?: number;

  allowAutoPush: boolean;
  autoPushExceedByDeltaValue?: number;
  autoPushChangeDeltaValue?: number;
  autoPushBestExpectedValue?: number;

  actions?: string[];

  fieldCoreProcess?: number;

  // Not sent to backend
  // TODO this is very hacky
  _unitOfMeasure?: string;
};

function convertToTypeToSend(
  coreRecurringGoalScheduleToDisplay: CoreRecurringGoalScheduleTypeToSend,
  valueUnitOfMeasure: string | undefined,
): CoreRecurringGoalScheduleTypeToSend {
  const coreRecurringGoalScheduleToSend = {
    ...coreRecurringGoalScheduleToDisplay,
    goalValue: getValueToSend(coreRecurringGoalScheduleToDisplay.goalValue, valueUnitOfMeasure),
    cutoffValue: getValueToSend(coreRecurringGoalScheduleToDisplay.cutoffValue, valueUnitOfMeasure),
    autoPushExceedByDeltaValue: getValueToSend(
      coreRecurringGoalScheduleToDisplay.autoPushExceedByDeltaValue,
      valueUnitOfMeasure,
    ),
    autoPushChangeDeltaValue: getValueToSend(
      coreRecurringGoalScheduleToDisplay.autoPushChangeDeltaValue,
      valueUnitOfMeasure,
    ),
  };
  return coreRecurringGoalScheduleToSend;
}

export { convertToTypeToSend };
export default CoreRecurringGoalScheduleType;
export type { CoreRecurringGoalScheduleTypeToSend };
