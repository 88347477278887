import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useState } from 'react';
import { Alert, Button, Col, Collapse, Container, Navbar, Row } from 'react-bootstrap';
import SelectedTeamContext from '../../contexts/SelectedTeamContext';
import styles from './Header.module.scss';
import HeaderNavLinks from '../HeaderNavLinks/HeaderNavLinks';
import LoggedInTeamMemberContext from '../../contexts/LoggedInTeamMemberContext';
import TeamSelect from '../TeamSelect/TeamSelect';
import Profile from '../Profile/Profile';
import TeamScoreSummary from '../scores/TeamScoreHistoryGrid/TeamScoreSummary/TeamScoreSummary';
import HeaderDotsMenu from '../HeaderDotsMenu/HeaderDotsMenu';
import { LinkContainer } from 'react-router-bootstrap';

// selectedTeam pulled from context not props
const Header = () => {
  const selectedTeamContext = useContext(SelectedTeamContext);
  const [isOpen, setOpen] = useState(true);

  const {
    isAuthenticated,
    // isLoading: isLoadingAuthentication, // If we want to use <p>Loading your profile...</p> somewhere
  } = useAuth0();

  return (
    /* collapse button sticks to this div, animates relative to it */
    <div className={styles.collapsibleHeaderWrapper}>
      <Collapse in={isOpen}>
        <Navbar className="d-flex navbarContainer" bg="light" expand="lg">
          <Navbar.Brand className={styles.brand}>
            <LinkContainer to="/" className={styles.linkContainer}>
              <img src="/lji_logo_large.png" width="33" height="33" alt="Love Justice International Logo" />
            </LinkContainer>
          </Navbar.Brand>
          {/* <Navbar.Brand href="#"><i className="bi-flag"></i> LJI Ops Management</Navbar.Brand> */}
          <Navbar.Toggle aria-controls="navbarScroll" id={'navBarToggle'} />
          <Navbar.Collapse id="navbarScroll">
            {/* fluid: take up all horizontal space */}
            <Container fluid>
              <Row
                style={{
                  // This is hacky, why isn't this defaulted???
                  // 100% wasn't actually the full length, so I used 110% as another hack
                  // Also see TeamScoreSummary which has width: auto
                  width: '110%',
                }}
              >
                {/* This passes an object to the lg/xs tag
                    span is the size of the column that is usually passed
                    order specifies the order on big desktops
                    Otherwise, on mobile, it will be the order of the tags
                    I use this so that the team score summary is in the middle on big desktops (out of tag order)
                    but on smaller screens drop to a new line (in tag order) */}
                <Col lg={{ order: 'last' }} className="justify-content-start px-0">
                  <div
                    // bootstrap flex shortcut for inner elements to go left->right instead of stack
                    // px-0 bootstrap spacing shortcut, removes left/right padding
                    className="d-flex flex-column-reverse justify-content-start flex-xl-row justify-content-lg-end px-0"
                  >
                    <div>
                      {isAuthenticated && selectedTeamContext.selectedTeam && (
                        <TeamScoreSummary selectedTeam={selectedTeamContext.selectedTeam} />
                      )}
                    </div>
                    <div
                      // bootstrap flex shortcut for inner elements to go left->right instead of stack
                      // px-0 bootstrap spacing shortcut, removes left/right padding
                      className="d-flex flex-row justify-content-start justify-content-lg-end px-0"
                    >
                      {isAuthenticated && (
                        <LoggedInTeamMemberContext.Consumer>
                          {(loggedInTeamMember) => <TeamSelect loggedInTeamMember={loggedInTeamMember} />}
                        </LoggedInTeamMemberContext.Consumer>
                      )}
                      {isAuthenticated && (
                        <a target="_blank" href="https://searchlightdata.org" rel="noreferrer">
                          <img
                            className={styles.searchlightLogo}
                            alt="Searchlight Logo"
                            src="/searchlight_logo_small.png"
                            width={40}
                            height={37}
                          />
                        </a>
                      )}
                      <Profile />
                      {isAuthenticated && selectedTeamContext.selectedTeam && (
                        <HeaderDotsMenu selectedTeam={selectedTeamContext.selectedTeam} />
                      )}
                    </div>
                  </div>
                </Col>
                <Col lg={{ order: 'first' }} className="px-0">
                  {isAuthenticated && <HeaderNavLinks selectedTeam={selectedTeamContext.selectedTeam} />}
                </Col>
              </Row>
            </Container>
          </Navbar.Collapse>
        </Navbar>
      </Collapse>
      <div>
        {!isAuthenticated && (
          <Alert variant="info">Welcome to Love Justice Operations Management! Please log in above.</Alert>
        )}
      </div>
      <Button
        className={styles.collapseButton}
        variant="light"
        onClick={() => setOpen(!isOpen)}
        aria-controls="example-collapse-text"
        aria-expanded={isOpen}
      >
        <i className={isOpen ? 'bi bi-caret-up' : 'bi bi-caret-down'}></i>
      </Button>
      <div
        style={{
          //This div adds a small border to the bottom of the header.
          background: 'grey',
          color: 'grey',
          borderColor: 'grey',
          height: '3px',
        }}
      ></div>
    </div>
  );
};

export default Header;
